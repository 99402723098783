import { Component, Mixins } from 'vue-property-decorator';
import LinkPlate from '@/components/LinkPlate.vue';
import QueuePlate from '@/components/QueuePlate.vue';
import { Utils } from '@/mixins';
import { Queue, QueueMembershipPostBody } from '../../../api';
import _ from 'lodash';

@Component({
  components: {
    LinkPlate,
    QueuePlate,
  },
})
export default class ActiveQueues extends Mixins(Utils) {
  public lastRefresh: number = 0;

  public queues: QueueData[] = [];

  public created() {
    this.refresh();
  }

  public refresh(queueId?: number) {
    const refreshNow = Date.now();
    this.lastRefresh = refreshNow;
    this.api.queues().then(queues => {
      // Prevent slow refreshes overwiting newer
      if (this.lastRefresh > refreshNow) {
        return;
      }
      // this.lastRefresh = refreshNow;
      this.queues = _.orderBy(queues, ['joined', 'id'], ['desc', 'asc']).map(queue => {
        const prevQueue = this.queues.find(qd => qd.queue.id === queue.id);
        const expanded = prevQueue && prevQueue.expanded || false;
        return {
          expanded,
          processing: false,
          queue,
        };
      });
    });
  }

  public async handleChangeMembership(qd: QueueData, value: boolean) {
    const join = value;
    const queueId = qd.queue.id;

    qd.processing = true;
    try {
      await this.api.queueMemebership(queueId, join);
      this.refresh();
    } catch (e) {
      qd.processing = false;
      console.error(e);
    }
  }

  public async handleToggleAvailable(queue: QueueData, value: boolean) {
    console.log('UpdateAvailable', queue, value);
    queue.processing = true;

    try {
      await this.api.saveQueueAvailable(queue.queue.id, value);

      // Update queue status
      // queue.queue.available = value;
      // queue.queue.available_agents += value ? 1 : -1;

      // Update agent listing in queue
      // const agent = queue.queue.agents.find(item => item.destination_id === destinationId);
      // if (!agent) {
      //   throw new Error(`Could not find user with DEstination ID ${destinationId} in agents ${queue.queue.agents}!`);
      // }
      // agent.available = value;

      // Do a full refresh in the background for proper synchronisation.
      this.refresh();
    } catch (e) {
      queue.processing = false;
      console.error('Could not update availablility on queue', queue);
      console.error(e);
    }

    // queue.processing = false;
  }

  get allCollapsed() {
    return _.every(this.queues, qd => !qd.expanded);
  }

  public toggleExpandAll() {
    // If any are open, collapse all, otherwise expands all.
    if (this.allCollapsed) {
      this.expandAll();
    } else {
      this.collapseAll();
    }
  }

  public collapseAll() {
    this.queues.forEach(qd => qd.expanded = false);
  }

  public expandAll() {
    this.queues.forEach(qd => qd.expanded = true);
  }
}

export interface QueueData {
  expanded: boolean;
  processing: boolean;
  queue: Queue;
}
