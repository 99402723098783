import {
  Boolean as Bool,
  Number,
  String,
  Literal,
  Array,
  Record,
  Union,
  Static,
  // Undefined,
  Unknown,
  Null,
  // InstanceOf
} from 'runtypes';
import CheckFilter from './runtypes_filter';


const _Void = Record({
  type: Literal("void")
});
export type Void = Static<typeof _Void>;
export const is_void = _Void.guard;
export const filter_void = CheckFilter(_Void);

const _Ping = Record({
  type: Literal("ping")
});
export type Ping = Static<typeof _Ping>;
export const is_ping = _Ping.guard;
export const filter_ping = CheckFilter(_Ping);


const _Pong = Record({
  type: Literal("pong")
});
export type Pong = Static<typeof _Pong>;
export const is_pong = _Pong.guard;
export const filter_pong = CheckFilter(_Pong);

const _GraphRegisterId = Record({
  type: Literal("graph_register_id"),
  id: String
});
export type GraphRegisterId = Static<typeof _GraphRegisterId>;
export const is_graph_register_id = _GraphRegisterId.guard;
export const filter_graph_register_id = CheckFilter(_GraphRegisterId);

const _GraphRegisterResult = Record({
  type: Literal("graph_register_result"),
  registered: Bool,
  expires_at: Number,
  mail: String.Or(Null)
});
export type GraphRegisterResult = Static<typeof _GraphRegisterResult>;
export const is_graph_register_result = _GraphRegisterResult.guard;
export const filter_graph_register_result = CheckFilter(_GraphRegisterResult);


const _Error = Record({
  type: Literal("error"),
  message: String,
  data: Unknown
});
export type Error = Static<typeof _Error>;
export const is_error = _Error.guard;
export const filter_error = CheckFilter(_Error);

const _Payload = Union(
  _Void, _Ping, _Pong,
  _GraphRegisterId, _GraphRegisterResult,
  _Error
);
export type Payload = Static<typeof _Payload>;
export const is_payload = _Payload.guard;
export const filter_payload = CheckFilter(_Payload);


const _Header = Record({
  addr: String,
  ts: Number,
  reply_addr: String,
  sender: String
});
export type Header = Static<typeof _Header>;
export const is_header = _Header.guard;
export const filter_header = CheckFilter(_Header);


const _Message = Record({
  header: _Header,
  data: _Payload
});
export type Message = Static<typeof _Message>;
export const is_message = _Message.guard;
export const filter_message = CheckFilter(_Message);

