




import { Mixins, Component, Prop } from "vue-property-decorator";
import { Utils } from "@/mixins";

@Component
export default class Logout extends Mixins(Utils) {
  public created() {
    console.log("logout created!");
    this.$store.dispatch('logout');
  }
}
