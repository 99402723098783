<template>    
  <div class="[ o-gutter-bottom-md o-gutter-bottom-lg@lg ]">    
    <div v-if="processing" class="o-gutter__item">{{ $t('Loading...') }}</div>
    <div v-else>
      <div class="o-gutter__item" style="margin-bottom: 20px;">
        <button
          class="c-btn c-btn--sm"
          style="font-size:1.2rem;"
          @click="go_to_create"
        >{{$t("Create new subscription")}}</button>
      </div>      
      <div class="o-gutter__item [ u-soft-bottom-sm u-soft-bottom-md@lg ]">
        <h2 class="u-push-bottom-clear">{{ prettyTitle }}</h2>
      </div>    
      <div class="o-gutter__item">      
        <div class="[ u-bleed-sides-sm u-bleed-sides-md@xl ]">
          <div class="u-width-auto">
            <span
              @click="previous"
              v-if="offset>0"
              style="cursor:pointer;"
              class="u-soft-ends-xs u-inline-block u-width-1/2 u-text-center u-border-top"
            > <pre style="display:inline"></pre>&lsaquo; {{ $t('Previous') }}</span>
            <span
              v-else
              class="u-soft-ends-xs u-inline-block u-width-1/2 u-text-center u-border-top"
            > <pre style="display:inline"> </pre> </span>
            <span
              @click="next"
              v-if="offset+limit < data.length"
              style="cursor:pointer;"
              class="u-soft-ends-xs u-inline-block u-width-1/2 u-text-center u-border-top"
            > <pre style="display:inline"> </pre>{{ $t('Next') }} &rsaquo;</span>
            <span
              v-else
              class="u-soft-ends-xs u-inline-block u-width-1/2 u-text-center u-border-top"
            > <pre style="display:inline"> </pre> </span>
          </div>
          <hr />
        </div>

        <div class="o-gutter-bottom-sm">
          <div class="o-gutter__item">     
            <table class="table u-text-omicron">
              <thead>
                <th class="u-width-2/12">{{$t('Name')}}</th>
                <th class="u-width-2/12">{{$t('Number')}}</th>
                <th class="u-width-2/12">{{$t('Type')}}</th>                
                <th class="u-width-2/12">{{$t('Binding')}}</th>              
                <th class="u-width-2/12">{{$t('Children')}}</th>
              </thead> 
              <tbody>
                <tr v-for="sub in subscriptions" :key="sub.id" @click="go_to_details(sub)" style="cursor:pointer;">
                  <td>{{sub.name}}</td>
                  <td>{{sub.primary_number}}</td>
                  <td>{{$t(sub.system.type)}}</td>                  
                  <td>{{sub.binding.end_date ? $t("Yes") : $t("No")}}</td>
                  <td>{{sub.children.length}}</td>
                </tr>
              </tbody>           
            </table>     
          </div>
        </div>

        <div class="[ u-bleed-sides-sm u-bleed-sides-md@xl ]">
          <div class="u-width-auto">
            <span
              @click="previous"
              v-if="offset>0"
              style="cursor:pointer;"
              class="u-soft-ends-xs u-inline-block u-width-1/2 u-text-center u-border-top"
            ><pre style="display:inline"> </pre>&lsaquo; {{ $t('Previous') }}</span>
            <span
              v-else
              class="u-soft-ends-xs u-inline-block u-width-1/2 u-text-center u-border-top"
            ><pre style="display:inline"> </pre></span>
            <span
              @click="next"
              v-if="offset+limit < data.length"
              style="cursor:pointer;"
              class="u-soft-ends-xs u-inline-block u-width-1/2 u-text-center u-border-top"
            ><pre style="display:inline"> </pre> {{ $t('Next') }} &rsaquo;</span>
            <span
              v-else
              class="u-soft-ends-xs u-inline-block u-width-1/2 u-text-center u-border-top"
            ><pre style="display:inline"> </pre></span>
          </div>
          <hr />
        </div>
      </div>
    </div>    
  </div>
</template>

<script>
import s from "./self_admin_subscription_list";
export default s;
</script>