



























































































import s from './event-history-view';
export default s;
