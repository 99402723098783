




























































import { Vue, Prop, Component, Mixins } from 'vue-property-decorator';
import { Agent } from '../../../api';
import { Utils } from '@/mixins';

@Component
export default class AgentPlate extends Mixins(Utils) {

    @Prop({ required: true })
    private agent!: Agent;

    get textColor() {
        return this.agent.available ? 'u-text-omicron' : 'u-text-mu';
    }

    get fillColor() {
        return this.agent.available ? 'u-fill-omicron' : 'u-fill-mu';
    }
}
