
















import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class RefLine extends Vue {
  @Prop({ required: true })
  private userRef!: any;

  get prettyEnd() {
    return moment.unix(this.userRef.end).locale(this.$i18n.locale).format('LLL');
  }
}
