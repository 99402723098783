




































import { Mixins, Component } from 'vue-property-decorator';
import { Utils } from '@/mixins';
import { Subscription, SubscriptionSummary } from '../../../../api';
import UserPlate from '@/components/UserPlate.vue';
import SubscriptionPlate from '@/components/plates/SubscriptionPlate.vue';
import userPlaceholder from '@/assets/img/user-placeholder.png';
import _ from 'lodash';

@Component({
  components: {
    UserPlate,
    SubscriptionPlate,
  },
})
export default class TopupSubscriptions extends Mixins(Utils) {
  private processing = true;
  private buying = false;

  private allSubscriptions: SubscriptionState[] = [];

  private productId!: number;
  private systemId!: number;
  private name!: string;
  private price!: number;
  private tax_amount!: number;
  private currency!: string;
  private privateCustomer!: boolean;

  private placeholder = userPlaceholder;

  public created() {
    const { product, system, name, price, tax_amount, currency } = this.$route.params;

    this.productId = Number(product);
    this.systemId = Number(system);
    this.name = name;
    this.price = Number(price);
    this.tax_amount = Number(tax_amount);
    this.currency = currency;

    this.refresh();
  }

  public async refresh() {
    this.processing = true;
    this.privateCustomer = await this.api.isPrivateCustomer();
    const admin = this.$store.getters.adminMode;
    const subs = await this.api.subscriptions();
    const withSelection = subs
      .filter(sub => admin || sub.own)
      .filter(sub => this.systemId === 0 || sub.system === this.systemId)
      .map(sub => ({ ...sub, selected: false }));

    // Put own subscription on top, rest in lexiographic order
    this.allSubscriptions = _.orderBy(withSelection, ['own', 'name'], ['desc', 'asc']);

    if (this.allSubscriptions.length === 1) {
      this.allSubscriptions = this.allSubscriptions.map(sub => ({ ...sub, selected: true }));
    }
  }

  public async handleSubmit() {
    this.buying = true;

    const subscriptionIds = this.selected.map(sub => sub.id);
    const productId = this.productId;

    await this.api.buyProduct(productId, subscriptionIds);

    this.buying = false;

    const names = this.selected.map(sub => sub.name);
    this.$router.push({ name: 'topup-product', query: { productid: this.productId.toString(), selection: names } });
  }

  public clearSelection() {
    this.allSubscriptions = this.allSubscriptions.map(sub => ({ ...sub, selected: false }));
  }

  get selected(): SubscriptionState[] {
    return this.allSubscriptions.filter(sub => sub.selected);
  }

  get buttonDisabled() {
    return this.selected.length === 0;
  }

  get buttonText() {

    if (this.buttonDisabled) {
      return this.$t('Choose at least one subscription');
    }

    if (this.buying) {
      return `${this.$t('Buying')}...`;
    }
    const buy_price = this.privateCustomer ? this.price + this.tax_amount : this.price;
    const price = this.formatPrice(buy_price * this.selected.length, this.currency);
    const selected = this.selected.length;
    const subs = selected === 1 ? this.$t('subscription') : this.$t('subscriptions');

    return `${this.$t('Buy for')} ${selected} ${subs} ${this.$t('for')} ${price}`;
  }

  get subscriptions() {
    return this.allSubscriptions
      .filter(this.adminMode);
  }

  public adminMode(sub: SubscriptionState) {
    const am = this.$store.getters.adminMode;

    if (am) {
      return true;
    } else {
      return sub.own;
    }
  }
}

interface SubscriptionState extends SubscriptionSummary {
  selected: boolean;
}

