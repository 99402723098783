import Component from 'vue-class-component';
import { Mixins } from 'vue-property-decorator';
import { Events } from '@/mixins';
import { SelfAdminSubscription, SubscriptionBase } from '../../../../api';
import i18n from '../../i18n';
@Component({  
})
export default class SelfAdminSubscriptionList extends Mixins(Events) {  
  public processing = true;
  public subscription: SelfAdminSubscription|null = null;
  public show_pre = false;
  public binding_show = false;
  public sim_show = false;
  public line_show = false;
  public children_show = false;

  public created() {
    console.log("SelfAdminSubscriptionList created()", this.$route.params.id);    
    this.$watch('$route.params.id', (id) => {
      console.log("id changed to ", id);
      this.load(Number(id));
    });    
  }

  public mounted() {
    console.log("SelfAdminSubscriptionList mounted()");
    this.load(Number(this.$route.params.id));
  }  

  public updated() {
    console.log("SelfAdminSubscriptionList updated()");    
  }  

  public load(id: number) {
    this.processing = true;
    this.show_pre = false;
    this.sim_show = false;
    this.line_show = false;
    this.children_show = false;
    this.subscription = null;
    this.api.selfadmin_get_subscription(id).then(sub => {
      this.subscription = sub;
      this.processing = false;
      console.log("subscription = ", JSON.parse(JSON.stringify(this.subscription)));
    }).catch(err => {
      console.error("failed to get subscription", err);
    });
  }  

  public goto(id: number) {
    this.$router.push({
      name: "self-admin-subscription-details", 
      params: { 
        id: String(id)
      }
    });    
  }

  get prettyTitle() {
    return `${i18n.t("Subscription Details")}`;
  }


}
