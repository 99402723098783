
























































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Utils } from '@/mixins';

@Component
export default class ChatForm extends Mixins(Utils) {
  public body: string = '';

  @Prop()
  public disabled!: boolean;

  public handleSubmit() {
    const body = this.body;
    this.body = '';

    this.$emit('send', body);
  }
}
