
















































import s from './check-plate';
export default s;
