import { Component, Mixins } from 'vue-property-decorator';
import { Utils, Events } from '@/mixins';
import {
  Contact, ContactEmail, ContactPhone, ContactType
} from '../../../../api';

@Component({

})
export default class Contacts extends Mixins(Events) {
  public contacts: Contact[] = [];
  public processing = true;
  public search_string = "";

  public created() {
    this.refresh();
    this.api.getContact(1).then(contact => {
      console.log("this.api.getContact(1)", contact);
    });
  }

  public async refresh() {
    this.api.contacts().then(contacts => {
      console.log("contacts_list.refresh result", contacts);
      this.contacts = contacts;      
      this.processing = false;
    }).catch(err => {
      console.error("this.api.contacts error ", err);
    });
  }



  public goto_details(contact_id: number) {
    console.log("going to contact id", contact_id);
    this.$router.push({
      name: "contact-details",
      params: {
        id: String(contact_id)
      }
    });
  }

  public edit_contact(contact_id: number) {
    console.log("edit_contact", contact_id);
    this.$router.push({
      name: "contact-edit",
      params: {
        id: String(contact_id)
      }
    });
  }

  public type_name(contact: Contact) {
    switch(contact.type) {
      case ContactType.COMPANY: return "Company";
      case ContactType.CUSTOM: return "Personal";
      case ContactType.TEAMS: return "Teams";
      default: return "Unknown";
    }
  }

  get filtered_contacts() {
    return this.contacts.filter(c => {
      const name = (c.first_name + " " + c.last_name).toLocaleLowerCase();
      const found_name =  name.includes(this.search_string.toLocaleLowerCase());
      const tname = (this.$t(this.type_name(c)) as string).toLocaleLowerCase();
      const found_type = tname.includes(this.search_string.toLocaleLowerCase());
      return found_name || found_type;
    });
  }



  /**
   * Used for debugging ui layout should not be calld in checked in code
   * @param n number of contacts to generate
   */
  private random_contacts(n: number): Contact[] {
    const random_num = (min: number, max: number) => Math.floor(Math.random() * (max-min) + min);
    const random_str = (len: number) => {
      let res = '';
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for( let i = 0; i<len; ++i) {
        res += chars.charAt(random_num(0, chars.length));
      }
      return res;
    };
    const ret: Contact[] = [];
    for(let i = 0; i<n; ++i) {
      ret.push({
        id: null,
        first_name: random_str(random_num(10,20)),
        last_name: random_str(random_num(10,20)),
        type: ContactType.CUSTOM,
        phones: [],
        emails: []
      });
    }
    return ret;
  }

}
