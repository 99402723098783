import { Component, Mixins } from 'vue-property-decorator';
// import GenericPlate from '@/components/GenericPlate.vue';
import UserPlate from '@/components/UserPlate.vue';
import EmptyPlate from '@/components/EmptyPlate.vue';
import UserPlateLarge from '@/components/UserPlateLarge.vue';
import InvoiceList from '@/components/InvoiceList.vue';
import ProfileLinkPlate from '@/components/ProfileLinkPlate.vue';
import { Voicemail, Invoice, UserProfile, ReferenceStatus } from '../../../../api';
import { EasyAppApi } from '@/utils';
import userImgSrc from '@/assets/img/user-placeholder.png';
import _ from 'lodash';
import { Utils } from '@/mixins';

@Component({
  components: {
    InvoiceList,
    UserPlate,
    UserPlateLarge,
    EmptyPlate,
    ProfileLinkPlate,
  },
})
export default class MyProfile extends Mixins(Utils) {
  public userImgSrc = userImgSrc;
  public firstName = '';
  public lastName = '';
  public phoneNumber = '';
  public subscriptionNames: { [locale: string]: string } = {};
  public userRef: ReferenceStatus | null = null;

  public processing = true;

  public created() {
    this.fetch();
  }

  public async fetch() {
    this.processing = true;

    console.log(this.P.USER.REFERENCES.READ, this.has(this.P.USER.REFERENCES.READ));

    const [profile, consumption, userRefs] = await Promise.all([
      this.api.profile(),
      this.api.consumption(),
      this.has(this.P.USER.REFERENCES.READ) ? this.api.checkUserRef() : Promise.resolve(null),
    ]);

    this.setUpProfile(profile);
    this.subscriptionNames = consumption.name;
    // this.userRef = userRefs && nearest(userRefs);
    const uref = nearest(userRefs || []);
    if (uref) {
      this.userRef = {
        ...uref,
        reference: {
          ...uref.reference,
          name: this.$t(uref.reference.name).toString(),
        },
      };
    }

    this.processing = false;
  }

  public openListen(voicemail: Voicemail) {
    this.$router.push({ name: 'voicemail-listen', params: { name: voicemail.filename } });
  }

  public handleLogOut() {
    this.$store.dispatch('logout');
  }

  get name() {
    return this.firstName + ' ' + this.lastName;
  }

  get subscriptionName() {
    const names = this.subscriptionNames;
    return names[this.$i18n.locale] || names.sv || names.en || this.$t('Unknown subscription');
  }

  get onPresence() {
    return this.$route.path.indexOf('presence') !== -1;
  }

  private setUpProfile(profile: UserProfile) {
    this.firstName = profile.first_name || this.$t('No name').toString();
    this.lastName = profile.last_name || '';
    this.phoneNumber = this.formatNumber(profile.number);
  }
}

function nearest(userRefs: ReferenceStatus[]): ReferenceStatus | null {
  return _.head(_.orderBy(userRefs, 'end')) || null;
}
