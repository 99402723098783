























































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Utils } from '@/mixins';

@Component
export default class GroupPlate extends Mixins(Utils) {
  @Prop()
  public name!: string;

  @Prop()
  public status!: string;

  @Prop()
  public colour!: string;

  @Prop()
  public checked!: boolean;
}
