






































































































import { Component, Mixins } from "vue-property-decorator";
import { Utils } from "@/mixins";
import { log } from "util";
import { Invoice } from "../../../../api";

@Component
export default class InvoiceDetail extends Mixins(Utils) {
  public invoice: Invoice | null = null;
  public processing: boolean = true;

  public created() {
    this.processing = true;
    const id = Number.parseInt(this.$route.params.id, 10);
    this.api.invoice(id).then(invoice => {
      this.invoice = invoice as Invoice;
      this.processing = false;
    });
  }

  get id() {
    return this.invoice ? this.invoice.id : 0;
  }

  private isCreditInvoice() {
    const inv = this.invoice;

    if (inv === null) {
      return false;
    }

    return (
      inv.hasOwnProperty("credit") &&
      ((typeof inv.credit === "boolean" && inv.credit === true) ||
        (typeof inv.credit === "string" && inv.credit === "true"))
    );
  }

  private getInvoiceRecipient() {
    if (!this.invoice) {
      return [];
    }

    const address = this.invoice.address;
    if (!address) {
      return [];
    }

    const arr = [];
    if (address.name) {
      arr.push(address.name);
    }
    if (address.address1) {
      arr.push(address.address1);
    }
    if (address.address2) {
      arr.push(address.address2);
    }
    if (address.city) {
      arr.push(address.city);
    }

    return arr;
  }

  private getInvoicePaymentData() {
    if (!this.invoice) {
      return [];
    }

    const inv = this.invoice;
    const arr = [];
    const crossedOutLine = this.isCreditInvoice() || inv.remainingPayment <= 1;
    const giroTypeString = this.getGiroTypeString(inv.giroType);
    arr.push([giroTypeString, inv.giroNo, crossedOutLine]);
    arr.push([this.$t("OCR number"), inv.ocr, crossedOutLine]);
    arr.push([this.$t("Amount to Pay"), inv.remainingPaymentFormatted, false]);
    arr.push([this.$t("Due Date"), inv.dueDate, false]);

    return arr;
  }

  private getInvoiceMainData() {
    if (!this.invoice) {
      return [];
    }

    const inv = this.invoice;
    const arr = [];

    arr.push([this.$t("Customer Number"), inv.custno]);
    arr.push([this.$t("Invoice Number"), inv.invoiceNumber]);
    arr.push([this.$t("Invoice Date"), inv.invoiceDate]);
    arr.push([this.$t("Status"), this.getTranslationStatus(inv.status)]);

    return arr;
  }

  private getInvoiceAmountSum() {
    if (!this.invoice) {
      return [];
    }

    const inv = this.invoice;
    const arr = [];

    arr.push([this.$t("Sum excl. VAT"), inv.amountFormatted]);

    arr.push([this.$t("VAT"), inv.vatAmountFormatted]);

    if (inv.rounding != null) {
      arr.push([this.$t("Rounding"), inv.roundingFormatted]);
    }

    arr.push([this.$t("Total"), inv.toPayFormatted]);

    return arr;
  }

  private getInvoicePDFUrl() {
    if (!this.invoice) {
      return "";
    }
    return this.invoice.invoiceUrl;
  }

  private getInvoiceItemGroups() {
    if (!this.invoice) {
      return [];
    }
    const invGroups: { [index: string]: any } = this.invoice.summary.items;
    const arr: any[] = [];

    for (const key in invGroups) {
      arr.push(this.getInvoiceItemGroup(invGroups[key], key));
    }
    return arr;
  }

  private getInvoiceItemGroup(invoiceItemsGroup: any[], name: string) {
    const group: { [index: string]: any } = {};
    group.name = this.getTranslationInvoiceGroup(name);

    const items: any[] = [];

    invoiceItemsGroup.forEach((item: any) => {
      items.push(this.getInvoiceItem(item));
    });
    group.items = items;

    return group;
  }

  private getInvoiceItem(invoiceItem: any) {
    const item: { [index: string]: any } = {};

    item.text = "";
    if (invoiceItem.text1 != null && invoiceItem.text1.length > 0) {
      item.text += invoiceItem.text1;
    }
    if (invoiceItem.text2 != null && invoiceItem.text2.length > 0) {
      item.text += " " + invoiceItem.text2;
    }

    item.units = invoiceItem.units.formatted;
    item.cost = invoiceItem.rowTotal.formatted;

    return item;
  }

  private getTranslationStatus(str: string) {
    if (str === "draft") {
      return this.$t("Draft");
    } else if (str === "ready") {
      return this.$t("Ready");
    } else if (str === "sent") {
      return this.$t("Sent");
    } else if (str === "dontsend") {
      return this.$t("Don't send");
    } else if (str === "invalidated") {
      return this.$t("Invalidated");
    } else {
      return str;
    }
  }

  private getTranslationInvoiceGroup(str: string) {
    if (str === "fixed") {
      return this.$t("Fixed Line Telephony");
    } else if (str === "mobile") {
      return this.$t("Mobile Telephony");
    } else if (str === "customer") {
      return this.$t("Other fees");
    } else if (str === "usage") {
      return this.$t("Usage");
    } else if (str === "product") {
      return this.$t("Product");
    } else if (str === "content") {
      return this.$t("Content");
    } else {
      return str;
    }
  }

  private getGiroTypeString(value: string) {
    // Bankgiro and Postgiro should not be translated.
    switch (value) {
      case "pg":
        return "Postgiro";
      case "bg":
        return "Bankgiro";
      default:
        return "Giro";
    }
  }
}
