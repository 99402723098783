import { Component, Mixins } from 'vue-property-decorator';
import UserPlate from '@/components/UserPlate.vue';
import EmptyPlate from '@/components/EmptyPlate.vue';
import InvoiceList from '@/components/InvoiceList.vue';
import ProfileLinkPlate from '@/components/ProfileLinkPlate.vue';
import CallList from '@/components/lists/CallList.vue';
import RecordingList from '@/components/lists/RecordingList.vue';
import VoicemailList from '@/components/lists/VoicemailList.vue';
import ProfileRecent from '@/components/ProfileRecent.vue';
import { Voicemail, Invoice, Recording, Call, Relative, NumberAliasMap } from '../../../../api';
import _ from 'lodash';
import { Utils } from '@/mixins';

@Component({
  components: {
    InvoiceList,
    UserPlate,
    EmptyPlate,
    ProfileLinkPlate,
    ProfileRecent,
    CallList,
    RecordingList,
    VoicemailList,
  },
})
export default class MyProfileRecents extends Mixins(Utils) {

  public calls: Array<Relative<Call>> | null = null;
  public voicemails: Voicemail[] | null = null;
  public invoices: Invoice[] | null = null;
  // public recordings: Recording[] | null = null;

  public numbers: string[] | null = null;
  public number_alias: NumberAliasMap = {};

  public processing = true;

  public created() {
    Promise.all([
      this.api.recents(),
      this.api.getNumberLookups()
    ]).then(([recents, alias]) => {
      this.numbers = recents.my_numbers;
      this.number_alias = alias;
      if (recents.calls) {
        this.calls = recents.calls;
      }
      if (recents.voicemails) {
        this.voicemails = recents.voicemails;
      }
      if (recents.invoices) {
        this.invoices = recents.invoices;
      }
      this.processing = false;
    });
  }

  public openListen(voicemail: Voicemail) {
    this.$router.push({ name: 'voicemail-listen', params: { name: voicemail.filename } });
  }

  get call_link() {
    if (this.has(this.P.USER.EVENT_HISTORY)) {
      return "event-history-top";
    } else {
      return "call-log";
    }
  }

  // get voicemails() {
  //   if (!this.rawVoicemails) {
  //     return null;
  //   }
  //   return this.rawVoicemails.map(voicemail => {
  //     const pl = `${voicemail.length} seconds`;
  //     const start = new Date(voicemail.start * 1000);
  //     const pt = start.toLocaleString();
  //     const id = voicemail.filename;
  //     return {
  //       ...voicemail,
  //       id,
  //       prettyTime: pt,
  //       prettyLength: pl,
  //     };
  //   });
  // }
}
