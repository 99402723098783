


















































import { Component, Mixins } from 'vue-property-decorator';
import GenericPlate from '@/components/GenericPlate.vue';
import ReverseLinkPlate from '@/components/plates/ReverseLinkPlate.vue';
import { Utils } from '@/mixins';
import { Subscription } from '../../../../api';

@Component({
  components: {
    GenericPlate,
    ReverseLinkPlate,
  },
})
export default class SimDataView extends Mixins(Utils) {
  public error = '';
  public processing = true;
  public subId!: number;

  public subscription?: Subscription;

  public created() {
    // this.pin = this.$t('Loading...').toString();
    // this.puk = this.$t('Loading...').toString();
    // this.iccid = this.$t('Loading...').toString();

    this.subId = Number(this.$route.params.id);

    this.refresh();
  }

  public async refresh() {
    this.processing = true;

    try {
      const sub = await this.api.subscription(this.subId);
      this.subscription = sub;
    } catch (err) {
      this.error = this.$t('Invalid SIM').toString();
    }

    // this.api.simData(this.subId).then(sim => {
    //   this.pin = sim.pin;
    //   this.puk = sim.puk;
    //   this.iccid = sim.iccid;
    // }).catch((err: any) => {
    //   this.error = this.$t('Invalid SIM').toString();
    // });

    this.processing = false;
  }
}
