
















































































































































import { Mixins, Prop, Watch, Model, Component } from 'vue-property-decorator';
import { Utils } from '@/mixins';
import { Queue } from '../../../api';
import AgentPlate from '@/components/AgentPlate.vue';
import _ from 'lodash';

@Component({
    components: {
        AgentPlate,
    },
})
export default class QueuePlate extends Mixins(Utils) {

    @Prop({ required: true })
    private queue!: Queue;

    @Model('change', { type: Boolean })
    private expand!: boolean;

    @Prop({ required: true })
    private processing!: boolean;

    get subtitle() {
        return this.$t('Available agents') + ': ' + this.queue.available_agents + '/' + this.queue.total_agents;
    }

    get name() {
        return this.queue.name;
    }

    get agents() {
        return _.orderBy(this.queue.agents, ['available', 'destination_id']);
    }

    get description() {
        return this.queue.description;
    }

    get checked() {
        return this.queue.available;
    }

    get joined() {
        return this.queue.joined;
    }

    get available() {
        return this.queue.available;
    }

    get status() {
        return this.queue.available ? this.$t('AVAILABLE') : this.$t('UNAVAILABLE');
    }

    get danger() {
        const { available_agents, total_agents } = this.queue;
        const ratio = total_agents / available_agents;

        if (ratio <= 0.25) {
            return true;
        }

        if (total_agents <= 4 && ratio <= 0.5) {
            return true;
        }

        return false;
    }
}
