



















































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator';
import { Utils } from '@/mixins';
import { match } from 'minimatch';
import _ from 'lodash';
import { getAssetPath, loadTheme } from "../utils";
import * as AppApi from "../../../api";

@Component
export default class Header extends Mixins(Utils) {

    get header() {        
        const theme: AppApi.Theme|null = loadTheme();
        if(theme && theme.found) {
            return theme.menu_header;
        } else {            
            const asset_path = getAssetPath();            
            const ret = `${asset_path}img/header.svg`;
            console.log("Header get header", ret);
            return ret;        
        }        
    }

    public titleBarClicked() {

        if (this.isBackEnabled) {
            this.$router.back();
        } else {
            this.$router.push('../my-profile');
        }
    }

    get isBackEnabled() {
        const matched = this.$route.matched;
        return _.trim(this.$route.path, '/ ').split('/').length > 1;
    }

    get isBackToProfileScreen() {
        const pathParts = _.trim(this.$route.path, '/ ').split('/');
        return pathParts.includes('my-profile');
    }
}
