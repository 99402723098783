















































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Utils } from '@/mixins';
import { Message } from '../../../../api';
import Roaming from '@/components/event-history/Roaming.vue';

@Component({
  components: {
    Roaming,
  },
})
export default class MessageTable extends Mixins(Utils) {
  @Prop()
  public messages!: Message[];

  @Prop()
  public numbers!: string[];

  public otherNumber(message: Message) {
    if (this.numbers.indexOf(message.origin) === -1) {
      return message.origin;
    } else {
      return message.destination;
    }
  }
}
