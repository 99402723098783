
import * as AppApi from '../../api';
import { STORAGE } from './constants';
import { Vue } from 'vue-property-decorator';
import { FORBIDDEN, UNAUTHORIZED, INTERNAL_SERVER_ERROR, getStatusText } from 'http-status-codes';
import Axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { Globals } from './globals';


let ASSET_PATH = "";

export function getAssetPath() {
  return ASSET_PATH;
}
export function setAssetPath() {
  for( const key in process.env ) {
    console.log(`process.env.${key}`, process.env[key]);
  }
  if (!process.env.CORDOVA_PLATFORM || process.env.CORDOVA_PLATFORM === 'browser') {
    ASSET_PATH = "/";
  } else {
    ASSET_PATH = "";
  }
}

export function urlQuery(url: string, params: { [key: string]: string }) {
  const querystring = Object.keys(params)
    .map(key => `${encodeURI(key)}=${encodeURI(params[key])}`)
    .join('&');

  return url + '?' + querystring;
}

export class EasyAppApi extends AppApi.EasyAppAPI {
  // public failed: Map<string, number>;
  private vm: Vue;

  constructor(vm: Vue) {
    const baseUrl = process.env.VUE_APP_SERVER_BASE_URL!;
    const token = vm.$store.getters.token;

    super(baseUrl, token || undefined);

    this.vm = vm;

    this.setUpErrorHandler();
  }

  public async isPrivateCustomer() {
    if(this.vm.$store.getters.privateCustomer === 0) {
      const info = await this.info();
      this.vm.$store.commit("privateCustomer", info.customer.type === "private" ? 1 : -1);
    }
    return this.vm.$store.getters.privateCustomer > 0 ? true : false;
  }

  private setUpErrorHandler() {
    this.api.interceptors.response.use(res => res, (error: AxiosError) => {
      if (error.response) {
        const status = error.response!.status as number;
        if (status >= 400) {
          if (status === FORBIDDEN) {
            const data = error?.response?.data as any;
            console.log("missing perm data",data);
            const missing: string[] = data?.error?.missing ? data.error.missing: [];
            const youlack = this.vm.$t(
              'Missing permissions',
            ).toString();
            const message = `${youlack}: ${missing.join(', ')}.`;

            this.setError(status, error, message);
          }

          if (status === UNAUTHORIZED) {
            const authproblem = this.vm.$t(
              'Authentication error. Try restarting.',
            ).toString();
            const message = `${authproblem}`;

            this.setError(status, error, message);
          }

          if (status >= 500) {
            const serverproblem = this.vm.$t(
              'Application error. Try restarting.',
            ).toString();
            const message = `${serverproblem}`;
            throw error;
            this.setError(status, error, message);
          }

          // this.vm.$forceUpdate();
        }
      } else {
        this.setError(0, error, this.vm.$t('Network error. Check your connection.').toString());
      }
    });
  }

  private setError(status: number, error: any, message: string) {
    console.error(getStatusText(status), message, error);

    const self = this;
    setTimeout(() => {
        console.log("clearing error");
        self.vm.$store.commit('clearError');
        self.vm.$forceUpdate();
    }, 10000);
    console.log("setting error");

    this.vm.$store.commit('setError', message);
  }
}

export function logOut() {
  localStorage.removeItem(STORAGE.TOKEN);
  // router.push({ name: 'login' });
}

export function getToken(): string {
  const token = localStorage.getItem(STORAGE.TOKEN);
  if (token) {
    return token;
  } else {
    logOut();
    return '';
  }
}

/** Rund `value` to nearest multiple of `step`. */
export function roundNearest(value: number, step: number) {
  return Math.round(value / step) * step;
}

export function FloorNearest(value: number, step: number) {
  return Math.floor(value / step) * step;
}

function get_name() {
  const theme: AppApi.Theme | null = loadTheme();
  if(theme && theme.found) {
    return theme.name;
  } else {
    return Globals.name;
  }
}

function hash(s: string) {
  let h = 0;
  if(s) {
    for (let i = 0; i < s.length; i++) {
      const chr   = s.charCodeAt(i);
      h  = ((h << 5) - h) + chr | 0;
    }
  }
  return h;
}

function theme_from_globals(): AppApi.Theme {
  const asset_path = getAssetPath();
  return {
    found: true,
    name: Globals.name,
    help_link: Globals.urls.help,
    mainColor: Globals.colors.alpha,
    mainBgTextColor: Globals.colors.brand,
    favicon: `${asset_path}img/favicon.png`,
    login_header: `${asset_path}img/logo.svg`,
    menu_header: `${asset_path}img/header.svg`,
    incoming_color: Globals.colors.incoming,
    outgoing_color: Globals.colors.outgoing
  };
}


export function switchTheme(vue: Vue) {
  const t = loadTheme();
  const theme = t && t.found ? t : theme_from_globals();
  if(theme.found && validTheme(theme)) {
    console.log("switching theme to", theme.name);
    document.title = theme.name;
    const root: HTMLElement = document.documentElement;
    root.style.setProperty('--brand', theme.mainBgTextColor);
    console.log("set brand to ", theme.mainBgTextColor);
    root.style.setProperty('--alpha', theme.mainColor);
    console.log("set alpha to ", theme.mainColor);
    root.style.setProperty('--incoming', theme.incoming_color);
    console.log("set incoming_color to", theme.incoming_color);
    root.style.setProperty('--outgoing', theme.outgoing_color);
    console.log("set outgoing_color to", theme.outgoing_color);
    const favicon_link = document.getElementById("favicon_link");
    if(favicon_link) {
      favicon_link.setAttribute("href", theme.favicon);
      console.log("set favicon", hash(theme.favicon));
    }
    const site_header = document.getElementById("site_header");
    if(site_header) {
      site_header.setAttribute("src",theme.menu_header);
      console.log("set site_header", hash(theme.menu_header));
    }
    const site_logo = document.getElementById("site_logo");
    if(site_logo) {
      site_logo.setAttribute("src",theme.login_header);
      console.log("set site_logo", hash(theme.login_header));
    }
  }
  vue.$forceUpdate();
}

export function validTheme( theme: AppApi.Theme ) {  
  return theme.found && theme.name && theme.favicon && theme.help_link 
          && theme.login_header && theme.menu_header && theme.mainColor 
          && theme.mainBgTextColor;
}

/**
 * loads theme object from local storeage and returns it, returns null if no theme has been set
 */
export function loadTheme(): AppApi.Theme | null {
  const theme = localStorage.getItem("theme_data");
  if(theme) {
    return JSON.parse(theme);
  }
  return null;
}

/**
 * Stores theme object in local storage as json serialization
 */
export function storeTheme( theme: AppApi.Theme ) {
  if(theme.found) {
    localStorage.setItem("theme_data", JSON.stringify(theme));
  }  else {
    localStorage.removeItem("theme_data");
  }
}

export function clearTheme() {
  localStorage.removeItem("theme_data");
}
