import { Component, Mixins, Model, Prop } from 'vue-property-decorator';
import { Utils } from '@/mixins';

@Component
export default class CheckPlate extends Mixins(Utils) {
  @Prop()
  public text!: string;

  // @Model('change', { type: Boolean }) public readonly checked!: boolean;
  @Prop()
  public checked!: boolean;
}
