











import { Component, Mixins } from "vue-property-decorator";
import { Utils } from "@/mixins";
import { UserPresence } from "../../../../api";
import { Option, Some, None } from "tsoption";

export interface ListReference {
  name: string;
  icon?: string;
  id: number;
  title: string;
}

@Component({})
export default class SelectReference extends Mixins(Utils) {
  public managed = true;

  public created() {
    console.log("Reference created");
    this.api.getPresence().then((o: any) => {
      const opt = Option.of<UserPresence>(o.value);
      if (opt.isEmpty()) {
        this.managed = false;
      }
    });
  }
}
