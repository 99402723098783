<template>
  <div class="[ o-gutter-bottom-md o-gutter-bottom-lg@lg ]">
    <div class="o-gutter__item [ u-soft-bottom-sm u-soft-bottom-md@lg ]">
      <h1 class="u-push-bottom-clear">{{ prettyTitle }}</h1>
    </div>
    <div class="o-gutter__item">
      <div class="[ u-bleed-sides-sm u-bleed-sides-md@xl ]">
        <div class="u-width-auto">
          <span
            @click="previous"
            v-if="offset>0"
            style="cursor:pointer;"
            class="u-soft-ends-xs u-inline-block u-width-1/2 u-text-center u-border-top"
          > <pre style="display:inline"></pre>&lsaquo; {{ $t('Newer') }}</span>
          <span
            v-else
            class="u-soft-ends-xs u-inline-block u-width-1/2 u-text-center u-border-top"
          > <pre style="display:inline"> </pre> </span>
          <span
            @click="next"
            v-if="offset+limit < data.length"
            style="cursor:pointer;"
            class="u-soft-ends-xs u-inline-block u-width-1/2 u-text-center u-border-top"
          > <pre style="display:inline"> </pre>{{ $t('Older') }} &rsaquo;</span>
          <span
            v-else
            class="u-soft-ends-xs u-inline-block u-width-1/2 u-text-center u-border-top"
          > <pre style="display:inline"> </pre> </span>
        </div>
        <hr />
      </div>
      <div v-if="processing">{{ $t('Loading...') }}</div>
      <div v-else class="o-gutter-bottom-sm">
        <div class="o-gutter__item">
          <CallTable v-if="calls.length > 0" :calls="calls" :numbers="numbers" :show_date="false" :number_alias="number_alias"/>
          <p v-else class="u-text-omicron">{{ $t('No calls this day') }}.</p>
        </div>
      </div>
      <div class="[ u-bleed-sides-sm u-bleed-sides-md@xl ]">
        <div class="u-width-auto">
          <span
            @click="previous"
            v-if="offset>0"
            style="cursor:pointer;"
            class="u-soft-ends-xs u-inline-block u-width-1/2 u-text-center u-border-top"
          ><pre style="display:inline"> </pre>&lsaquo; {{ $t('Newer') }}</span>
          <span
            v-else
            class="u-soft-ends-xs u-inline-block u-width-1/2 u-text-center u-border-top"
          ><pre style="display:inline"> </pre></span>
          <span
            @click="next"
            v-if="offset+limit < data.length"
            style="cursor:pointer;"
            class="u-soft-ends-xs u-inline-block u-width-1/2 u-text-center u-border-top"
          ><pre style="display:inline"> </pre> {{ $t('Older') }} &rsaquo;</span>
          <span
            v-else
            class="u-soft-ends-xs u-inline-block u-width-1/2 u-text-center u-border-top"
          ><pre style="display:inline"> </pre></span>
        </div>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import s from "./call-log";
export default s;
</script>

<style scoped>
.full-table {
  width: 100%;
}

th,
td {
}
</style>