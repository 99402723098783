import { Component, Prop, Mixins } from 'vue-property-decorator';
import { Utils } from '@/mixins';
import ColorfulPlate from '@/components/ColorfulPlate.vue';

@Component({
  components: {
    ColorfulPlate,
  },
})
export default class SelectPlate extends Mixins(Utils) {
  @Prop()
  public items!: string[];
  @Prop()
  public names!: string[];

  @Prop()
  public item!: string;

  @Prop()
  public title!: string;

  @Prop()
  public processing!: boolean;

  @Prop()
  public handler!: (s: string) => void;

  public expanded: boolean = false;
  public selected: any = this.item;  

  get nochange() {
    return this.selected === this.item;
  }

  get saving() {
    return this.processing;
  }

  get listItems() {
    return this.items.map(this.listItem);
  }

  public toggleExpand() {
    this.expanded = !this.expanded;
  }

  public checked(item: any) {
    console.log("checked ", item, "=", item === this.selected);
    return item === this.selected;
  }

  public clickbutton(item: any) {
    console.log("clickbutton ", item);
    if(this.selected !== item) {
      this.selected = item;
    }    
  }

  public handleSave() {
    console.log("handleSave", this.selected);
    this.handler(this.selected);
  }

  public listItem(item: any) {
    return { description: item };
  }
}
