import _ from 'lodash';

/** A function taking the provided user permissons
 * and returns a list of missing permissions, or empty list if all OK. */
export type PermFunction = (userPerms: string[]) => string[];
export type Permissions = string | string[] | PermFunction;

export const MODULES = {
  /** Standard features. */
  CORE: 'core',
  /** Authentication features. */
  AUTH: 'auth',
  /** Developer features. */
  DEBUG: 'debug',
  /** Database manipulation. */
  RESOURCES: 'resources',
  /** Microsoft Graph API, Outlook integration. */
  GRAPH: 'graph',
};

export const SCOPE = 'app.e9m.se';

export const APP_PERMISSION_PREFIX = 'app.';

export const PERMISSIONS = {
  // User-level
  USER: {
    SIM: {
      READ: 'app.user.sim.read',
    },
    INFO: 'app.user.info',
    SUBSCRIPTION: 'app.user.subscription',
    PRESENCE: {
      READ: 'app.user.presence.read',
      UPDATE: 'app.user.presence.update',
    },
    CALLS: {
      RECENT: 'app.user.calls.recent',
      READ: 'app.user.calls.read',
    },
    EVENT_HISTORY: 'app.user.event-history',
    CALL_LOG: 'app.user.call-log',
    INVOICES: {
      RECENT: 'app.user.invoices.recent',
      READ: 'app.user.invoices.read',
    },
    RECORDINGS: {
      RECENT: 'app.user.recordings.recent',
      READ: 'app.user.recordings.read',
      DOWNLOAD: 'app.user.recordings.download',
      FAVOURITE: 'app.user.recordings.favourite',
    },
    VOICEMAILS: {
      RECENT: 'app.user.voicemails.recent',
      READ: 'app.user.voicemails.read',
      DOWNLOAD: 'app.user.voicemails.download',
      DELETE: 'app.user.voicemails.delete',
    },
    CONSUMPTION: 'app.user.consumption',
    QUEUE: {
      AVAILABILITY: 'app.user.queue.availability',
      MEMBERSHIP: 'app.user.queue.membership',
    },
    REFERENCES: {
      READ: 'app.user.references.read',
      UPDATE: 'app.user.references.update',
    },
    GRAPH: {
      CONNECT: 'app.user.graph.connect',
    },
    ADDONS: {
      BUY: 'app.user.addons.buy',
    },
    CHAT: 'app.user.chat',
  },
  // Customer-level
  ADDONS: {
    READ: 'app.addons.read',
    BUY: 'app.addons.buy',
  },
  QUEUES: {
    READ: 'app.queues.read',
    MEMBERSHIP: 'app.queues.membership',
    AGENTS: {
      READ: 'app.queues.agents.read',
    },
  },
  SUBSCRIPTIONS: {
    READ: 'app.subscriptions.read',
  },
  ADMIN: 'app.admin',
  SELF_ADMIN: 'app.self_admin',
  PERMISSIONS: {
    READ: 'app.permissions.read',
    UPDATE: 'app.permissions.update',
  },
  REFERENCES: crud('references'),
  USERS: crud('users'),
};

function crud(resource: string) {
  const perms: ResourcePermissions = {
    CREATE: 'app.' + resource + '.create',
    READ: 'app.' + resource + '.read',
    UPDATE: 'app.' + resource + '.update',
    DELETE: 'app.' + resource + '.delete',
  };

  return perms;
}

export type ResourcePermissions = {
  /** Create new resources. */
  CREATE: string;
  /** List all resources. */
  READ: string;
  /** Update a resource. */
  UPDATE: string;
  /** Delete a resource. */
  DELETE: string;
};

interface PermObject {
  [key: string]: PermObject | string;
}

export function allPermissions() {
  return extractPermissions(PERMISSIONS);
}

function extractPermissions(obj: PermObject | string): string[] {
  if (typeof obj === 'string') {
    return [obj];
  }
  let res: string[] = [];
  Object.keys(obj).forEach(key => {
    res = _.concat(res, extractPermissions(obj[key]));
  });
  return res;
}

export function checkFactory(requredPerms: Permissions) {
  return (userPerms: string[]) => checkPermissions(userPerms, requredPerms);
}

/** True if the user permissions satisfy the required permissions. */
export function checkPermissions(userPerms: string[], requredPerms: Permissions): boolean {
  return unmetPermissions(userPerms, requredPerms).length === 0;
}

/** Return the permissions not met by the user. */
export function unmetPermissions(userPerms: string[], requiredPerms: Permissions) {
  if (typeof requiredPerms === 'string') {
    return [requiredPerms].filter(p => userPerms.indexOf(p) === -1);
  }

  if (Array.isArray(requiredPerms)) {
    return requiredPerms.filter(p => userPerms.indexOf(p) === -1);
  }

  if (typeof requiredPerms === 'function') {
    return requiredPerms(userPerms);
  }

  throw new Error(`Invalid permissions ${requiredPerms}, must be either string, string[] or permisison function`);
}

/** Collapse any permissions into a list of strings. */
export function collapsePermissions(perms: Permissions) {
  return unmetPermissions([], perms);
}
