import { render, staticRenderFns } from "./UserPlateLarge.vue?vue&type=template&id=75d7f492&functional=true&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports