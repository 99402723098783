
























import { Component, Mixins } from "vue-property-decorator";
import SubscriptionPlate from "@/components/plates/SubscriptionPlate.vue";
import { Utils } from "@/mixins";
import { SubscriptionSummary } from "../../../../api";
import _ from "lodash";

import placeholder from "@/assets/img/user-placeholder.png";

@Component({
  components: {
    SubscriptionPlate
  }
})
export default class SimDataIndex extends Mixins(Utils) {
  public placeholder = placeholder;

  private subscriptions: SubscriptionSummary[] = [];
  private processing = true;

  public created() {
    this.refresh();
  }

  public async refresh() {
    this.processing = true;

    const subs = await this.api.subscriptions();
    console.log("subs =", subs);

    const am = this.$store.getters.adminMode;

    // Select mobile subscriptions
    // TODO this will break once mobile numbers can be any format (in 2025 ish)
    // TODO doesnt work with non swedish numbers
    const withSelection = subs.filter(
      sub => sub.primary_number != null && sub.primary_number.startsWith("07")
    );

    // Put own subscription on top, rest in lexiographic order
    let subscriptions = _.orderBy(
      withSelection,
      ["own", "name"],
      ["desc", "asc"]
    );

    if (this.subscriptions.length === 1) {
      subscriptions = this.subscriptions.map(sub => ({
        ...sub,
        selected: true
      }));
    }

    this.subscriptions = subscriptions;

    this.processing = false;
  }

  public openSIM(sub: SubscriptionSummary) {
    this.$router.push({
      name: "sim-data-view",
      params: { id: sub.id.toString() }
    });
  }

  get filteredSubscriptions() {
    const am = this.$store.getters.adminMode;
    return this.subscriptions.filter(sub => am || sub.own);
  }
}
