/**
 * EasyApp Server API routes.
 * Has to have a leading slash and no trailing slash.
 */
export const ROUTES = {
  MAIN_WS_SERVER: '/ws/main',
  INFO: '/info',
  CHATS: '/chats',
  USER_LIST: '/user-list',
  SUBSCRIPTIONS: '/subscriptions',
  PRODUCTS: '/products',
  VERSION: '/version',
  PERMISSIONS: '/permissions',
  INVOICES: '/invoices',
  INVOICE: '/invoice',
  SIM_DATA: '/sim-data',
  MY_NUMBERS: '/my-numbers',
  LOGIN: '/login',
  TEST_TOKEN: '/test-token',
  RESET: '/reset',
  FORGOT: '/forgot',
  PROFILE: '/profile',
  RECENTS: '/recents',
  STATUSES: '/statuses',
  USERS: '/users',
  GROUPS: '/groups',
  RECORDINGS: '/recordings',
  VOICEMAILS: '/voicemails',
  VOICEMAIL_DOWNLOAD: '/voicemail-download',
  CALL_HISTORY: '/call-history',
  EVENT_HISTORY: '/event-history',
  CALLS: '/calls',
  SIMS: '/sims',
  SUSPEND_SIM: '/suspend-sim',
  PHONE_CONFIG: '/phoneconfig',
  TOPUPS: '/topups',
  BUY_TOPUP: '/buytopup',
  REFERENCES: '/references',
  QUEUES: '/queues',
  SAVE_QUEUE_AVAILABLE: '/save-queue-available',
  QUEUE_MEMBERSHIP: '/queue-membership',
  CUSTOMERS: '/customers',
  COUNTRIES: '/countries',
  CURRENCIES: '/currencies',
  REFERENCE_STATUSES: '/reference-statuses',
  STATUS_STATES: '/status-states',
  NUMBER_TO_DISPLAY: '/number-to-display',
  USER_PRESENCE: '/user-presence',
  MY_SUBSCRIPTION: '/my-subscription',
  TRANSACTIONS_BALANCE: '/transactions-balance',
  CHECK_USER_REF: '/check-user-ref',
  MAKE_USER_REF: '/make-user-ref',
  GET_USER_REF: '/get-user-ref',
  UPDATE_USER_REF: '/update-user-ref',
  CANCEL_USER_REF: '/cancel-user-ref',
  OUTLOOK_RETURN: '/auth/openid/return',
  GRAPH_REGISTER: '/graph/register',
  PSW_RESET_REQUEST_CODE: '/psw-reset/request-code',
  PSW_RESET_VERIFY_CODE: '/psw-reset/verify-code',
  PRESENTATION_NUMBERS: '/presentation-numbers',
  PRESENTATION_NUMBERS_ADD: '/presentation-numbers/add',
  PRESENTATION_NUMBERS_REMOVE: '/presentation-numbers/remove',
  CONTACTS: '/contacts',
  CONTACTS_STORE: '/contacts/store',
  THEME: '/theme',
  SELF_ADMIN_LIST_SUBSCRIPTIONS: '/self-admin/list-subscriptions',
  SELF_ADMIN_LIST_TEMPLATES: '/self-admin/list-templates',
  SELF_ADMIN_LIST_SIMS: '/self-admin/list-sims',
  SELF_ADMIN_LIST_NUMBERS: '/self-admin/list-numbers',
  SELF_ADMIN_RANDOM_NUMBERS: '/self-admin/random-numbers',
  SELF_ADMIN_SUBSCRIPTION_BINDING: '/self-admin/subscription-binding',
  SELF_ADMIN_CREATE_SUBSCRIPTION: '/self-admin/create-subscription',
  SELF_ADMIN_CANCEL_SUBSCRIPTION: '/self-admin/cancel-subscription'
};

/** API routes prefix. */
export const API_PREFIX = '/api';

export const DEFAULT_REFERENCES = {
  LUNCH: {
    name: 'lunch',
    reason: 0,
  },
  GONE_HOME: {
    name: 'gone.home',
    reason: 1,
  },
  ON_FLIGHT: {
    name: 'on.flight',
    reason: 2,
  },
  IN_MEETING: {
    name: 'in.meeting',
    reason: 3,
  },
  BUSINESS_TRIP: {
    name: 'business.trip',
    reason: 4,
  },
  SICK_LEAVE: {
    name: 'sick.leave',
    reason: 5,
  },
  VACATION: {
    name: 'vacation',
    reason: 6,
  },
  VACANT: {
    name: 'vacant',
    reason: 7,
  },
  TEMP_UNAVAILABLE: {
    name: 'temp.unavailable',
    reason: 8,
  },
  IN_EDUCATION: {
    name: 'in.education',
    reason: 9,
  },
};

export const STATUS_STATES = {
  PROCESSED: 'processed',
  REMOVED: 'removed',
  REMOVED_BY_NEWER_REF: 'removed.by.newer.ref',
  REMOVED_BY_TIMEOUT: 'removed.by.timeout',
  REMOVED_BY_USER: 'removed.by.user',
  REMINDER_SEND: 'reminder.sent',
  IN_CALL: 'in.call',
  INITIAL_SENT: 'initial.sent',
};

export const CRM = {
  PERMISSIONS: {
    INVOICE_VIEW: 'invoice.view',
  },
};
