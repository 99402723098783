





























































































import s from "./settings-screen";
export default s;
