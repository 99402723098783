import { Vue, Component, Mixins } from 'vue-property-decorator';
import { EasyAppApi, getToken } from '@/utils';
import { GRAPH_ROUTES, API_PREFIX, SOCKET_ROUTES, socket_types as st } from '../../../api';
import * as qs from 'qs';
import { Utils } from '@/mixins';
import {send, register_listener, unregister_listener} from '../WebSocketService';


@Component
export default class GraphLogin extends Mixins(Utils) {
  public status: 'logged-in' | 'logged-out' | 'processing' = 'logged-out';
  public graph_registration_key: string | null = null;
  public register_result: st.GraphRegisterResult | null = null;
  public processing = true;
  private timer_id: any = null;

  public async created() {
    const api = new EasyAppApi(this);
  }

  public mounted() {
    console.log("mounted");
    send(SOCKET_ROUTES.GRAPH_CHECK_STATUS, {type: 'void'}).then((res: st.Message) => {
      if(st.is_graph_register_result(res.data)) {
        this.register_result = st.filter_graph_register_result(res.data);
        console.log("register result = ", {...this.register_result});
        this.status = this.register_result.registered ? 'logged-in' : 'logged-out';
        this.$forceUpdate();
      } else {
        console.log("graph_check_status reply not valid register result", res);
      }
      this.processing = false;
    });
    this.timer_id = setInterval(this._update_register_result.bind(this), 5000);
  }  

  public async destroyed() {
    console.log("destroyed");
    console.log("unmounted");
    if(this.graph_registration_key) {
      unregister_listener(this.graph_registration_key, this.handle_graph_register);
    }
    if(this.timer_id) {
      clearInterval(this.timer_id);
    }
    
  }

  

  public connect() {
    console.log('click log in');
    if (!this.register_result?.registered) {
      this.handleLogin();
    }
  }

  public disconnect() {
    console.log("disconnect clicked!");
    send(SOCKET_ROUTES.GRAPH_DISCONNECT, {type: 'void'}).then((msg: st.Message)=> {
      console.log("disconnect graph result ", msg);
      if(st.is_graph_register_result(msg.data)) {
        this.register_result = st.filter_graph_register_result(msg.data);        
      }
      this.$forceUpdate();
    });
  }

  public handleLogin() {
    console.log("VUE_APP_SERVER_BASE_URL", process.env.VUE_APP_SERVER_BASE_URL);
    this.status = 'processing';
    const token = getToken();
    console.log("graph-login token", token);
    send(SOCKET_ROUTES.GRAPH_REGISTER_ID, {type: 'void'}).then((res: st.Message) => {
      console.info("graph-login created graph_register_id result", res);
      if(st.is_graph_register_id(res.data)) {
        const reg = st.filter_graph_register_id(res.data);
        this.graph_registration_key = reg.id;
        console.log("registering listener to ", reg.id);
        register_listener(reg.id, this.handle_graph_register);
        this.openLogin(reg.id);
      }
    });
  }

  private _update_register_result() {    
    send(
      SOCKET_ROUTES.GRAPH_CHECK_STATUS, {type: 'void'}
    ).then((res: st.Message) => {
      if(st.is_graph_register_result(res.data)) {        
        this.register_result = st.filter_graph_register_result(res.data);        
        console.log("updated register result = ", {...this.register_result});
        this.status = this.register_result.registered ? 'logged-in' : 'logged-out';
        this.$forceUpdate();
      }      
    });
  }

  private openLogin(id: string) {
    const startUrl = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
    // const endUrl = process.env.VUE_APP_GRAPH_REDIRECT!;
    const endUrl = process.env.VUE_APP_SERVER_BASE_URL! + API_PREFIX + GRAPH_ROUTES.REDIRECT;
    const state = id;

    const params: { [key: string]: string } = {
      client_id: process.env.VUE_APP_GRAPH_CLIENT_ID!,
      scope: process.env.VUE_APP_GRAPH_SCOPES!,
      response_type: 'code',
      redirect_uri: endUrl,
      state,
    };

    const loginUrl = startUrl + '?' + qs.stringify(params);

    if (this.isBrowser) {

      console.log('Browser login');

      console.log('Opening', loginUrl);
      const browser = window.open(loginUrl, '_blank');

    } else {
      console.log('Mobile login');

      // InAppBrowser has incorrect typings
      const browser = ((cordova as any).InAppBrowser as Window).open(loginUrl, '_blank', 'location=yes');
      if (!browser) {
        console.error('Could not open window to', browser);
        return;
      }
      browser.addEventListener('loadstart', (evt: any) => {
        const url = evt.url;
        if (url.indexOf(endUrl) === 0) {
          browser.close();
        }
      });
    }
  }

  private handle_graph_register(msg: st.Message) {
    console.log("handle_graph_register msg", msg);
    if(st.is_graph_register_result(msg.data)) {
      this.register_result = st.filter_graph_register_result(msg.data);
      unregister_listener(this.graph_registration_key!, this.handle_graph_register);
      if(this.register_result.registered) {
        this.status = 'logged-in';
      }
    }
    return;
  }
}
