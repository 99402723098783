import { Component, Mixins } from 'vue-property-decorator';
import { Events, SummaryItem } from '@/mixins';
import { CallHistorySummary } from '../../../../api';
import moment from 'moment';
import _ from 'lodash';
import LinkPlate from '@/components/LinkPlate.vue';
import EventPlate from '@/components/plates/EventPlate.vue';

const MIN_KB = 1000;

@Component({
  components: {
    EventPlate,
    LinkPlate,
  },
})
export default class EventHistoryDay extends Mixins(Events) {
  public processing: boolean = true;

  public chs: CallHistorySummary | null = null;

  public start: string = '';
  public end: string = '';

  public created() {
    this.setup();
  }

  public setup() {
    const month = this.fromYearMonth();

    this.start = month.format('YYYY-MM-DD [00:00:00]');
    this.end = month.date(month.daysInMonth()).format('YYYY-MM-DD [23:59:59]');

    this.refresh().then(chs => {
      if (chs.count > chs.limit && chs.limit < 5000) {
        this.refresh(chs.count > 5000 ? 5000 : chs.count);
      }
    });
  }

  public async refresh(limit = 100) {
    this.processing = true;
    const chs = await this.api.callHistorySummary({
      start: this.start,
      end: this.end,
      limit,
    });
    this.chs = chs;
    this.processing = false;
    return chs;
  }

  public fromYearMonth() {
    const date = moment(`${this.$route.params.year}-${this.$route.params.month}-01`);
    date.locale(this.$i18n.locale);
    return date;
  }

  public openDay(day: DayItem) {
    this.$router.push({
      name: 'event-history-view',
      params: {
        year: this.$route.params.year,
        month: this.$route.params.month,
        day: day.day,
      },
    });
  }

  get prettyTitle() {
    const month = this.fromYearMonth();
    return _.capitalize(month.format('MMMM YYYY'));
  }

  get days() {
    const result: DayItem[] = [];

    if (this.chs) {
      this.chs.items.forEach(item => {
        const date = moment(item.date);
        date.locale(this.$i18n.locale);

        const day = date.date().toString();

        // if (result.findIndex(value => value.day === day) === -1) {
        const year = date.year().toString();
        const month = date.month().toString();
        const next: DayItem = {
          day,
          month,
          year,
          name: _.capitalize(date.format('dddd, Do')),
          calls: item.units_voice,
          messages: item.units,
          data: item.units_data,
        };
        result.push(next);
        // }
      });
    }

    return _.reverse(_.sortBy(result.filter(pseudoNonEmpty), item => Number(item.day)));
  }

  public next() {
    const prev = this.fromYearMonth().add(1, 'month');
    this.navigateMonth(prev);
  }

  public previous() {
    const prev = this.fromYearMonth().subtract(1, 'month');
    this.navigateMonth(prev);
  }

  public navigateMonth(date: moment.Moment) {
    const year = date.year().toString();
    const month = (date.month() + 1).toString();

    this.$router.push({
      name: 'event-history-day',
      params: {
        year,
        month,
      },
    });

    this.$nextTick(this.setup);
  }
}

function pseudoNonEmpty(d: DayItem): boolean {
  return d.calls > 0 || d.messages > 0 || d.data > MIN_KB;
}

export interface DayItem extends SummaryItem {
  name: string;
  year: string;
  month: string;
  day: string;
}
