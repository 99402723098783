



















import { Mixins, Component, Prop } from 'vue-property-decorator';
import { Utils } from '@/mixins';
import VoicemailPlate from '@/components/plates/VoicemailPlate.vue';
import { Voicemail } from '../../../../api';
import placeholder from '@/assets/img/user-placeholder.png';
import moment from 'moment';

@Component({
  components: {
    VoicemailPlate,
  },
})
export default class VoicemailList extends Mixins(Utils) {
  @Prop()
  public voicemails!: Voicemail[];

  private placeholder = placeholder;

  get relativeVoicemails() {
    if (!this.voicemails) {
      return [];
    }

    return this.voicemails.map(vm => {
      const prettyTime = moment.unix(vm.start).locale(this.$i18n.locale).format('LLL');
      return {
        ...vm,
        prettyTime,
      };
    });
  }
}
