



















import { parsePhoneNumber, parse, PhoneNumber } from 'libphonenumber-js';
import { Mixins, Component, Prop } from 'vue-property-decorator';
import { Utils } from '@/mixins';
import CallPlate from '@/components/plates/CallPlate.vue';
import {Call, checkPermissions, NumberAliasMap, PERMISSIONS, Relative } from '../../../../api';
import moment from 'moment';
import userImgSrc from '@/assets/img/user-placeholder.png';

@Component({
  components: {
    CallPlate,
  },
})
export default class CallList extends Mixins(Utils) {
  @Prop()
  public calls!: Array<Relative<Call>>;

  @Prop() public numbers!: string[];

  @Prop() public number_alias?: NumberAliasMap;

  public placeholder = userImgSrc;

  get formattedCalls() {
    return this.calls.map(call => {
      // const outgoing = this.numbers.indexOf(call.origin) !== -1;
      const prettyTime = moment.unix(call.start).locale(this.$i18n.locale).format('LLL');
      return {
        ...call,
        // outgoing,
        // co_number: outgoing ? call.destination : call.origin,
        prettyTime,
      };
    });
  }

  public openEventDay(call: Relative<Call>) {
    const self = this;
    return (param: number) => {
      console.log("openEventDay", param, call);
      if(param === 1) {
        // didnt click tel link go to events page
        const perms = self.$store.getters.perms || [];
        if(checkPermissions(perms, [PERMISSIONS.USER.EVENT_HISTORY])) {
          const d = moment.unix(call.start);
          const location = {
            name: 'event-history-view',
            params: {
              year: d.format('YYYY'),
              month: d.format('MM'),
              day: d.format('DD'),
            },
          };
          self.$router.push(location);
        } else if(checkPermissions(perms, [PERMISSIONS.USER.CALL_LOG])) {
          self.$router.push({name:'call-log'});
        }
      } else {
        // clicked the actual number link ignore
      }
    };
  }

  public numberName( call: Relative<Call> ) {
    try {
      const num_data = parsePhoneNumber(call.co_number, "SE");
      if(num_data.isValid()) {
        const nstr = num_data.number as string;
        if(this.number_alias && this.number_alias[nstr]) {
          return this.number_alias[nstr];
        }
        if(nstr.substr(0,3) === "+46") {
          return num_data.formatNational();
        }
        return nstr;
      }
      return this.$t('Protected number').toString();
    } catch(err) {
      return this.formatNumber(call.co_number);
    }
  }

  public numberRef( call: Relative<Call> ) {
    try {
      const num_data = parsePhoneNumber(call.co_number, "SE");
      if(num_data.isValid()) {
        return num_data.number as string;
      }
      return "";
    } catch(err) {
      return "";
    }
  }
}
