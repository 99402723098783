


















































































import s from "./audio-plate";
export default s;
