















import { Component, Mixins, Vue } from 'vue-property-decorator';

@Component
export default class Invoices extends Vue { }
