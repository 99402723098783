import Component from 'vue-class-component';
import { Mixins } from 'vue-property-decorator';
import { Events } from '@/mixins';
import {Paginated, SelfAdminSubscription, SignupTemplate } from '../../../../api';
import i18n from '../../i18n';
@Component({  
})
export default class SelfAdminSubscriptionList extends Mixins(Events) {
  public offset: number = 0;
  public limit: number = 20;
  public total_count: number = 0;
  public data: SelfAdminSubscription[] = [];
  public processing = true;

  public created() {
    console.log("SelfAdminSubscriptionList created()");   
    this.refresh();    
  }

  public mounted() {
    console.log("SelfAdminSubscriptionList mounted()");
  }

  public refresh() {
    const end = Math.min(this.offset + this.limit -1, this.data.length -1);
    if(this.data[this.offset] == null || this.data[end] == null) {
      this.processing = true;
      this.api.selfadmin_list_subscriptions(
        this.offset, this.limit, false
      ).then((subs: Paginated<SelfAdminSubscription>) => {
        console.log("subs = ", subs);
        this.total_count = subs.count;
        if(this.data.length !== subs.count) {
          this.data = new Array(subs.count);
        }
        this.data.splice(this.offset, subs.items.length, ...subs.items);
        this.processing = false;
        console.log("subscription_list:", this.data.length, this.data);
      }).catch( err => {
        console.error("refresh error",err);
      });        
    }        
  }

  get subscriptions(): SelfAdminSubscription[] {
    return this.data.slice(this.offset, this.offset+this.limit);
  }

  public previous() {
    if(!this.processing) {
      this.offset = Math.max(this.offset - this.limit, 0);
      return this.refresh();
    }
    return false;
  }

  public next() {
    if(!this.processing) {
      this.offset = Math.min(this.offset + this.limit, this.total_count);
      return this.refresh();
    }
    return false;
  }

  public go_to_details(sub: SelfAdminSubscription) {
    this.$router.push({
      name: "self-admin-subscription-details", 
      params: { 
        id: String(sub.id) 
      }
    });
  }

  public go_to_create() {
    this.$router.push({name: "self-admin-subscription-create"});
  }

  get prettyTitle() {
    return `${i18n.t("Subscriptions")} ${1 + this.offset} ${i18n.t('to')} ${Math.min(this.offset + this.limit, this.data.length)}`;
  }


}
