



































import s from './my-profile';
export default s;
