


































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Utils } from '@/mixins';

@Component
export default class AccountPlate extends Mixins(Utils) {
  @Prop()
  public user!: any;

  @Prop()
  public img!: { src: string };
}
