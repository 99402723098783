
























import { Mixins, Component, Prop } from "vue-property-decorator";
import { Utils } from "@/mixins";
import RecordingPlate from "@/components/plates/RecordingPlate.vue";
import { Call, Recording } from '../../../../api';
import userPlaceholder from "@/assets/img/user-placeholder.png";
import moment from "moment";

@Component({
  components: {
    RecordingPlate
  }
})
export default class RecordingList extends Mixins(Utils) {
  @Prop()
  public recordings!: Recording[];

  @Prop() public numbers!: string[];

  private placeholder = userPlaceholder;

  get relativeRecordings() {
    return this.recordings.map(recording => {
      const outbound = this.numbers.indexOf(recording.origin) !== -1;
      const prettyTime = moment
        .unix(recording.start)
        .locale(this.$i18n.locale)
        .format("LLL");
      const prettyDeleted =
        this.$t("Expires") +
        " " +
        moment
          .unix(recording.expires)
          .locale(this.$i18n.locale)
          .format("LLL");
      return {
        ...recording,
        outbound,
        co_number: outbound ? recording.destination : recording.origin,
        prettyTime,
        prettyDeleted
      };
    });
  }
}
