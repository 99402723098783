





























































import { Component, Mixins } from 'vue-property-decorator';
import AudioPlate from '@/components/AudioPlate.vue';
import LinkPlate from '@/components/LinkPlate.vue';
import { Utils } from '@/mixins';
import moment from 'moment';

@Component({
  components: {
    AudioPlate,
    LinkPlate,
  },
})
export default class VoicemailListen extends Mixins(Utils) {
  public file: string | null = null;

  public number = '';
  public time = '';

  public confirm = false;

  public created() {
    const filename = this.$route.params.name;

    this.api.voicemail(filename).then(vm => {
      console.log("voicemail", filename, vm);
      const prettyStart = moment.unix(vm.start).locale(this.$i18n.locale).format('LLL');

      this.file = vm.dataurl.startsWith('data:') ? vm.dataurl : process.env.VUE_APP_SERVER_BASE_URL + vm.dataurl;
      this.number = this.formatNumber(vm.origin);
      this.time = prettyStart;
    }).catch(err => {
      console.error(err);
    });
  }

  public async handleDelete() {
    const filename = this.$route.params.name;

    await this.api.deleteVoicemail(filename);

    this.$router.push({ name: 'voicemail-index' });
    }

  public toggleConfirm() {
    this.confirm = !this.confirm;
  }
}
