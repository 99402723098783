import { Component, Mixins } from 'vue-property-decorator';
import CallTable from '@/components/lists/CallTable.vue';
import MessageTable from '@/components/lists/MessageTable.vue';

import { Utils, Events } from '@/mixins';
import moment, { parseTwoDigitYear } from 'moment';
import _ from 'lodash';
import i18n from '../../i18n';
import {
  Call, CallHistory, CallHistoryItem, CallHistoryDestination,
  CallHistoryCustomer, CallHistorySubscription, CallHistorySystem, NumberAliasMap
} from '../../../../api';
import moment_tz from 'moment-timezone';

type MetaCall = { id: string, co_number: string, outbound: boolean } & Call;
const DAY_IN_SECONDS = 24 * 60 * 60;

type DestinationLookup = {
  [key: string]: CallHistoryDestination
};

type CustomerLookup = {
  [key: string]: CallHistoryCustomer
};

type SubscriptionLookup = {
  [key: string]: CallHistorySubscription
};

type SystemLookup = {
  [key: string]: CallHistorySystem
};

@Component({
  components: {
    CallTable,
    MessageTable,
  },
})
export default class CallLog extends Mixins(Events) {
  public limit = 20;
  public offset = 0;
  public data: CallHistoryItem[] = [];
  public destinations: DestinationLookup = {};
  public customers: CustomerLookup = {};
  public systems: SystemLookup = {};
  public subscriptions: SubscriptionLookup = {};

  public processing = true;
  public history: null | CallHistory = null;
  public numbers: string[] = [];
  public number_alias: NumberAliasMap = {};


  public created() {
    this.setup();
  }

  public setup() {
    this.api.getNumberLookups().then(n => {
      this.number_alias = n;
      this.refresh();
    }).catch(err => {
      this.number_alias = {};
      this.refresh();
    });

  }

  public async refresh() {
    const end = Math.min(this.offset + this.limit - 1, this.data.length - 1);
    if (this.data[this.offset] == null || this.data[end] == null) {
      this.processing = true;
      const [call_hist, numbers] = await Promise.all([
        this.api.callHistory({
          limit: this.limit, offset: this.offset, rec_info: true, type: ["voice"], rated: 'both',
          direction: ["in", "out", "internal"]
        }
        ),
        this.api.myNumbers()

      ]);
      this.history = call_hist as CallHistory;
      for(const key in this.history.customer)     { this.customers[key]     = this.history.customer[key];     }
      for(const key in this.history.destination)  { this.destinations[key]  = this.history.destination[key];  }
      for(const key in this.history.subscription) { this.subscriptions[key] = this.history.subscription[key]; }
      for(const key in this.history.system)       { this.systems[key]       = this.history.system[key];       }
      this.numbers = numbers;
      if (this.data.length !== this.history.count) {
        this.data = new Array(this.history.count);
      }
      this.data.splice(this.offset, this.history.items.length, ...this.history.items);
      this.processing = false;
    }
  }


  get calls(): Array<MetaCall | string> {
    const self = this;
    function to_call(item: CallHistoryItem): MetaCall {
      const start = moment_tz.tz(item.timestamp, "Europe/Stockholm").unix();
      const compute_roming = (it: CallHistoryItem) => {
        const history = self.history;
        if (item.roaming && history != null) {
          const { country, network } = item.roaming;
          const eu = _.some(item.destination.map(id => history.destination[id].type.indexOf('region_eu') !== -1));
          return { country, network, eu };
        }
        return undefined;
      };
      let outbound = item.called_number === 'mailbox' ? false : true;
      if(item.destination.length === 0 || (item.destination.length === 1 && item.destination[0] === 0)) {
        outbound = self.numbers.indexOf(item.calling_number) !== -1
                   || self.numbers.indexOf(item.called_number) === -1;
      }
      for(const dest of item.destination) {
        if(self.destinations[dest]) {
          for(const s of self.destinations[dest].type) {
            if(s === 'terminated') {
              outbound = false;
              break;
            }
          }
        }
      }

      return {
        id: start.toString(),
        start,
        end: start + item.units,
        origin: item.calling_number,
        destination: item.called_number,
        duration: item.units,
        co_number: outbound ? item.called_number : item.calling_number,
        recording: item.recording,
        roaming: compute_roming(item),
        outbound,
      };
    }
    const calls = this.data.slice(this.offset, this.offset + this.limit).map(item => to_call(item));
    const ret = calls.reduce((acc: Array<MetaCall | string>, e: MetaCall) => {
      if (acc.length) {
        const last = acc[acc.length - 1];
        if (typeof last !== "string") {
          const ndate = this.formatDateFromUnix(e.start);
          if (ndate !== this.formatDateFromUnix(last.start)) {
            acc.push(ndate);
          }
        }
      } else {
        acc.push(this.formatDateFromUnix(e.start));
      }
      acc.push(e);
      return acc;
    }, []);
    console.log(ret);
    return ret;
  }


  public previous() {
    if (!this.processing) {
      this.offset = Math.max(this.offset - this.limit, 0);
      return this.refresh();
    }
    return false;
  }

  public next() {
    if (!this.processing) {
      this.offset = Math.max(this.offset + this.limit, 0);
      return this.refresh();
    }
    return false;
  }

  private navigateDate(date: moment.Moment) {
    const year = date.year().toString();
    const month = (date.month() + 1).toString();
    const day = date.date().toString();

    // console.log(year, month, day);

    this.$router.push({
      name: 'event-history-view',
      params: {
        year,
        month,
        day,
      },
    });

    this.$nextTick(this.setup);
  }

  get prettyTitle() {
    return `${i18n.t("Call logs")} ${1 + this.offset} ${i18n.t('to')} ${Math.min(this.offset + this.limit, this.data.length)}`;
  }
}
