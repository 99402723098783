

















import { Component, Vue, Mixins } from 'vue-property-decorator';
import Header from '@/navigation/Header.vue';
import Dashboard from '@/components/Dashboard.vue';
import Login from '@/views/Login.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import { Utils } from '@/mixins';
import { setAssetPath} from './utils';
import { connect } from './WebSocketService';
import {get_token} from './store';

const pkg = require('../package.json');

@Component({
  components: {
    Header,
    Dashboard,
    ResetPassword,
    Login,
  },
})
export default class App extends Mixins(Utils) {
  public reset_password = false;

  get loggedIn() {
    return this.$store.getters.loggedIn;
  }

  get resetPassword() {
      return this.$store.getters.resetPassword;
  }

  public toggleResetPassword() {
      this.$store.commit("resetPassword", !this.$store.getters.resetPassword);
  }

  public created() {    
    this.api.version().then((serverVersion: [number, number, number]) => {
      setAssetPath();   
      const token = get_token();
      if(token) {
        connect(token);
      }      
      
      const clientVersion = pkg.version.split('.').map(Number) as [number, number, number];

      if (serverVersion[0] > clientVersion[0]) {
        console.log('Version mismatch. Server:', serverVersion, 'client:', clientVersion);
        this.$store.commit('showVersionWarning');
        return;
      }

      if (serverVersion[1] > clientVersion[1]) {
        console.log('Version mismatch. Server:', serverVersion, 'client:', clientVersion);
        this.$store.commit('showVersionWarning');
        return;
      }
    });
  }

  public beforeRouteUpdate(to: any, from: any, next: any) {
    this.$store.commit('clearError');
  }
}
