



























import { Component, Prop, Mixins } from 'vue-property-decorator';
import { Utils } from '@/mixins';

@Component
export default class ProfileRecent extends Mixins(Utils) {
  @Prop()
  public title!: string;

  @Prop()
  public to!: any;
}
