



















































































import s from "./select-plate";
export default s;
