








































import s from './my-company';
export default s;
