



























import { Component, Mixins } from "vue-property-decorator";
import RecordingList from "@/components/lists/RecordingList.vue";
import CallTable from "@/components/lists/CallTable.vue";
import { Recording, Call, CallRecording, NumberAliasMap } from "../../../../api";
import { Utils, Events } from "@/mixins";

type MetaCall = { id: string; co_number: string; outbound: boolean } & Call;

@Component({
  components: {
    //  LinkPlate,
    //  UserPlate,
    RecordingList,
    CallTable
  }
})
export default class VoicemailIndex extends Mixins(Events) {
  public processing = true;
  public _recordings: Recording[] = [];
  public numbers: string[] = [];
  public number_alias: NumberAliasMap = {};

  public created() {
    this.refresh();
  }

  public async refresh() {
    const s = this;
    const [recordings, numbers, number_alias] = await Promise.all([
      this.api.recordings(),
      this.api.myNumbers(),
      this.api.getNumberLookups()
    ]);
    this.number_alias = number_alias;
    console.log("my_numbers", numbers);
    this.numbers = numbers;
    this._recordings = recordings.map((r: Recording) => ({
      ...r,
      outbound: s.numbers.indexOf(r.origin) >= 0
    })) as Recording[];
    console.log("recordings", s.recordings);

    this.processing = false;
  }

  get recordings(): Array<MetaCall | string> {
    if (!this._recordings) {
      return [];
    }
    const calls: MetaCall[] = this._recordings.map(e => ({
      id: e.uniqueid ? e.uniqueid + e.start : String(e.start),
      start: e.start,
      end: e.end,
      origin: e.origin,
      destination: e.destination,
      duration: e.duration,
      co_number: e.outbound ? e.destination : e.origin,
      recording:
        e.recording != null
          ? e.recording
          : ({ type: "e", server: "", uniqueid: e.uniqueid } as CallRecording),
      roaming: undefined,
      outbound: e.outbound ? e.outbound : false
    }));
    const ret = calls.reduce((acc: Array<MetaCall | string>, e: MetaCall) => {
      if (acc.length) {
        const last = acc[acc.length - 1];
        if (typeof last !== "string") {
          const ndate = this.formatDateFromUnix(e.start);
          if (ndate !== this.formatDateFromUnix(last.start)) {
            acc.push(ndate);
          }
        }
      } else {
        acc.push(this.formatDateFromUnix(e.start));
      }
      acc.push(e);
      return acc;
    }, []);
    return ret;
  }
}
