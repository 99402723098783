<template>
  <div class="[ o-gutter-bottom-md o-gutter-bottom-lg@lg ]">
    <RefLine v-if="userRef" class="u-push-bottom-sm" :userRef="userRef" />

    <div class="[ u-bleed-sides-sm u-bleed-sides-md@xl ]" style="padding-bottom: 10px;">
      <hr class="u-push-bottom-clear">
    </div>

    <div class="o-grid [ o-gutter-left-2xl o-gutter-bottom-md ]">
      <div class="o-grid__item o-gutter__item u-width-1/2@lg">
        <div
          class="[ c-border-list c-border-list--bottom c-border-list--beta ] [ c-ui-links c-ui-links--omicron-brand ]"
        >
          <LinkPlate
            :icon="'calendar'"
            :icon_height="'30px'"
            :icon_width="'30px'"
            :text="$t('Add reference')"
            size="xs"
            @primary="$router.push({ name: 'select-reference' })"
          />
          <LinkPlate
            v-for="ref in sorted_references"
            :key="ref.id"
            :icon="ref.reference.icon"
            :icon_height="'30px'"
            :icon_width="'30px'"
            :text="ref.subject ? ref.subject : $t(ref.reference.name)"
            :start="`${formatDateFromUnix(ref.start)} ${formatTimeFromUnix(ref.start)}`"
            :end="`${formatDateFromUnix(ref.end)} ${formatTimeFromUnix(ref.end)}`"
            size="sm"
            @primary="$router.push( { name: 'reference-update', params: {id: ref.id } } )"
          />
          <LinkPlate
            v-if="userRefs.length >=5"
            :icon="'calendar'"
            :icon_height="'30px'"
            :icon_width="'30px'"
            :text="$t('Add reference')"
            size="xs"
            @primary="$router.push({ name: 'select-reference' })"
          />
        </div>
      </div>
    </div>
    <div>{{reloading}}</div>
  </div>


</template>
<script>
import s from "./list-references";
export default s;
</script>

<style scoped>
</style>