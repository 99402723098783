import { Component, Mixins } from 'vue-property-decorator';
import { SummaryItem, Events } from '@/mixins';
import moment from 'moment';
import LinkPlate from '@/components/LinkPlate.vue';
import EventPlate from '@/components/plates/EventPlate.vue';
import { CallHistorySummary } from '../../../../api';
import _ from 'lodash';

@Component({
  components: {
    LinkPlate,
    EventPlate,
  },
})
export default class EventHistoryTop extends Mixins(Events) {

  private processing = true;

  private chs: CallHistorySummary | null = null;

  public created() {
    this.refresh().then(ch => {
      if (ch.count > ch.limit && ch.limit < 5000) {
        this.refresh(ch.count > 5000 ? 5000 : ch.count);
      }
    });
  }

  public async refresh(limit: number = 100) {
    this.processing = true;
    const ch = await this.api.callHistorySummary({ limit });
    this.chs = ch;
    this.processing = false;
    return ch;
  }

  public chooseDay(month: MonthItem) {
    this.$router.push({
      name: 'event-history-day',
      params: {
        year: month.year,
        month: month.month,
      },
    });
  }

  get years() {
    const result: YearMonths = {};

    if (this.chs) {
      this.chs.items.forEach(item => {
        const date = moment(item.date);

        const name = _.capitalize(date.locale(this.$i18n.locale).format('MMMM'));
        const year = date.format('YYYY');
        const month = date.format('MM');

        const calls = Number(item.units_voice);
        const messages = Number(item.units);
        const data = Number(item.units_data);

        const existing = result[year] || [];
        const prev = existing.find(value => value.month === month);
        if (!prev) {
          const next: MonthItem = {
            name,
            year,
            month,
            calls,
            messages,
            data,
          };
          existing.push(next);
        } else {
          // Accumulate call minutes and number of messages
          prev.calls += calls;
          prev.messages += messages;
          prev.data += data;
        }

        result[year] = existing;
      });

      Object.keys(result).forEach(year => {
        const months = result[year];
        result[year] = _.orderBy(months, 'month', ['desc']);
      });
    }

    return _.orderBy(Object.keys(result).map(year => ({ year, months: result[year] })), 'year', ['desc']);
  }

  get empty() {
    return !this.chs || this.chs.items.length === 0;
  }
}

export interface YearMonths {
  [year: string]: MonthItem[];
}

export interface MonthItem extends SummaryItem {
  name: string;
  year: string;
  month: string;
}
