import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class AudioPlate extends Vue {
  @Prop()
  public file!: string;

  public processing: boolean = true;

  public a: HTMLAudioElement | null = null;

  public duration: number = 1;
  public fraction: number = 0;
  public playing: boolean = false;
  public canplay: boolean = false;

  public created() {
    const audio = document.createElement('audio');
    audio.src = this.file;

    audio.autoplay = false;

    audio.addEventListener('loadedmetadata', ev => {

      const a = ev.target as HTMLAudioElement;
      this.duration = a.duration;
      this.fraction = a.currentTime / a.duration;
    });

    const setFraction = () => {
      if (this.a) {
        this.fraction = this.a.currentTime / this.a.duration;
        requestAnimationFrame(setFraction);
      }
    };

    const onCanPlayThrough = (ev: Event) => {
      this.processing = false;
      const a = ev.target as HTMLAudioElement;
      this.a = a;
      this.startPlayback();
      audio.removeEventListener('canplaythrough', onCanPlayThrough);
      setFraction();
    };
    audio.addEventListener('canplaythrough', onCanPlayThrough);
    audio.addEventListener('canplay', () => this.canplay = true);

    audio.addEventListener('ended', ev => {
      this.playing = false;
      this.fraction = 0;
      audio.currentTime = 0;
    });

    this.a = audio;
  }

  public toTime(duration: number) {
    const m = Math.floor(duration / 60);
    const s = Math.floor(duration % 60);
    return `${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
  }

  public stopPlayback() {
    this.a!.currentTime = 0;
    this.a!.pause();
    this.fraction = 0;
    this.playing = false;
  }

  public pausePlayback() {
    this.a!.pause();
    this.playing = false;
  }

  public startPlayback() {
    this.a!.play();
    this.playing = true;
  }

  public handleClick(ev: MouseEvent) {
    const { clientX, pageX, screenX, x } = ev;
    const t = this.$refs.clicktarget as HTMLElement;
    const { left, right, width } = t.getBoundingClientRect();

    const [client, page, screen, z] = [clientX, pageX, screenX, x].map(n => {
      return (n - left) / width;
    });

    this.fraction = client;
    this.a!.currentTime = this.duration * this.fraction;
  }

  // get fraction() {
  //   const totalLength = this.audio.duration;
  //   return totalLength !== 0 ? 0 : this.audio.currentTime / totalLength;
  // }

  get barWidth() {
    return `width: ${this.fraction * 100}%;`;
  }

  get maxTime() {
    return this.toTime(this.duration);
  }

  get currentTime() {
    const duration = this.duration;
    const current = duration * this.fraction;
    return this.toTime(current);
  }

  public beforeDestroy() {
    if (this.a) {
      this.stopPlayback();
    }
  }
}
