import { Component, Mixins, Prop } from "vue-property-decorator";
import { Utils } from "@/mixins";
import { Call, PERMISSIONS, checkPermissions, NumberAliasMap, Relative } from "../../../../api";
import { parsePhoneNumber, parse, PhoneNumber } from 'libphonenumber-js';
import Roaming from "@/components/event-history/Roaming.vue";

@Component({
  components: {
    Roaming
  }
})
export default class CallTable extends Mixins(Utils) {
  @Prop()
  public numbers!: string[];

  @Prop()
  public calls!: Array<Relative<Call>>;

  @Prop()
  public show_date?: boolean;

  @Prop({ default: {} })
  public number_alias?: NumberAliasMap;

  public otherNumber(call: Relative<Call>) {
    if (this.numbers.indexOf(call.origin) === -1) {
      return call.origin;
    } else {
      return call.destination;
    }
  }

  public goToRecording(call: Relative<Call> | string) {
    if (
      typeof call === "string" ||
      !this.has_recording(call) ||
      call.recording == null
    ) {
      return;
    }

    this.$router.push({
      name: "recording-listen",
      params: {
        uniqueid: call.recording.uniqueid
      }
    });
  }

  public has_recording(call: Relative<Call> | string): boolean {
    return (
      checkPermissions(
        this.$store.getters.perms || [],
        PERMISSIONS.USER.RECORDINGS.READ
      ) &&
      typeof call !== "string" &&
      (call as Call).recording != null
    );
  }

  public number_name(call: Relative<Call>) {
    try {
      const num_data = parsePhoneNumber(call.co_number, "SE");
      if(num_data.isValid()) {
        const nstr = num_data.number as string;
        if(this.number_alias && this.number_alias[nstr]) {
          return this.number_alias[nstr];
        }
        if(nstr.substr(0,3) === "+46") {
          return num_data.formatNational();
        }
        return nstr;
      }
      return this.$t('Protected number').toString();
    } catch(err) {
      return this.formatNumber(call.co_number);
    }
  }

  public number_ref(call: Relative<Call>) {
    try {
      const num_data = parsePhoneNumber(call.co_number, "SE");
      if(num_data.isValid()) {
        return num_data.number as string;
      }
      return "";
    } catch(err) {
      return "";
    }
  }
}
