import { Component, Vue, Mixins, Watch } from 'vue-property-decorator';
import { Reference, ReferenceStatus, MyNumbers, Event } from '../../../../api';
import GenericPlate from '@/components/GenericPlate.vue';
import QueuePlate from '@/components/QueuePlate.vue';

import CheckPlate from '@/components/plates/CheckPlate.vue';
import RefLine from '@/components/references/RefLine.vue';
import { Utils } from '@/mixins';
import moment from 'moment';
import FlatPickr from 'vue-flatpickr-component';
import { Swedish as swedish } from 'flatpickr/dist/l10n/sv';
import { english } from 'flatpickr/dist/l10n/default';
import { roundNearest, FloorNearest } from '@/utils';

@Component({
    components: {
      GenericPlate,
      QueuePlate,
      CheckPlate,
      FlatPickr,
      RefLine,
    },
})
export default class CommitReference extends Mixins(Utils) {
  public errors: string[] = [];
  private reference: Reference | null = null;
  private refStatus: ReferenceStatus | null = null;
  private numbers: { [key: string]: boolean } | null = null;
  private subject: string = '';
  private description: string = '';

  private endtime: Date = new Date();
  private starttime: Date = new Date();
  private now = new Date();

  private processing: boolean = false;

  private timeLeft = 4;
  private sendBack?: NodeJS.Timeout;

  private graphReady = false;
  private graphProcessing = false;
  private graphCollisions: Event[] = [];

  public created() {
    this.endtime.setHours(this.endtime.getHours() + 1);
    this.endtime.setMinutes(roundNearest(this.endtime.getMinutes(), 15));

    this.starttime.setMinutes(FloorNearest(this.starttime.getMinutes(), 15));

    const id = this.$route.params.id;

    this.api.referenceById(Number(id)).then(reference => {
      this.reference = reference;
    });

    this.api.myNumbers().then(numbers => {
        const active: { [key: string]: boolean } = {};
        numbers.forEach(phoneNumber => {
            active[phoneNumber] = true;
        });
        this.numbers = active;
    });
  }

  public destroyed() {
    // Cancel scheduled redirect if we leave
    if (this.sendBack) {
      clearTimeout(this.sendBack);
    }
  }

  public handleSubmit(evt: Event) {
    this.processing = true;
    if(typeof this.starttime === 'string') {
      this.starttime = new Date(this.starttime);
    }
    if(typeof this.endtime === 'string') {
      this.endtime = new Date(this.endtime);
    }

    console.log("handlesubmit times", this.starttime, this.endtime);

    if (!this.reference) {
      throw new Error(`Cannot make user ref with reference ${this.reference}`);
    }
    if (!this.numbers) {
      throw new Error(`Cannot make reference with no numbers!`);
    }

    const selectedNumbers: string[] = [];
    Object.keys(this.numbers).forEach(phoneNumber => {
      if (this.numbers![phoneNumber]) {
        selectedNumbers.push(phoneNumber);
      }
    });

    const end = this.endtime.getTime() / 1000;
    const start = this.starttime.getTime() / 1000;

    this.api.makeUserRef(this.reference, selectedNumbers, end, start, this.subject, this.description)
        .then(res => {
            this.refStatus = res;

            this.processing = false;
            this.scheduleSendBack();
    });
  }

  public scheduleSendBack() {
    const handler = () => {
      this.timeLeft--;

      if (this.timeLeft === 0) {
        this.$router.push({ name: 'list-reference' });
      }
      this.sendBack = setTimeout(handler, 1000);
    };
    handler();
  }

  public toggleNumber(phoneNumber: string) {
      const active = this.numbers![phoneNumber];
      this.numbers![phoneNumber] = !active;
  }

  public setDatetime(type: 'start' | 'end') {
    const isStart = type === 'start';

    const options = {
      date: isStart ? this.starttime : this.endtime,
      mode: 'datetime',
      okText: this.$t('Done'),
      cancelText: this.$t('Cancel'),
      todayText: this.$t('Today'),
      nowText: isStart ? this.$t('Now') : '', // Empty for no current time
      is24Hour: true,
      allowOldDates: false,
      doneButtonLabel: this.$t('Done'),
      cancelButtonLabel: this.$t('Cancel'),
      minuteInterval: 15,
      locale: this.$i18n.locale === 'en' ? 'en_us' : this.$i18n.locale === 'sv' ? 'sv_se' : 'en_us',
    };

    const onSuccess = (date: Date) => {
      if (date) {
        if (isStart) {
          this.starttime = date;
        } else {
          this.endtime = date;
        }
      }
    };

    const onError = (err: any) => {
      console.error(err);
    };

    // Launch native datepicker
    (window as any).plugins.datePicker.show(options, onSuccess, onError);
  }

  public async graphCheckCollisions() {
    this.graphProcessing = true;

    let starttime: string | Date = this.starttime;
    if (typeof starttime === 'string') {
      starttime = new Date(starttime);
      console.log("starttime = ", starttime);
    }

    let endtime: string | Date = this.endtime;
    if (typeof endtime === 'string') {
      endtime = new Date(endtime);
      console.log("endtime = ", endtime);
    }

    const start = starttime.getTime() / 1000;
    const end = endtime.getTime() / 1000;
    const events = await this.api.graphCheck(end, start);
    console.log(`this.api.graphCheck(${end}, ${start})`, events);

    this.graphCollisions = events;

    this.graphProcessing = false;
  }

  public handleGraphReady() {
    this.graphReady = true;
    this.graphCheckCollisions();
  }

  public prettyEventDate(date: number) {
    return moment.unix(date).locale(this.$i18n.locale).format('LLL');
  }

  public browserEndChange(selectedDates: any, dateStr: any, instance: any) {
    // console.log('End');
    // console.log(selectedDates);
    // console.log(dateStr);
    // console.log(instance);

    // this.endtime = selectedDates[0];
  }


  public browserStartChange(selectedDates: any, dateStr: any, instance: any) {
    // console.log('Start');
    // console.log(selectedDates);
    // console.log(dateStr);
    // console.log(instance);

    // this.starttime = selectedDates[0];
  }

  @Watch('starttime')
  public onStartTimeChange(value: string | Date) {
    this.graphCheckCollisions();
    this.checkDateRange();
  }

  @Watch('endtime')
  public onEndTimeChange(value: string | Date) {
    this.graphCheckCollisions();
    this.checkDateRange();
  }

  public dateRangeValid() {
    let start = this.starttime;
    if(typeof start === 'string') {
      start = new Date(this.starttime);
    }
    let end = this.endtime;
    if(typeof end === 'string') {
      end = new Date(this.endtime);
    }
    console.log("endtime watch starttime", start);
    console.log("endtime watch endtime", end);
    return start < end;

  }


  public checkDateRange() {    
    if(this.dateRangeValid()) {
      this.errors = [];
    } else {
      this.errors = ['Start time must be before end time'];
    }
  }

  get submitDisabled() {
    if (this.processing) {
      return true;
    }
    if (!this.numbers) {
      return true;
    }
    if (this.refStatus) {
      return true;
    }
    if( !this.dateRangeValid()) {
      return true;
    }
    return Object.keys(this.numbers).every(phoneNumber => {
      return !this.numbers![phoneNumber];
    });
  }

  get prettyEndDate() {
    return moment(this.endtime).format('YYYY-MM-DD HH:mm');
  }

  get prettyStartDate() {
    return moment(this.starttime).format('YYYY-MM-DD HH:mm');
  }

  get refEnd() {
    if (!this.refStatus) {
      return 'Reference status not loaded!';
    }

    return moment.unix(this.refStatus.end).locale(this.$i18n.locale).format('llll');
  }

  get flatConfig() {
    const locale = this.$i18n.locale === 'sv' ? swedish : english;
    return {
      enableTime: true,
      dateFormat: 'Y-m-d H:i',
      time_24hr: true,
      minuteIncrement: 15,
      weekNumbers: true,
      locale,
    };
  }
}
