export const Globals = {    
  name: 'Allo Telecom APP',
  description: 'Allo Telecom self-service-application',
  bundle: 'se.allotelecom.app',
  login: {
    bankid: 371,
    password: true  
  },
  urls: {
    help: "https://www.allotelecom.se",
  },
  colors: {
    brand: '#1C44A6',
    alpha: '#F7F8FB',
    beta: '#ebebeb',
    gamma: '#212231',
    delta: '#222222',
    epsilon: '#f8f8f8',
    zeta: '#ffffff',
    eta: '#d7d7d7',
    theta: '#9b9b9b',
    iota: '#6dc98c',
    kappa: '#345736',
    lambda: '#ff3a30',
    mu: '#fc8b8b',
    nu: '#e5a051',
    xi: '#fbd343',
    omicron: '#606060',
    incoming: '#E31C54',
    outgoing: '#0C9D29',
  }
  
};
