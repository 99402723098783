












































































import { Component, Mixins, Model, Prop } from 'vue-property-decorator';
import { Utils } from '@/mixins';

@Component
export default class SubscriptionPlate extends Mixins(Utils) {
  @Prop()
  public name!: string;

  @Prop()
  public phonenumber!: string;

  @Prop()
  public img!: any;

  @Prop()
  public checked!: boolean;

  @Prop()
  public hideCheckbox!: boolean;
}
