<template>
  <div class="[ o-gutter-bottom-md o-gutter-bottom-lg@lg ]">
    <div class="o-gutter__item [ u-soft-bottom-sm u-soft-bottom-md@lg ]">
      <h1 class="u-push-bottom-clear">{{ prettyTitle }}</h1>
    </div>
    <div class="o-gutter__item" v-if="processing">
      <div>{{ $t('Loading...') }}</div>
    </div>
    <div class="o-gutter__item" v-else-if="templates.length == 0">
      {{$t("No signup templates configured for your customer.")}}
    </div>
    <div class="o-gutter__item" v-else>
      <table class="u-width-1/1 u-text-omicron u-text-md">
        <tbody>
          <tr>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm"><h3>{{$t("Subscription name")}}:</h3></td>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm">
              <input type="text" v-model="subscription_name"/>
            </td>
          </tr>
          <tr>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm"><h3>{{$t("Start date")}}:</h3></td>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm">
              <FlatPickr v-if="isBrowser"
                  v-model="start_date"
                  class="[ c-text-input__input c-text-input__input--border ]"
                  :config="flatConfig"></FlatPickr>
              <div v-else @click="setDatetime('end')"
                  class="[ c-text-input__input c-text-input__input--border ]"
              > {{ prettyEndDate }} </div>
            </td>
          </tr>
          <tr>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm"><h3>{{$t("Select template")}}:</h3></td>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm">
              <select v-model="selected_template">
                <option v-for="temp in templates" :key="temp.id" v-bind:value="temp">{{temp.name}}</option>
              </select>
            </td>
          </tr>
          <tr v-if="selected_template && selected_template.system_is_mobile">
            <td class="u-width-1/1 u-border-top u-soft-ends-sm" colspan="2">
              <table class="u-width-1/1 u-text-sm">
                <tr>
                    <td class="u-width-1/4" v-bind:style="{ visibility: sim_offset > 0 ? '' : 'hidden', 'text-align':'center' }">
                      <button class="c-btn c-btn--sm btn-xs" @click="prev_sims()">{{$t("Previous")}}</button>
                    </td>
                    <td class="u-width-1/2" style="text-align:center;"><h3>{{$t("Select SIM")}}</h3></td>
                    <td class="u-width-1/4" v-bind:style="{ visibility: sim_offset+sim_limit < sim_total ? '' : 'hidden', 'text-align':'center' }" >
                      <button class="c-btn c-btn--sm btn-xs" @click="next_sims()" >{{$t("Next")}}</button>
                    </td>
                  </tr>
              </table>
            </td>
          </tr>
          <tr v-if="selected_template && selected_template.system_is_mobile">
            <td class="u-width-1/1 u-soft-ends-sm" colspan="2">
              <div v-if="sims_processing">{{$t("Loading...")}}</div>
              <table v-else class="u-width-1/1 u-text-sm" >
                <thead>
                  <th>{{$t('ICC')}}</th>
                  <th>{{$t('IMSI')}}</th>
                  <th>{{$t('Select')}}</th>
                </thead>
                <tbody>
                  <tr v-for="sim in sims" :key="sim.id"
                      v-bind:class="{ 'u-text-brand' : selected_sim && selected_sim.id == sim.id }"
                      v-bind:style="{ 'font-weight' : selected_sim && selected_sim.id == sim.id ? 'bold' : 'normal'}"
                      style="font-size:0.8rem !important;"
                  >
                    <td>{{sim.icc}}</td>
                    <td>{{sim.imsi}}</td>
                    <td>
                      <button class="c-btn c-btn--sm btn-xs" @click="select_sim(sim)">{{$t("Select")}}</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr v-if="selected_template && (!selected_sim || selected_sim.last_msisdn == '')">
            <td class="u-width-1/2 u-border-top u-soft-ends-sm"><h3>{{$t("Select number")}}:</h3></td>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm">
              <select v-model="selected_number">
                <option v-for="num in numbers" :key="num.id" v-bind:value="num">{{num.number}}</option>
              </select>
            </td>
          </tr>
          <tr v-else-if="selected_template">
            <td class="u-width-1/2 u-border-top u-soft-ends-sm"><h3>{{$t("Prebound number")}}</h3></td>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm">{{selected_sim.last_msisdn}}</td>
          </tr>


          <tr v-if="selected_template">
            <td colspan="2" class="u-border-top u-soft-ends-sm">
              <h3>{{$t("Subscription details")}}</h3>
              <table class="u-width-1/1 u-text-sm">
                <tr>
                  <td class="u-width-1/2"><h4>{{$t("With vat")}}</h4></td>
                  <td class="u-width-1/2">
                    <fieldset class="u-text-clear u-border-clear u-push-bottom-clear">
                        <label class="[ c-styled-checkbox c-styled-checkbox--left-top ] u-inline-block">
                            <input type="checkbox"
                                name=""
                                class="c-styled-checkbox__input"
                                :checked="inc_vat"
                                @click="inc_vat = !inc_vat"
                                required>
                            <div class="c-styled-checkbox__container">
                                <div class="c-styled-checkbox__square">
                                    &nbsp;
                                    <figure class="c-styled-checkbox__figure u-push-bottom-clear">
                                        <svg class="[ u-position-absolute u-position-center-center ] u-width-1/1 u-height-1/1">
                                            <use xlink:href="#icon-check"></use>
                                        </svg>
                                    </figure>
                                </div>
                                <div class="c-styled-checkbox__content u-soft-left-clear u-width-clear">
                                    &nbsp;
                                </div>
                            </div>
                        </label>
                    </fieldset>
                  </td>
                </tr>
                <tr v-if="selected_template.cost_setup">
                  <td class="u-width-1/2 "><h4>{{$t("Setup cost")}}</h4></td>
                  <td class="u-width-1/2 " v-if="inc_vat">
                    {{selected_template.cost_setup}} {{selected_template.currency}}
                  </td>
                  <td class="u-width-1/2 " v-else>
                    {{selected_template.cost_setup_ex_vat}} {{selected_template.currency}}
                  </td>
                </tr>
                <tr>
                  <td class="u-width-1/2 "><h4>{{$t("Minimum monthly cost")}}</h4></td>
                  <td class="u-width-1/2 " v-if="inc_vat">
                    {{selected_template.cost_monthly_minimum}} {{selected_template.currency}}
                  </td>
                  <td class="u-width-1/2 " v-else>
                    {{selected_template.cost_monthly_minimum_ex_vat}} {{selected_template.currency}}
                  </td>
                </tr>
                <tr v-if="selected_template.call_credit">
                  <td class="u-width-1/2 "><h4>{{$t("Included call credit")}}</h4></td>
                  <td class="u-width-1/2 " v-if="inc_vat">
                    {{selected_template.call_credit}} {{selected_template.currency}}
                  </td>
                  <td class="u-width-1/2 " v-else>
                    {{selected_template.call_credit_ex_vat}} {{selected_template.currency}}
                  </td>
                </tr>
                <tr v-if="selected_template.included.voice">
                  <td class="u-width-1/2 "><h4>{{$t("Included calls")}}</h4></td>
                  <td class="u-width-1/2 ">
                    {{selected_template.included.voice}} {{$t("minutes")}}
                  </td>
                </tr>
                <tr v-if="selected_template.included.data">
                  <td class="u-width-1/2 "><h4>{{$t("Included data")}}</h4></td>
                  <td class="u-width-1/2 ">
                    {{selected_template.included.data}} {{$t("MB")}}
                  </td>
                </tr>
                <tr v-if="selected_template.included.sms">
                  <td class="u-width-1/2 "><h4>{{$t("Included SMS")}}</h4></td>
                  <td class="u-width-1/2 ">
                    {{selected_template.included.sms}}
                  </td>
                </tr>
                <tr v-if="selected_template.included.mms">
                  <td class="u-width-1/2 "><h4>{{$t("Included MMS")}}</h4></td>
                  <td class="u-width-1/2 ">
                    {{selected_template.included.mms}}
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr v-if="validate">
            <td colspan="2">
              <button
                class="c-btn c-btn--sm"
                style="font-size:1.2rem;"
                @click="create_subscription"
              >{{$t("Create")}}</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="will_create_with">
        Will create subscription with: <pre>{{will_create_with}}</pre>
      </div>  
    </div>   
  </div>
</template>

<style scoped>
.btn-xs {
  font-size: 0.9rem !important;
}
</style>

<script>
import s from "./self_admin_subscription_create";
export default s;
</script>