<template>
  <div class="[ o-gutter-bottom-md o-gutter-bottom-lg@lg ]">
    <div class="o-gutter__item [ u-soft-bottom-sm u-soft-bottom-md@lg ]">
      <h1 class="u-push-bottom-clear">{{ $t(type_name(contact)) +' '+ $t("Contact") }}</h1>
    </div>
    <div class="o-gutter__item">
      <div v-if="processing">{{ $t('Loading...') }}</div>
      <table v-else class="u-width-1/1 u-text-omicron u-text-md">
        <tbody>
          <tr v-if="editable(contact)">
            <td class="u-width-1/2 u-soft-ends-sm"><b>{{$t("First Name")}}:</b></td>
            <td class="u-width-1/2 u-soft-ends-sm">
              {{contact.first_name}}
            </td>
          </tr>
          <tr v-if="editable(contact)">
            <td class="u-width-1/2 u-soft-ends-sm"><b>{{$t("Last Name")}}:</b></td>
            <td class="u-width-1/2 u-soft-ends-sm">
              {{contact.last_name}}
            </td>
          </tr>
          <tr v-if="!editable(contact)"> 
            <td class="u-width-1/2 u-soft-ends-sm"><b>{{$t("Name")}}:</b></td>
            <td class="u-width-1/2 u-soft-ends-sm">
              {{contact.first_name}}
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <table class="u-width-1/1">
                <thead>
                  <th class="u-width-1/2">{{$t("Phones")}}</th>
                  <th class="u-width-1/2">{{$t("Number")}}</th>
                </thead>
                <tbody>
                  <tr v-for="(phone, index) in contact.phones" :key="index">
                    <td>
                      {{phone.description}}
                    </td>
                    <td>
                      <a :href="'tel:'+ phone.number">{{formatNumber(phone.number)}}</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <table class="u-width-1/1">
                <thead>
                  <th class="u-width-1/2">{{$t("Emails")}}</th>
                  <th class="u-width-1/2">{{$t("Address")}}</th>
                </thead>
                <tbody>
                  <tr v-for="(email, index) in contact.emails" :key="index">
                    <td >
                      {{email.description}}
                    </td>
                    <td >
                      <a :href="'mailto:'+ email.address">{{email.address}}</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr v-if="editable(contact)">
            <td>
              <button
                class="c-btn c-btn--sm"
                style="float: right;"
                @click="delete_contact(contact.id)"
              >
                {{$t("Delete contact")}}
              </button>
            </td>
            <td colspan="1">
              <button
                class="c-btn c-btn--sm"
                style="float:right;"
                @click="edit_contact(contact.id)"
              >
                {{$t("Edit contact")}}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import s from "./contact_details";
  export default s;
</script>