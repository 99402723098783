


















































import { Component, Vue, Prop } from 'vue-property-decorator';

export interface SidebarItem {
  text: string;
  icon: string;
  isCurrent: boolean;
  path: string;
  name: string;
}

@Component
export default class Sidebar extends Vue {
  @Prop({ default: () => [] })
  public items!: SidebarItem[];
}
