



























































import { Component, Mixins, Vue } from "vue-property-decorator";
import AudioPlate from "@/components/AudioPlate.vue";
import LinkPlate from "@/components/LinkPlate.vue";
import { Utils } from "@/mixins";
import moment from "moment";

@Component({
  components: {
    AudioPlate,
    LinkPlate
  }
})
export default class RecordingListen extends Mixins(Utils) {
  public processing = true;

  public file = "";

  public number = "";
  public time = "";

  public outbound = false;

  // public confirm = false;

  public created() {
    const uniqueid = this.$route.params.uniqueid;

    Promise.all([this.api.recording(uniqueid), this.api.myNumbers()]).then(
      ([recording, numbers]) => {
        this.processing = false;

        const prettyStart = moment
          .unix(recording.start)
          .locale(this.$i18n.locale)
          .format("LLL");

        this.file = process.env.VUE_APP_SERVER_BASE_URL + recording.dataurl;

        this.outbound = numbers.indexOf(recording.origin) !== -1;
        this.number = this.formatNumber(
          this.outbound ? recording.destination : recording.origin
        );
        this.time = prettyStart;
      }
    );
  }

  // public async handleDelete() {
  //   const filename = this.$route.params.name;

  //   console.log('Deleting', filename);
  //   await this.api.deleteVoicemail(filename);
  //   console.log('Deleted', filename);

  //   this.$router.push({ name: 'voicemail-index' });
  // }

  // public toggleConfirm() {
  //   this.confirm = !this.confirm;
  // }
}
