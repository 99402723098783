import { ROUTES, socket_types, Deferred } from '../../api';

export default { send, register_listener, unregister_listener };

let open_defered = new Deferred<void>();
let closed_deferred = new Deferred<void>();
closed_deferred.resolve();

type Listeners = {
  [key: string]: Array<(msg: socket_types.Message) => void>;
};
const listeners: Listeners = {};

const uuid = require('uuid/v4');
let ws: WebSocket | null = null;
let hearbeat_id: NodeJS.Timeout | null = null;
let client_id: string | '' = '';

export function await_open(): Promise<void> {
    return open_defered.promise;
}

export function await_closed(): Promise<void> {
  return closed_deferred.promise;
}

export async function send(addr: string, payload: socket_types.Payload): Promise<socket_types.Message> {
    return new Promise<socket_types.Message>((resolve, reject) => {
        await_open().then(() => {
            if (ws && ws.readyState === ws.OPEN) {
                const call_back_addr = uuid();
                const cb = (mes: socket_types.Message): void => {
                    if (socket_types.is_error(mes.data)) {
                        reject(mes);
                    } else {
                        resolve(mes);
                    }
                    unregister_listener(call_back_addr, cb);
                };
                register_listener(call_back_addr, cb);
                const message: socket_types.Message = {
                    header: { addr, ts: Date.now(), reply_addr: call_back_addr, sender: client_id },
                    data: payload
                };
                console.debug("sending", message);
                ws.send(JSON.stringify(message));
                // TODO figure out how to handle errors so we dont leave dead listeners
            } else {
                reject("weboscket is not open");
            }
        }).catch(err => {
            reject(err);
        });
    });
}

export function register_listener(addr: string, callback: (msg: socket_types.Message) => void): void {
    if (listeners[addr] === undefined) {
        listeners[addr] = [];
    }
    listeners[addr].push(callback);
}

export function unregister_listener(addr: string, callback: (msg: socket_types.Message) => void): void {
    if (listeners[addr]) {
        const idx = listeners[addr].indexOf(callback);
        if (idx >= 0) {
            listeners[addr].splice(idx, 1);
        }
    }
}






function on_open(): void {
    console.log("WebsocketService connected");
    open_defered.resolve();
    closed_deferred = new Deferred<void>();
    heartbeat();
    hearbeat_id = setInterval(heartbeat, 5000);
}

function on_close(event: CloseEvent): void {
    console.log(`WebsocketService closed code:${event.code}, reason:${event.reason}`);
    clearInterval(hearbeat_id as NodeJS.Timeout);
    open_defered = new Deferred<void>();
    closed_deferred.resolve();
    hearbeat_id = null;
    ws = null;
    if(client_id) {
      connect(client_id);
    }
}

function on_error(event: Event): void {
    console.error(`WebsocketService got error `, event);
    open_defered.reject(event);
    // TODO check if on_close gets automatically called here then this is not needed
    closed_deferred.resolve();
    open_defered = new Deferred<void>();
    clearInterval(hearbeat_id as NodeJS.Timeout);
    hearbeat_id = null;
}

function on_message(message: MessageEvent): void {
    console.debug("on_message", message.data);
    const m = JSON.parse(message.data);
    if (socket_types.is_message(m)) {
        if (socket_types.is_error(m.data)) {
            console.error(`WEBSOCKET ERROR: ${m.data.message}`, m);
        }
        console.debug("incoming message: ", socket_types.filter_message(m));
        handle_listeners(m);
    }
}

function heartbeat(): void {    
    const send_time = Date.now();
    send("ping", { type: "ping" }).then((m: socket_types.Message) => {
        const now = Date.now();
        if (socket_types.is_message(m) && socket_types.is_pong(m.data)) {
            console.debug(`WebsocketService pingpong roundtrip time: ${now - send_time} ms`);
            console.debug(`WebsocketService pingpong server to client time: ${now - m.header.ts} ms`);
        } else {
            console.error("expected pong got: ", m);
        }
    }).catch(e => {
        console.error("send ping gave error", e);
    });
}

export async function connect(token: string | null): Promise<void> {
    await disconnect();
    client_id = token ? token : '';
    // const s = `ws://${window.location.hostname}:3000${ROUTES.MAIN_WS_SERVER}?socket_id=${client_id}`;
    const s = `wss://${client_id}:x@${process.env.VUE_APP_SERVER_BASE_DOMAIN}${ROUTES.MAIN_WS_SERVER}`;
    console.log("ws connection",s);
    ws = new WebSocket(s, [client_id]);
    ws.onopen = on_open;
    ws.onclose = on_close;
    ws.onerror = on_error;
    ws.onmessage = on_message;
    return await_open();
}

export async function disconnect(): Promise<void> {
  client_id = '';
  if(ws) {
    ws.close();
  }
  return await_closed();
}

function handle_listeners(m: socket_types.Message): boolean {
    for (const key in listeners) {
        if (key === m.header.addr) {
            for (const func of listeners[key]) {
                func(m);
            }
            return true;
        }
    }
    return false;
}

