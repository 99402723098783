import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ROUTES, API_PREFIX } from './src/constants';
import { GRAPH_ROUTES } from './src/graph/constants';
import {
  MakeUserRefPostBody,
  Consumption,
  Voicemail,
  CallHistoryPostBody,
  CallHistory,
  CallHistorySummary,
  Invoice,
  Recording,
  UserPresence,
  UserPresencePostBody,
  Sim,
  UserProfile,
  Recents,
  Queue,
  QueueAvailablePostBody,
  QueueMembershipPostBody,
  ReferenceStatus,
  EventHistory,
  Reference,
  VoicemailData,
  RecordingData,
  Product,
  SubscriptionSummary,
  AppUser,
  Chats,
  Info,
  Subscription,
  PermissionGroup,
  NamedNumber,
  Contact,
  ContactEmail,
  ContactPhone,
  Theme,
  SignupTemplate,
  SignupSim,
  SignupNumber,
  SelfAdminSubscription,
  SubscriptionBinding,
  NumberAliasMap
} from './src/resources';
import { GraphRegisterResponse, Event, PswResetReply } from './src/graph/interfaces';
import { Identifiable, Version, Paginated } from './src/common';
import { Option } from 'tsoption';

export * from './src/common';

export * from './src/resources';
export * from './src/constants';

export * from './src/permissions/index';

export * from './src/graph/interfaces';
export * from './src/graph/constants';

export * as socket_types from './src/socket_types';
export {SOCKET_ROUTES} from './src/socket_routes';

export class Deferred<T> {
  public promise: Promise<T>;
  public resolve!: (value: T | PromiseLike<T>) => void;
  public reject!: (reason: any) => void;
  public then: <TResult1 = T, TResult2 = never>(
    onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | null | undefined,
    onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | null | undefined
  ) => Promise<TResult1 | TResult2>;
  public catch: <TResult = never>(
    onrejected?: ((reason: any) => TResult
    | PromiseLike<TResult>)
    | null
    | undefined
  ) => Promise<T | TResult>;
  constructor() {
    const p = this.promise = new Promise<T>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    this.then = this.promise.then.bind(p);
    this.catch = this.promise.catch.bind(p);
  }
}


/**
 * The EasyApp Server API.
 */
export class EasyAppAPI {
  public readonly baseUrl: string;

  public readonly api: AxiosInstance;

  // Auth

  /** Creates a new API. */
  constructor(baseUrl: string, token?: string) {
    this.baseUrl = baseUrl + API_PREFIX;

    let config: AxiosRequestConfig = {
      baseURL: this.baseUrl,
    };
    if (token) {
      config = {
        ...config,
        headers: {
          Authorization: `Bearer ${token}`
        },
      };
    }
    this.api = axios.create(config);
  }

  public version() {
    return this.get<Version>(ROUTES.VERSION);
  }

  /** Requests an access token. Sets the access token for subsequent requests. */
  public async login(username: string, password: string): Promise<{session: string}> {
    // const res = await this.api.post<LoginResponse>(ROUTES.LOGIN, {
    //   username,
    //   password,
    // });

    const auth = this.api.defaults.headers.common.Authorization;
    delete this.api.defaults.headers.common.Authorization;

    console.log('Deleting previous Auth', auth);

    const res = await this.api.post('/login', {
      username,
      password,
    });
    const res2 = res as any;
    const token = res2?.data?.session ? res2.data.session as string : "";
    console.log('Instating new Auth', token);
    this.api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return {session: token};
  }

  /** Requests an access token. Sets the access token for subsequent requests. */
  public async bankidLogin(system: number): Promise<{id: string, qr: () => Promise<string|null>, appToken: string, cancel: () => void, loginInit: Promise<void>, loginDone: Promise<{session:string}>}> {
    type BankIDInitResp = {
      id: string,
      appToken: string,
      qr: string
    };

    type BankIDStatusResp = {
      status: "failed"|"pending"|"complete",
      hint: string,
      session: null|string
    }

    // Remove old Auth
    delete this.api.defaults.headers.common.Authorization;

    const res = await this.api.post<BankIDInitResp>(`/system/${system}/auth`, {
      type: 'app',
    });

    if (res.status != 200) {
      throw new Error(res.statusText);
    }

    if (!res?.data?.appToken) {
      throw new Error("unable to initialize authentication");
    }

    const sessId = res.data.id;
    let statusTmr: any;
    let qrCnt = 0;
    let loginInit: Deferred<void>|undefined = new Deferred();
    let loginDone: Deferred<{ session: string }>|undefined = new Deferred();

    const cancel = () => {
      if (statusTmr) {
        clearTimeout(statusTmr);
        statusTmr = undefined;
      }
      if (loginInit) {
        loginInit.resolve();
        loginInit = undefined;
      }
      if (loginDone) {
        loginDone.reject('cancel');
        loginDone = undefined;
      }
    }

    const qr = async () => {
      if (!loginInit) {
        return null;
      }
      qrCnt += 1;
      if (qrCnt === 1) {
        return res.data.qr;
      }
      const qrRes = await this.api.get<{ qr: string }>(`/system/${system}/qr/${sessId}?n=${qrCnt}`);
      return qrRes?.data?.qr;
    }

    const checkStatus = async () => {
      if (!loginDone) return;
      const res = await this.api.post<BankIDStatusResp>(`/system/${system}/auth/${sessId}`, {});
      if (!loginDone) return;
      if (res?.data?.status === 'complete') {
        if (loginInit) {
          loginInit.resolve();
          loginInit = undefined;
        }
        if (res.data.session) {
          this.api.defaults.headers.common.Authorization = `Bearer ${res.data.session}`;
          loginDone.resolve({session: res.data.session});
        } else {
          loginDone.reject(res.data.hint);
        }
        return;
      } else if (res?.data?.status === 'failed') {
        if (loginInit) {
          loginInit.resolve();
          loginInit = undefined;
        }
        loginDone.reject(res.data.hint);
        return;
      }

      // Pending or HTTP error
      if (res?.data?.status === 'pending') {
        if (res?.data?.hint === 'userSign' && loginInit) {
          loginInit.resolve();
          loginInit = undefined;
        }
      }

      statusTmr = setTimeout(() => checkStatus(), 1500);
    }
    statusTmr = setTimeout(() => checkStatus(), 1000);

    return {
      id: res.data.id,
      qr,
      appToken: res.data.appToken,
      loginInit: loginInit.promise,
      loginDone: loginDone.promise,
      cancel
    };
  }

  public async pswResetRequestCode(username: string): Promise<PswResetReply> {
    const res = await this.api.post(ROUTES.PSW_RESET_REQUEST_CODE, {
      username
    });
    if (res.status !== 200) {
      return Promise.reject(res);
    }
    const data = res.data as any;
    return {
      success: !! data?.success,
      message: data?.message
    }
  }

  public async pswResetVerifyCode(username: string, pass_code: string, new_password: string): Promise<PswResetReply> {
    const res = await this.api.post(ROUTES.PSW_RESET_VERIFY_CODE, {
      username, pass_code, new_password
    });
    if (res.status !== 200) {
      return Promise.reject(res);
    }
    const data = res.data as any;
    return {
      success: !! data?.success,
      message: data?.message
    }
  }

  public presentationNumbers() {
    return this.get<NamedNumber[]>(ROUTES.PRESENTATION_NUMBERS);
  }

  public addPresentationNumbers(numbers: NamedNumber[]) {
    return this.post<NamedNumber[]>(ROUTES.PRESENTATION_NUMBERS_ADD, { numbers });
  }

  public removePresentationNumbers(numbers: NamedNumber[]) {
    return this.post<NamedNumber[]>(ROUTES.PRESENTATION_NUMBERS_REMOVE, { numbers });
  }

  public contacts() {
    return this.get<Contact[]>(ROUTES.CONTACTS);
  }

  public getContact(id:number) {
    return this.get<Contact>(`${ROUTES.CONTACTS}/${id}`);
  }

  public getForwardingNumbers() {
    return this.get<NumberAliasMap>(`${ROUTES.CONTACTS}/forwarding_numbers`);
  }

  public getNumberLookups() {
    return this.get<NumberAliasMap>(`${ROUTES.CONTACTS}/number_lookups`);
  }

  public deleteContact(id: number) {
    return this.delete(`${ROUTES.CONTACTS}/${id}`);
  }

  public deleteContactPhone(id: number) {
    return this.delete(`${ROUTES.CONTACTS}/phones/${id}`)
  }

  public deleteContactEmail(id: number) {
    return this.delete(`${ROUTES.CONTACTS}/emails/${id}`)
  }

  public storeContact(contact: Contact) {
    return this.post<Contact>(ROUTES.CONTACTS_STORE, { contact });
  }

  /** SelfAdmin methods */
  public selfadmin_list_templates(): Promise<Array<SignupTemplate>> {
    return this.get<Array<SignupTemplate>>(ROUTES.SELF_ADMIN_LIST_TEMPLATES, {});
  }

  public selfadmin_list_sims(
    system_id: number, offset: number = 0, limit: number = 100, query: string = "",
    order_by: string = "imsi", order_desc: boolean = false
  ): Promise<Paginated<SignupSim>> {
    return this.get<Paginated<SignupSim>>(ROUTES.SELF_ADMIN_LIST_SIMS, {
      params:{ system_id, offset, limit, query, order_by, order_desc }
    });
  }

  public selfadmin_list_numbers(
    system_id: number, offset: number = 0, limit: number = 100, query: string = "",
    order_by: string = "number", order_desc: boolean = false
  ): Promise<Paginated<SignupNumber>> {
    return this.get<Paginated<SignupNumber>>(ROUTES.SELF_ADMIN_LIST_NUMBERS, {
      params: { system_id, offset, limit, query, order_by, order_desc }
    });
  }

  public selfadmin_random_numbers( system_id: number, limit: number = 5 ): Promise<Array<SignupNumber>> {
    return this.get<Array<SignupNumber>>(ROUTES.SELF_ADMIN_RANDOM_NUMBERS, {
      params: { system_id, limit }
    });
  }

  public selfadmin_list_subscriptions(
    offset: number = 0, limit: number = 100, children: boolean = false
  ): Promise<Paginated<SelfAdminSubscription>> {
    return this.get<Paginated<SelfAdminSubscription>>(ROUTES.SELF_ADMIN_LIST_SUBSCRIPTIONS, {
      params: { offset, limit, children }
    });
  }

  public selfadmin_get_subscription(subscription_id: number): Promise<SelfAdminSubscription> {
    return this.get<SelfAdminSubscription>( `${ROUTES.SELF_ADMIN_LIST_SUBSCRIPTIONS}/${subscription_id}` );
  }

  public selfadmin_get_subscription_binding(subscription_id: number): Promise<SubscriptionBinding> {
    return this.get<SubscriptionBinding>(`${ROUTES.SELF_ADMIN_SUBSCRIPTION_BINDING}/${subscription_id}`);
  }

  public selfadmin_create_subscription(
    name: string, template_id: number, sim_id: number, number_id: number, start: string|null = null
  ): Promise<SelfAdminSubscription> {
    let data:any = { name, template_id, sim_id, number_id }
    if(start){ data.start = start; }
    return this.post<SelfAdminSubscription>(ROUTES.SELF_ADMIN_CREATE_SUBSCRIPTION, data);
  }

  public selfadmin_cancel_subscription(subscription_id: number, end: string|null = null): Promise<boolean> {
    let data: any = {subscription_id};
    if(end){ data.end = end; }
    return this.post<boolean>( ROUTES.SELF_ADMIN_CANCEL_SUBSCRIPTION, data );
  }

  /** Standard user information. */
  public info() {
    return this.get<Info>(ROUTES.INFO);
  }

  /** List subscriptions under customer */
  public subscriptions() {
    return this.get<SubscriptionSummary[]>(ROUTES.SUBSCRIPTIONS);
  }

  public mySubscriptions(){
    return this.get<Subscription>(`${ROUTES.SUBSCRIPTIONS}/me`);
  }

  /** Get a detail on a specific subscription */
  public subscription(id: number) {
    return this.get<Subscription>(`${ROUTES.SUBSCRIPTIONS}/${id}`);
  }

  /** List user numbers */
  public async myNumbers(): Promise<string[]> {
    return this.get<string[]>(ROUTES.MY_NUMBERS);
  }

  /** Test a token for validity. */
  public async testToken(token: string) {
    await this.api.post(ROUTES.TEST_TOKEN, { token }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return token;
  }

  /** Get your subscription usage */
  public async consumption() {
    return this.get<Consumption>(ROUTES.MY_SUBSCRIPTION);
  }

  /** List your voicemails */
  public async voicemails() {
    const res = await this.api.get<Voicemail[]>(ROUTES.VOICEMAILS);
    return res.data;
  }

  /** Get details on specific voicemail */
  public async voicemail(filename: string) {
    return this.get<VoicemailData>(`${ROUTES.VOICEMAILS}/${filename}`);
  }

  /** Get download link for voicemail */
  public async voicemailDownload(filename: string) {
    const res = await this.api.post<string>(ROUTES.VOICEMAIL_DOWNLOAD, {
      filename,
    });
    return res.data;
  }

  /** Delete a voicemail */
  public deleteVoicemail(filename: string) {
    return this.delete(`${ROUTES.VOICEMAILS}/${filename}`);
  }

  /** List your recordings. */
  public async recordings(): Promise<Recording[]> {
    return this.get<Recording[]>(ROUTES.RECORDINGS);
  }

  /** Get details on specific recording */
  public async recording(uniqueid: string) {
    return this.get<RecordingData>(`${ROUTES.RECORDINGS}/${uniqueid}`);
  }

  /** Get event history for the interval specified.
   *
   * @param start - Beginning of interval in Unix time.
   * @param end - End of interval in Unix time.
   *
   * @returns A colleciton of all calls, messages and data from that interval.
   */
  public async eventHistory(start?: number, end?: number) {
    return this.get<EventHistory>(ROUTES.EVENT_HISTORY, { params: { start, end } });
  }

  /** DEPRECATED. Use `eventHistory` instead. */
  public async callHistory(params: CallHistoryPostBody) {
    const res = await this.api.post<CallHistory>(ROUTES.CALL_HISTORY, params);
    return res.data;
  }

  /** Summarise subscription activity using `params` as options. See CRM api `v1/CallHistory` for details. */
  public async callHistorySummary(params: CallHistoryPostBody) {
    const res = await this.api.post<CallHistorySummary>(ROUTES.CALL_HISTORY, {
      ...params,
      summary: true
    });
    return res.data;
  }

  /** Get SIM data for subscription. */
  public async simData(subscriptionId?: number) {
    let path = ROUTES.SIM_DATA;
    if (subscriptionId) {
      path += `/${subscriptionId}`;
    }
    const res = await this.api.get<Sim>(path);
    return res.data;
  }

  /** Get your user and profile info */
  public async profile() {
    const res = await this.api.get<UserProfile>(ROUTES.PROFILE);
    return res.data;
  }

  /** List recent calls, voicemails, invoices and recordings if available. */
  public async recents() {
    return this.get<Recents>(ROUTES.RECENTS);
  }

  /** Get user invoices */
  public async invoices() {
    const res = await this.api.get<Invoice[]>(ROUTES.INVOICES);
    return res.data.map(invoice => {
      return {
        ...invoice,
        invoiceDate: new Date(invoice.invoiceDate as any),
        dueDate: new Date(invoice.dueDate as any),
      } as Invoice;
    });
  }

  /** Get details on specific invoice. */
  public async invoice(id: number) {
    return this.get<Invoice>(`${ROUTES.INVOICES}/${id}`);
  }

  /** List your permissions */
  public async permissions() {
    const res = await this.api.get<string[]>(ROUTES.PERMISSIONS);
    return res.data;
  }

  public async theme() {
    const res = await this.api.get<Theme>(ROUTES.THEME);
    return res.data;
  }

  /** List queues under customer */
  public async queues() {
    return this.get<Queue[]>(ROUTES.QUEUES);
  }

  /** Set your availability in a queue you're member of. */
  public async saveQueueAvailable(queueId: number, available: boolean) {
    const body: QueueAvailablePostBody = {
      queue_id: queueId,
      available,
    };

    return this.post<number>(ROUTES.SAVE_QUEUE_AVAILABLE, body);
  }

  /** Join or leave a queue. */
  public async queueMemebership(queueId: number, join: boolean) {
    const body: QueueMembershipPostBody = {
      queue_id: queueId,
      join,
    };
    return this.post<void>(ROUTES.QUEUE_MEMBERSHIP, body);
  }

  /** List your current routing config. */
  public async getPresence() {
    return this.get<Option<UserPresence>>(ROUTES.USER_PRESENCE);
  }

  /** Edit your routing config. */
  public async setPresence(presence: UserPresencePostBody) {
    return this.post<UserPresence>(ROUTES.USER_PRESENCE, presence);
  }

  /** List all available references */
  public references() {
    return this.get<Reference[]>(ROUTES.REFERENCES);
  }

  /** Get a specific reference. */
  public referenceById(id: number) {
    return this.get<Reference>(`${ROUTES.REFERENCES}/${id}`);
  }

  /** Save a user reference, marking the user as references from the start and
   * end dates for the specified numbers. If no start date is given, it is
   * assumed to be now.
   *
   * @param reference Object containing an id
   * @param numbers Phone numbers to mark referenced
   * @param end Ending time as Unix time
   * @param start Default now. Starting time in Unix time.
   *
   * @returns The created reference status.
   */
  public makeUserRef(reference: Identifiable, numbers: string[], end: number, start: number, subject: string, description: string) {
    const data: MakeUserRefPostBody = {
      reference,
      numbers,
      start,
      end,
      subject,
      description
    };
    return this.post<ReferenceStatus>(`${ROUTES.MAKE_USER_REF}`, data);
  }

  public getUserRef(id: number) {
    return this.get<ReferenceStatus>(`${ROUTES.GET_USER_REF}/${id}`);
  }

  public updateUserRef(ref: ReferenceStatus) {
    return this.post<ReferenceStatus>(ROUTES.UPDATE_USER_REF, ref);
  }

  /** See your current reference status */
  public checkUserRef() {
    return this.get<ReferenceStatus[]>(ROUTES.CHECK_USER_REF);
  }

  /** Reset your reference status. */
  public cancelUserRef() {
    return this.post<ReferenceStatus[]>(ROUTES.CANCEL_USER_REF);
  }

  public cancelUserRefById(id: number) {
    console.debug("cancelUserRefById", id);
    return this.delete(`${ROUTES.CANCEL_USER_REF}/${id}`);
  }

  /** Lists products available. */
  public products() {
    return this.get<Product[]>(ROUTES.PRODUCTS);
  }

  /** Buy a product for the given subscriptions. */
  public buyProduct(productId: number, subscriptionIds: number[]): Promise<void> {
    return this.post<void>(`${ROUTES.PRODUCTS}/buy`, {
      product_id: productId,
      subscription_ids: subscriptionIds,
    });
  }

  /** List users on customer. */
  public userList() {
    return this.get<AppUser[]>(ROUTES.USER_LIST);
  }

  /**
   * Get chats with another user.
   *
   * @param correspondent CRM ID of user
   */
  public chats(correspondent: number) {
    return this.get<Chats>(`${ROUTES.CHATS}/${correspondent}`);
  }

  /**
   * Send a message to the recipient.
   * @param recipient CRM ID of recipient
   * @param body Message body
   */
  public sendChat(recipient: number, message: string) {
    const body = {
      recipient_crm_id: recipient,
      body: message,
    };
    return this.post(`${ROUTES.CHATS}/send`, body);
  }

  /**
   * Mark the chats as read
   * @param messages Chat ID's to mark read
   */
  public markChatsRead() {
    // const body = { messages };
    return this.post(`${ROUTES.CHATS}/read`);
  }

  /** All prmission groups for the customer. */
  public permissionGroups() {
    return this.get<PermissionGroup[]>(`${ROUTES.PERMISSIONS}/groups`);
  }

  /** Permission group the user with the specified `crmId` belongs to. Calling use if `crmId` unset. */
  public permissionUserGroups(crmId?: number) {
    return this.get<PermissionGroup[]>(`${ROUTES.PERMISSIONS}/groups/user/${crmId || ''}`);
  }

  /** Update user permission group membership. Updates calling user if `crmId` unset. */
  public updatePermissionUserGroups(add: number[], remove: number[], crmId?: number) {
    const body = {
      add,
      remove,
    };
    return this.post<PermissionGroup[]>(`${ROUTES.PERMISSIONS}/groups/user/${crmId || ''}`, body);
  }


  // Graph Module

  /** Determines if the user is connected with outlook. */
  public async graphRegistration() {
    const res = await this.api.get<GraphRegisterResponse>(GRAPH_ROUTES.REGISTRATION);
    console.log("graphRegistration resp", res.data);
    return res.data.access_token_available;
  }

  /** Get secret to use for WebSocket init. */
  public graphRegistrationKey() {
    return this.post<string>('/graph/register/key');
  }

  /**
   * Check if the user has any conflicting bookings in Outlook for the given interval.
   *
   * @param end UNIX time of interval end.
   * @param start UNIX time of interval start. Defaults to Now.
   *
   * @returns A list of conflicting events.
   */
  public graphCheck(end: number, start?: number) {
    return this.post<Event[]>(GRAPH_ROUTES.CHECK, { start, end });
  }


  // Internal

  private async delete(path: string, config?: AxiosRequestConfig) {
    await this.api.delete(path, config);
  }

  private async post<T>(path: string, body?: any, config?: AxiosRequestConfig) {
    const res = await this.api.post<T>(path, body, config);
    return res.data;
  }

  private async get<T>(path: string, config?: AxiosRequestConfig) {
    const res = await this.api.get<T>(path, config);
    return res.data;
  }
}

export function isCallHistorySummary(ch: CallHistory | CallHistorySummary): ch is CallHistorySummary {
  return ch.hasOwnProperty('currency');
}
