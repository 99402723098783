







































































































































import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import { EasyAppAPI } from "../../../api";
import { Utils } from "@/mixins";
import { Globals } from "../globals";
import {
  getAssetPath, switchTheme, storeTheme, loadTheme,
  clearTheme, validTheme
} from "../utils";
import * as AppApi from "../../../api";
import {connect} from '../WebSocketService';

@Component
export default class Login extends Mixins(Utils) {
  public username: string = "";
  public password: string = "";
  public error: string | null = null;
  public processing: boolean = false;
  public bankidInProgress: boolean = false;

  public qrValue: string|null = null;

  public bankIDAuth: undefined|{
    id: string,
    qr: () => Promise<string|null>,
    appToken: string,
    cancel: () => void,
    loginInit: Promise<void>,
    loginDone: Promise<{session: string}>
  } = undefined;

  private cleanup: Array<()=>void> = [];

  public destroyed() {
    console.log('Login unmounted');
    for (const cb of this.cleanup) {
      if (typeof cb === 'function') {
        cb();
      }
    }
  }

  get logo() {
    const theme: AppApi.Theme|null = loadTheme();
    if(theme && theme.found) {
        return theme.menu_header;
    } else {
        const asset_path = getAssetPath();
        const ret = `${asset_path}img/logo.svg`;
        console.log("Header get header", ret);
        return ret;
    }
  }

  public created() {
    const token = this.$store.getters.token;
    const api = new EasyAppAPI(process.env.VUE_APP_SERVER_BASE_URL!);
    api
      .testToken(token)
      .then(() => {
        this.$router.push({ name: "my-profile" });
      })
      .catch(() => {
        this.$store.dispatch("logout");
      });
  }

  public async triggerBankIDLogin() {
    this.processing = true;
    this.bankidInProgress = true;
    const api = new EasyAppAPI(process.env.VUE_APP_SERVER_BASE_URL!);
    console.debug(`API Base URL: ${process.env.VUE_APP_SERVER_BASE_URL!}`);

    let qrInterval;
    let qrCount = 0;
    let session;
    try {
      this.bankIDAuth = await api.bankidLogin(Globals.login.bankid);
      this.cleanup.push(() => {
        if (this.bankIDAuth) {
          this.bankIDAuth.cancel();
        }
        this.bankIDAuth = undefined;
      });
      if (typeof cordova !== 'undefined' && typeof cordova.platformId !== 'undefined') {
        let redir = `https://app.bankid.com/?autostarttoken=${this.bankIDAuth.appToken}&redirect=`;
        if (cordova.platformId === 'android') {
          // Redirect to null = return to app
          redir += 'null';
          (cordova as any).InAppBrowser.open(redir, '_system', 'location=yes');
        } else if (cordova.platformId === 'ios') {
          // iOS doesn't support return to app, use app scheme
          redir += encodeURIComponent(`${Globals.bundle}://authCallback?id=${this.bankIDAuth.id}`);
          (cordova as any).InAppBrowser.open(redir, '_system', 'location=yes');
        }
      }
      const qrBase = process.env.VUE_APP_SERVER_BASE_URL! +
          `/api/system/${Globals.login.bankid}/qr/${this.bankIDAuth.id}.png?n=`;

      qrInterval = setInterval(() => this.qrValue = qrBase+(qrCount++), 1000);
      this.qrValue = qrBase+(qrCount++);
      await this.bankIDAuth.loginInit;
      clearInterval(qrInterval);
      qrInterval = undefined;
      this.qrValue = null;
      const res = await this.bankIDAuth.loginDone;
      session = res.session;
      this.bankIDAuth = undefined;

    } catch(err) {
      if (this.bankIDAuth) {
        this.bankIDAuth.cancel();
      }
      this.bankIDAuth = undefined;
      console.error("Got error", err);
      if (err === 'userCancel') {
        this.error = this.$t("Authentication cancelled").toString();
      } else if (err === 'timeout') {
        this.error = this.$t("Authentication timed out").toString();
      } else {
        this.error = this.$t("BankID Authentication Error").toString();
      }
    }
    if (qrInterval) {
      clearInterval(qrInterval);
      qrInterval = undefined;
    }
    if (session) {
      try {
        await this.handleLogin(api, session);
      } catch(err) {
        console.error("Got error", JSON.stringify(err));
        this.error = this.$t("Authentication Error").toString();
      }
    }
    this.bankidInProgress = false;
    this.processing = false;
  }

  public toggleResetPassword() {
    console.log("reset_password pressed", this.$store.getters.resetPassword);
    this.$store.commit("resetPassword", !this.$store.getters.resetPassword);
  }

  public async handleSubmit() {
    this.processing = true;
    const api = new EasyAppAPI(process.env.VUE_APP_SERVER_BASE_URL!);
    console.debug(`API Base URL: ${process.env.VUE_APP_SERVER_BASE_URL!}`);

    try {
      const { session } = await api.login(this.username, this.password);
      await this.handleLogin(api, session);
    } catch (err) {
      console.error("Got error", err);
      this.error = this.$t("Username or password incorrect!").toString();
    }

    this.processing = false;
  }

  get submitDisabled() {
    return this.processing || (!this.username || !this.password);
  }

  public openBankID() {
    if (this.bankIDAuth) {
      const redir = `https://app.bankid.com/?autostarttoken=${this.bankIDAuth.appToken}&redirect=null`;
      if (typeof cordova !== 'undefined' && typeof (cordova as any).InAppBrowser !== 'undefined') {
        (cordova as any).InAppBrowser.open(redir, '_system', 'location=yes');
      } else {
        window.open(redir, '_blank', 'location=yes');
      }
    }
    return 'javascript:void(0);';
  }

  get submitText() {
    if (this.processing) {
      return this.$t("Logging in...");
    } else {
      return this.$t("Log in");
    }
  }

  get helpUrl() {
    const theme = loadTheme();
    if(theme && theme.found) {
      return theme.help_link;
    } else {
      return Globals.urls.help;
    }
  }

  get hasPassword() {
    return Globals.login.password;
  }

  get hasBankID() {
    return Globals.login.bankid > 0;
  }
  get locale() {
    return this.$store.getters.locale;
  }

  set locale(locale: string) {
    this.$store.commit("setLocale", locale);
    this.$i18n.locale = this.$store.getters.locale;
  }

  @Watch("username")
  public onUsernameChanged() {
    this.error = null;
  }

  @Watch("password")
  public onPasswordChange() {
    this.error = null;
  }

  private async handleLogin(api: EasyAppAPI, token: string) {
    connect(token);

    const perms = await api.permissions();
    console.log("getting theme");
    const theme = await api.theme();
    if(validTheme(theme)) {
      storeTheme(theme);
    } else {
      clearTheme();
    }
    switchTheme(this);


    this.error = null;
    await this.$store.dispatch("login", { token, perms });
    console.log("pushing prifile recents");
    await this.$router.push({ name: "my-profile-recents" });

  }

}
