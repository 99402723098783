import Vue from 'vue';
import Vuex from 'vuex';
import { STORAGE, SUPPORTED_LANGUAGES } from './constants';
import { AppError, mkError } from './errors';
import { disconnect } from './WebSocketService';



Vue.use(Vuex);

export interface Store {
  token: string | null;
  perms: string[];
  showVersionWarning: boolean;
  adminMode: boolean;
  error?: AppError;
  reset_password: boolean;
  private_customer: number;
  locale: string;
}

export default new Vuex.Store<Store>({
  state: {
    token: get_token(),
    perms: get_permissions(),
    showVersionWarning: false,
    adminMode: get_admin(),
    reset_password: false,
    private_customer: 0,
    locale: get_language()
  },
  mutations: {
    registerToken(state, token: string) {
      state.token = set_token(token);
    },
    resetToken(state) {
      state.token = clear_token();
    },
    permissions(state, perms: string[]) {
      state.perms = set_permissions(perms);
    },
    resetPermissions(state) {
      state.perms = set_permissions([]);
    },
    showVersionWarning(state) {
      state.showVersionWarning = true;
    },
    hideVersionWarning(state) {
      state.showVersionWarning = false;
    },
    setAdminMode(state, value: boolean) {
      state.adminMode = set_admin(value);
    },
    enableAdminMode(state) {
      state.adminMode = set_admin(true);
    },
    disableAdminMode(state) {
      state.adminMode = set_admin(false);
    },
    toggleAdminMode(state) {
      state.adminMode = toggle_admin();
    },
    setError(state, message: string) {
      state.error = mkError(message);
    },
    clearError(state) {
      delete state.error;
    },
    resetPassword(state, reset: boolean) {
      state.reset_password = reset;
    },
    privateCustomer(state, val: number) {
      state.private_customer = val > 0 ? 1 : -1;
    },
    setLocale(state, locale: string) {
      state.locale = set_language(locale);
    }

  },
  actions: {
    login({ commit }, { token, perms }: { token: string, perms: string[] }) {
      commit('permissions', perms);
      commit('registerToken', token);
      commit('disableAdminMode');
    },
    logout({ commit }) {
      commit('resetToken');
      commit('resetPermissions');
      commit('disableAdminMode');
      disconnect();
    },
  },
  getters: {
    loggedIn: state => state.token !== null,
    token: state => state.token,
    perms: state => state.perms,
    version: state => state.showVersionWarning,
    adminMode: state => state.adminMode,
    resetPassword: state => state.reset_password,
    privateCustomer: state => state.private_customer,
    locale: state => state.locale,
  },
});




export function get_token(): string | null {
  return window.localStorage.getItem(STORAGE.TOKEN);
}

export function set_token(token: string): string | null {
  window.localStorage.setItem(STORAGE.TOKEN, token);
  return get_token();
}

export function clear_token(): string | null {
  window.localStorage.removeItem(STORAGE.TOKEN);
  return get_token();
}

export function get_permissions(): string[] {
  const perms = window.localStorage.getItem(STORAGE.PERMISSIONS);
  if (perms != null) {
    return JSON.parse(perms) as string[];
  }
  return [];
}

export function set_permissions(perms: string[]) {
  window.localStorage.setItem(STORAGE.PERMISSIONS, JSON.stringify(perms));
  return get_permissions();
}

export function get_admin() {
  return window.localStorage.getItem(STORAGE.ADMIN_MODE) === "true";
}

export function set_admin(admin_mode: boolean) {
  window.localStorage.setItem(STORAGE.ADMIN_MODE, admin_mode.toString());
  return get_admin();
}

export function toggle_admin() {
  return set_admin(!get_admin());
}

export function get_language(): string {
  const slang = window.localStorage.getItem(STORAGE.LOCALE);
  if (slang == null) {
    const llang = window.navigator.language.slice(0, 2);
    return SUPPORTED_LANGUAGES.indexOf(llang) >= 0 ? llang : 'en';
  }
  return slang;
}

export function set_language(lang: string) {
  if (SUPPORTED_LANGUAGES.indexOf(lang) >= 0) {
    window.localStorage.setItem(STORAGE.LOCALE, lang);
  }
  return get_language();
}
