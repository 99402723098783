import { Component, Mixins } from 'vue-property-decorator';
import CallTable from '@/components/lists/CallTable.vue';
import MessageTable from '@/components/lists/MessageTable.vue';

import { Utils, Events } from '@/mixins';
import moment, { parseTwoDigitYear } from 'moment';
import _ from 'lodash';
import { EventHistory, Call, NumberAliasMap } from '../../../../api';

const DAY_IN_SECONDS = 24 * 60 * 60;

@Component({
  components: {
    CallTable,
    MessageTable,
  },
})
export default class EventHistoryView extends Mixins(Events) {

  public processing = true;

  public history: EventHistory | null = null;
  public numbers: string[] = [];

  public start: number = 0;
  public end: number = 0;
  public number_alias: NumberAliasMap = {};

  public created() {
    this.setup();
  }

  public setup() {
    const day = this.dayDate().unix();

    this.start = day;
    this.end = day + DAY_IN_SECONDS;

    this.refresh();
  }

  public async refresh() {
    this.processing = true;

    const [eh, numbers, aliases] = await Promise.all([
      this.api.eventHistory(this.start, this.end),
      this.api.myNumbers(),
      this.api.getNumberLookups()
    ]);
    this.number_alias = aliases;
    this.history = eh;
    this.numbers = numbers;

    this.processing = false;
  }

  get calls() {
    if (!this.history) {
      return [];
    }
    return _.orderBy(this.history.calls, ['start'], ['desc']);
  }

  get messages() {
    if (!this.history) {
      return [];
    }
    return _.orderBy(this.history.messages, ['timestamp'], ['desc']);
  }

  public dayDate() {
    const {year, month, day} = this.$route.params;
    const date = moment(new Date(Number(year), Number(month) - 1, Number(day), 0, 0, 0, 0));
    date.locale(this.$i18n.locale);
    return date;
  }

  public previous() {
    const to = this.dayDate().subtract(1, 'day');
    console.log(this.dayDate().format('LL'), to.format('LL'));

    this.navigateDate(to);
  }

  public next() {
    const to = this.dayDate().add(1, 'day');
    // console.log('Now:', this.dayDate().format('LL'), 'To:', to.format('LL'));

    this.navigateDate(to);
  }

  public backToMonth() {
    const { year, month } = this.$route.params;
    this.$router.push({
      name: 'event-history-day',
      params: {
        year,
        month,
      },
    });
  }

  private navigateDate(date: moment.Moment) {
    const year = date.year().toString();
    const month = (date.month() + 1).toString();
    const day = date.date().toString();

    // console.log(year, month, day);

    this.$router.push({
      name: 'event-history-view',
      params: {
        year,
        month,
        day,
      },
    });

    this.$nextTick(this.setup);
  }

  get prettyTitle() {
    const date = this.dayDate();
    return date.format('dddd LL');
  }
}
