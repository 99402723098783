




























































import { Component, Mixins } from "vue-property-decorator";
import { Utils } from "@/mixins";
import { Chat, Chats } from '../../../../api';
import ChatDivider from "@/components/chat/ChatDivider.vue";
import ChatMessage from "@/components/chat/ChatMessage.vue";
import ChatForm from "@/components/chat/ChatForm.vue";
import moment from "moment";
import _ from "lodash";
import { setInterval, setTimeout } from "timers";

/** 5 s in ms */
const REFRESH_CYCLE = 5 * 1000;

@Component({
  components: {
    ChatDivider,
    ChatMessage,
    ChatForm
  }
})
export default class ChatList extends Mixins(Utils) {
  public processing = true;
  public lastRefresh?: number;
  public unavailable: boolean = false;
  // public schedule?: NodeJS.Timeout;

  public sent: Chat[] = [];
  public received: Chat[] = [];

  public correspondentId: number = 0;
  public correspondentName: string = "";

  public created() {
    this.correspondentId = Number(this.$route.params.id);
    this.correspondentName = this.$route.params.name;

    this.refresh();
  }

  public mounted() {
    this.scrollChatToBottom();
    this.scheduleRefresh();
  }

  public destroyed() {
    console.log("Destroyed");
    // if (this.schedule) {
    //     console.log('Clearing Chat refresh no', this.schedule);
    //     clearInterval(this.schedule);
    //     this.schedule = undefined;
    // }
  }

  public async refresh(scroll = false, process = true) {
    if (process) {
      this.processing = true;
    }

    const start = Date.now();

    try {
      const chats = await this.api.chats(this.correspondentId);

      this.api.markChatsRead();

      if (!this.lastRefresh || this.lastRefresh < start) {
        // Scroll messages to last on new messages
        const changed =
          chats.sent.length > this.sent.length ||
          chats.received.length > this.received.length;
        if (changed || scroll) {
          this.$nextTick(this.scrollChatToBottom);
        }

        this.sent = chats.sent;
        this.received = chats.received;

        this.lastRefresh = start;
      } else {
        console.log("Refresh", start, "aborted");
      }
    } catch (err) {
      this.unavailable = true;
      // if (this.schedule) {
      //     console.log('Clearing Chat refresh no', this.schedule, 'due to error');
      //     clearInterval(this.schedule);
      //     this.schedule = undefined;
      // }
    }

    this.processing = false;
  }

  public scheduleRefresh() {
    const modulator = async () => {
      // clearInterval not working for some reason
      // Only renews timeout if on this view
      if (this.$route.name === "chat" && !this.unavailable) {
        await this.refresh(false, false), setTimeout(modulator, REFRESH_CYCLE);
      }
    };
    modulator();
  }

  public scrollChatToBottom() {
    const element = this.$refs.chatmiddle as Element;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }

  public async handleSend(body: string) {
    await this.api.sendChat(this.correspondentId, body);
    this.refresh(true);
  }

  get chats(): Array<ChatModel | Divider> {
    const sent = this.sent.map(chat => this.fromChat(chat, true));
    const received = this.received.map(chat => this.fromChat(chat, false));

    return _.orderBy(sent.concat(received), "sent_at", ["asc"]);
  }

  public fromChat(chat: Chat, own: boolean): ChatModel {
    const prettySentAt = moment.unix(chat.sent_at).format("HH:mm");
    const prettyReadAt = chat.read_at
      ? moment.unix(chat.read_at).format("HH:mm")
      : undefined;
    return {
      ...chat,
      name: own ? this.$t("You").toString() : this.correspondentName,
      own,
      pretty: {
        sent_at: prettySentAt,
        read_at: prettyReadAt
      }
    };
  }
}

interface ChatModel extends Chat {
  own: boolean;
  name: string;
  pretty: {
    sent_at: string;
    read_at?: string;
  };
}

interface Divider {
  divider: true;
  time: number;
}
