




































































import { Component, Vue } from 'vue-property-decorator';
import Sidebar from '@/navigation/Sidebar.vue';
import { SidebarItem } from '@/navigation/Sidebar.vue';
import { Route, routes } from '../router';
import _ from 'lodash';
import { unmetPermissions } from '../../../api';

const DASHBOARD_SIDEBAR_OPEN = 'c-dashboard-sidebar--is-open';

@Component({
  components: {
    Sidebar,
  },
})
export default class Dashboard extends Vue {

  public perms: string[] = [];

  public openSidebar: boolean = false;

  public created() {
      const permissions: string[] = this.$store.getters.perms || [];
      this.perms = permissions;
  }

  get sidebarItems() {
    const permitted = routes.filter(route => {

      if (!route.permissions) {
        return true;
      }

      const requiredPerms = route.permissions;
      const userPerms = this.perms;

      const missing = unmetPermissions(userPerms, requiredPerms);
      if (missing.length > 0) {
        console.info('Permissions:', missing, 'missing!', 'User:', userPerms, 'Required:', requiredPerms);
        console.log(unmetPermissions([], requiredPerms).map(p => ({ name: p, exist: userPerms.indexOf(p) !== -1})));
      }

      return missing.length === 0;
    });

    return permitted.map((route: Route) => {
      const isCurrent = this.$route.name!.indexOf(route.title) === 0;
      const name = this.routeName(route);

      const item = {
        text: this.$t(route.title).toString(),
        icon: route.icon || 'circle',
        path: route.path,
        name,
        isCurrent,
      };
      return item;
    });
  }

  public toggleSidebar() {
    (this.$refs.sidebar as Element).classList.toggle(DASHBOARD_SIDEBAR_OPEN);
    // document.body.classList.toggle('is-stuck'); // Prevent scroll
    this.openSidebar = !this.openSidebar;
  }

  public hideSidebar() {
    (this.$refs.sidebar as Element).classList.remove(DASHBOARD_SIDEBAR_OPEN);
    // document.body.classList.remove('is-stuck'); // Prevent scroll
    this.openSidebar = false;
  }

  public routeName(route: Route) {
      if (route.children && route.children.length > 0) {
          return route.children[0].name!;
      }
      return route.name;
  }

  get version() {
    return this.$store.getters.version;
  }
}
