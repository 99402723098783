


































import {Vue, Component, Mixins} from 'vue-property-decorator';
import LinkPlate from '@/components/LinkPlate.vue';
import {Product, Info} from '../../../../api';
import {Utils} from '@/mixins';
import _ from 'lodash';

@Component({
  components: {
    LinkPlate,
  },
})
export default class Topups extends Mixins(Utils) {

  private processing = true;

  private products: Product[] = [];
  private isPrivateCustomer: boolean = true;
  private error: string | null = null;

  public created() {
    this.refresh();
  }

  public async refresh() {
    try {
      this.processing = true;

      const prods = await this.api.products();
      this.products = prods;
      this.isPrivateCustomer = await this.api.isPrivateCustomer();
      this.processing = false;
    } catch (err) {
      this.error = (err as any).message;
      console.error(err);
      this.processing = false;
    }
  }

  public handleClick(p: Product) {

    this.$router.push({
      name: 'topup-subscriptions',
      params: {
        product: p.id.toString(),
        system: p.system.toString(),
        name: p.name,
        price: p.price.toString(),
        tax_amount: p.tax_amount.toString(),
        currency: p.currency,
      },
    });
  }

  get bought() {

    if (this.processing) {
      return null;
    }

    const {productid} = this.$route.query;
    if (!productid) {
      return null;
    }

    const selection = (this.$route.query.selection as string[])
      .map(name => name === '' ? this.$t('No name').toString() : name);

    const product = this.products.find(p => p.id === Number(productid));
    if (!product) {
      return null;
    }

    let names: string;
    if (selection.length === 1) {
      names = selection[0];

    } else {
      const first = _.initial(selection).join(', ');
      const last = _.last(selection);

      names = `${first} ${this.$t('and')} ${last}`;
    }

    return {
      product: product.name,
      names,
    };
  }

  private getPriceFormatted(price: number, vat: number, currency: string) {
    console.log("getPriceFormatted", price, vat, currency, this.isPrivateCustomer);
    if (this.isPrivateCustomer) {
      return this.formatPrice(price + vat, currency);
    } else {
      return this.formatPrice(price, currency);
    }
  }
}
