















































import s from './event-history-day';
export default s;
