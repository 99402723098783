import { Mixins, Component, Vue } from 'vue-property-decorator';
import { Utils } from '@/mixins';
import AccountPlate from '@/components/plates/AccountPlate.vue';
import placeholder from '@/assets/img/user-placeholder.png';
import { AppUser } from '../../../../api';
import _ from 'lodash';

@Component({
  components: {
    AccountPlate,
  },
})
export default class MyCompany extends Mixins(Utils) {
  public processing: boolean = true;
  public users: AppUser[] = [];
  public placeholder = placeholder;

  public thisUserID: number = 0;

  public created() {
    this.fetch();
  }

  public async fetch() {
    this.processing = true;

    const [users, info] = await Promise.all([
      this.api.userList(),
      this.api.info(),
    ]);

    this.users = users.map(u => ({ ...u, ref: _.head(u.references) }));
    this.users.sort((a,b)=> a.display_name <= b.display_name ? -1 : 1);

    this.thisUserID = info.crm_id;

    this.processing = false;
  }

  public openChat(user: AppUser) {
    const name = user.display_name;
    const id = user.id.toString();

    if (user.id !== this.thisUserID) {
      this.$router.push({
        name: 'chat',
        params: { name, id },
      });
    }
  }
}
