import { Request, Response, NextFunction } from "express";
import { IResource, Identifiable, Pagination, Duration, Numbers, SoftDelete } from './common';
import {Option, Some, None} from 'tsoption'

// Common

export type ResourceHandler<T> = (req: Request, res: Response, next: NextFunction) => Promise<T>;

/**
 * Controller for a resouce. Resources may be created, deleted, updated,
 * listed (many) and showed (one).
 */
export interface IResourceController<T> {
  /** List all resources. GET /resources */
  index(req: Request, res: Response, next: NextFunction): Promise<T[]>;
  /** Get a specific resource. GET /resources/:id */
  show(req: Request, res: Response, next: NextFunction): Promise<T>;
  /** Create a new resource. POST /resources */
  create(req: Request, res: Response, next: NextFunction): Promise<T>;
  /** Update a resource. PATCH /resources/:id */
  update(req: Request, res: Response, next: NextFunction): Promise<T>;
  /** Delete a resource. DELETE /resources/:id */
  remove(req: Request, res: Response, next: NextFunction): Promise<T>;
}

export interface PatchSoftDelete {
  deleted?: boolean;
}

export type Role = string;

export interface ICurrency extends IResource, CurrencyPostBody {
  // fraction_digits: number;
  // round_to_digits: number;
  countries?: ICountry[];
}

export interface CurrencyPostBody {
  name: string;
}

export interface CurrencyPatchBody {
  name?: string;
}

export interface ICountry extends IResource, CountryPostBody {
}

export interface CountryPostBody {
  name: string;
  country_code: string;
  language_code: string;
  currency: ICurrency;
}

export interface CountryPatchBody {
  name?: string;
  country_code?: string;
  language_code?: string;
  currency?: ICurrency;
}

// Auth

export interface LoginBody {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  permissions: string[];
}

export interface ForgotBody {
  email: string;
}

export interface ResetBody {
  token: string;
  password: string;
}

// User

export interface UserPostBodySafe {
  username: string;
  first_name: string;
  last_name: string;
  mobile_name: string;
  phone_name?: string;
  office_phone_name?: string;
  mailbox?: string;
  customer: Identifiable;
}

export interface UserPostBody extends UserPostBodySafe {
  password: string;
}

export interface UserPatchBody extends PatchSoftDelete {
  username?: string;
  first_name?: string;
  last_name?: string;
  mobile_name?: string;
  phone_name?: string;
  office_phone_name?: string;
  mailbox?: string;
}

export interface IUser extends IResource, UserPostBodySafe {
  customer: ICustomer;
  statuses: IReferenceStatus[];
}

export interface UserProfile {
  first_name?: string;
  last_name?: string;
  number: string;
}

export interface Recents {
  my_numbers: string[];
  calls?: Relative<Call>[];
  voicemails?: Voicemail[];
  recordings?: Recording[];
  invoices?: Invoice[];
}

export interface ReferencePostBody {
  name: string;
  icon?: string;
  reason: number;
  customer: Identifiable;
}

export interface IReference extends IResource, ReferencePostBody {
}

export interface ReferencePatchBody extends PatchSoftDelete {
  title?: string;
  icon?: string;
  reason?: number;
}

export interface ReferenceStatusPostBody {
  start?: Date;
  end: Date;
  user: Identifiable;
  reference: Identifiable;
}

export interface IReferenceStatus extends IResource, ReferenceStatusPostBody {
}

export interface ReferenceStatusPatchBody extends PatchSoftDelete {
  start?: Date;
  end?: Date;
}

export interface StatusStatePostBody {
  name: string;
}

export interface IStatusState extends IResource, StatusStatePostBody {
}

export interface StatusStatePatchBody extends PatchSoftDelete {
  name?: string;
}

// Groups

export interface GroupsParams {
  /** Groups inlcuding this user id. */
  user: number;
}

export interface Group extends IResource, GroupPostBody { }

export interface GroupPostBody {
  name?: string;
  // members: User[];
}

// Phone

export interface SimsParams {
  /** Sims for this user id. */
  user: number;
}

export interface Sim {
  pin: string;
  puk: string;
  iccid: string;
}

export interface CallsParams {
  /** Calls including this user id. */
  user: number;
}

export interface DeletedParams {
  deleted?: boolean;
}

export interface VoicemailsParams extends DeletedParams { }

export interface VoicemailPatchBody extends DeletedParams, Identifiable { }

export interface PhonePlan extends IResource {
  data: PlanIStatus;
  calls: PlanIStatus;
  sms: PlanIStatus;
  mms: PlanIStatus;
}

export interface PlanIStatus {
  plan: string;
  limit: number;
  used: number;
}

export interface PhoneConfig {
  primary: Sim | 'hidden';
  call_forwarding: Sim | 'voicemail' | 'none';
  occupied_forwarding: Sim | 'voicemail' | 'none';
  no_answer_forwarding: Sim | 'voicemail' | 'none';
}

export interface PhoneConfigPatchBody {
  primary?: Sim | 'hidden';
  call_forwarding: Sim | 'voicemail' | 'none';
  occupied_forwarding: Sim | 'voicemail' | 'none';
  no_answer_forwarding: Sim | 'voicemail' | 'none';
}

export interface Topup extends IResource {
  name: string;
  types: string[];
  valid_for: string[];
  price: Price;
}

export interface Price {
  amount: number;
  currency: string;
}

export interface BuyTopupPostBody {
  topup: Topup;
  sims: Sim[];
}

export interface SuspendPostBody {
  sim: Sim;
  reason?: string;
}


// Integrations

export interface Integration extends IResource {
  name: string;
  default_IStatus: string;
}

// Queues

export interface QueuesParams {

}

export interface Queue {
  id: number;
  name: string;
  description: string;
  recordgroup: number;
  /** Total number of agents registered to the queue. */
  total_agents: number;
  /** Number of actual agents available. */
  available_agents: number;
  /** The agents registered to this queue. */
  agents: Agent[];
  /** If this user is an agent of the queue. */
  joined: boolean;
  /** If this user is an available agent. */
  available: boolean;
}

export interface Agent {
  queue_id: number;
  destination_id: number;
  dtype: 'phone' | 'forward' | 'sipuri' | 'peer';
  dnumber: string;
  available: boolean;
  priority: number;
  /** The user of this number, if available. */
  user?: {
    id: number;
    first_name: string;
    last_name: string;
  };
  subscription?: SubscriptionSummary;
}

export interface QueueAvailablePostBody {
  available: boolean;
  queue_id: number;
}

export interface QueueMembershipPostBody {
  queue_id: number;
  join: boolean;
}

/** Customer */
export interface ICustomer extends IResource, CustomerPostBody { }

export interface CustomerPostBody {
  name: string;
  azure_tenant?: string;
  crm_id: number;
  // crm_no?: string;
}

export interface CustomerPatchBody {
  name?: string;
  azure_tenant?: string;
  crm_id?: number;
  // crm_no?: string;
}

/** Enswitch credentials */
export interface IEnswitch extends IResource, EnswitchPostBody {
}

export interface EnswitchPostBody {
  customer_enswitch_id: number;
  username: string;
  password: string;
}

export interface EnswitchPatchBody {
  customer_enswitch_id?: number;
  username?: string;
  password?: string;
}

export interface MakeUserRefPostBody {
  /** Unix time */
  start?: number;
  /** Unix time */
  end: number;
  subject: string,
  description: string,
  reference: Identifiable;
  /** @minimum 1 */
  numbers: string[];
}

export interface MyNumbers {
  mobile: string | null;
  phone: string | null;
  office_phone: string | null;
}

/** Summary of current consumption this month. */
export interface Consumption extends Identifiable {
  /** Primary phone number. */
  number: string;
  /** Subscription name translations. */
  name: { [locale: string]: string };
  /** Data usage in kB.
   * The `mod` property defines how many bytes go into a kB.
   * So usage in MB is `used / mod`.
   */
  data: ConsumptionItem & { mod: number };
  /** Combined SMS and MMS usage. */
  message: ConsumptionItem & Unlimited;
  /** Voice usage. */
  voice: ConsumptionItem & Unlimited;
}

export interface Unlimited {
  /** Mark the item as unlimited, e.g. market-speak for pretty high limit. */
  unlimited: boolean;
}

export interface ConsumptionItem {
  /** Consumption limit. Set to EULA limit if `unlimited` is true. */
  amount: number;
  /** Current consumption. */
  used: number;
}

export interface Voicemail extends Duration {
  /** Sorage file name. */
  filename: string;
  /** Caller number. */
  origin: string;
}

export interface VoicemailData extends Voicemail {
  dataurl: string;
}

export interface CRMCurrency {
  name: string;
  code: string;
  symbol: string;
  symbol_first: boolean;
}

export interface CallHistorySummary extends Pagination {
  items: CallHistorySummaryItem[];
  currency: CRMCurrency;
}

export interface CallHistory extends Pagination {
  /** Call history item data. */
  items: CallHistoryItem[];
  /** Customers in data. Indexed by id. */
  customer: {
    [key: string]: CallHistoryCustomer;
  };
  /** Destinations in data. Indexed by id. */
  destination: {
    [key: string]: CallHistoryDestination;
  };
  /** Subscriptions in data. Indexed by id. */
  subscription: {
    [key: string]: CallHistorySubscription;
  };
  /** Systems in data. Indexed by id. */
  system: {
    [key: string]: CallHistorySystem,
  };
}

export interface CallHistoryDestination {
  description: string;
  type: CallHistoryType[];
}

export interface CallHistorySystem {
  name: string;
  type: string;
}

export interface CallHistorySummaryItem {
  date: string;
  events: number;
  units: number;
  units_voice: number;
  units_data: number;
  cost: number;
}

export interface CallHistorySubscription extends Identifiable {
  name: string;
  /** Numeric */
  system: string;
  number: string;
  /** Numeric */
  number_count: string;
}

export interface CallHistoryCustomer extends Identifiable {
  name: string;
  deleted: boolean;
  /** Numeric */
  custno: string;
  regnumber?: string;
  responsible_seller?: any;
  created_date: string;
  deleted_date: string;
  status?: string;
  type: string;
  customer_group: {
    /** Numeric */
    id: string;
    name: string;
  };
  credit_limit: number;
  currency: CRMCurrency;
}

export interface CallHistoryItem extends Identifiable {
  /** Item timestamp. */
  timestamp: string;
  /** Item system. */
  system: number;
  /** Item destinations. */
  destination: number[];
  /** Item customer. */
  customer: number;
  /** Item subscription. */
  subscription: number;
  billing_number: string;
  calling_number: string;
  called_number: string;
  units: number;
  charged_units: number;
  unit_cost: number;
  event_cost: number;
  total_cost: number;
  roaming?: {
    country: string;
    network: string;
  };
  /** Possible recording */
  recording?: {
    /** Recording provider. Only `e` for Enswitch currently. */
    type: 'e';
    server: string;
    uniqueid: string;
  };
}

export interface CallHistoryPostBody {
  /** Offset from first record, used for paging. Default 0. */
  offset?: number;
  /** Maximum of items to return. Default 100.*/
  limit?: number;
  /** Field to sort by. Default 'timestamp'. */
  sort?: CallHistorySort;
  /** Sort ascending. Default false. */
  sortAsc?: boolean;
  /** Only fetch items after this date. Format `YYYY-MM-DD hh:mm:ss`. */
  start?: string;
  /** Only fetch items before this date. Format `YYYY-MM-DD hh:mm:ss`. */
  end?: string;
  /** Filter on roaming. Default both. */
  roaming?: 'no-roaming' | 'only-roaming' | 'both';
  /** Filter on rated status. Default only rated. */
  rated?: 'no-rated' | 'only-rated' | 'both';
  /** Filter on subscriptions. Default all. */
  subscription?: number[];
  /** Filter on destination. Default all. */
  destination?: number[];
  /** Filter on system. Default all. */
  system?: number[];
  /** Filter on direction. Default both. */
  direction?: Array<'in' | 'out' | 'internal'>;
  /** Filter on type. Default all. */
  type?: CallHistoryType[];
  /** Show recordings for calls */
  rec_info?: boolean;

}

/**
 * Legal types for call history destinations.
 *
 * The following combinations are used:
 *
 * `content` - Betalsamtal/betalsms
 *
 * `recording` - Inspelat samtal
 *
 * `data` - Datatrafik
 * `data,roaming` - Datatrafik roaming utanför EU
 * `data,roaming,region_eu` - Datatrafik roaming inom EU/EES
 *
 * `message,mms`- Utgående MMS
 * `message,sms`- Utgående SMS till sverige
 * `message,sms,international` - Utgående SMS till internationellt nummer
 * `message,sms,terminated` - Mottaget SMS
 *
 * `message,roaming,mms` - Utgående MMS roaming utanför EU
 * `message,roaming,sms` - Utgående SMS roaming utanför EU
 * `message,roaming,sms,terminated` - Mottaget SMS roaming utanför EU
 * `message,roaming,sms,region_eu` - Utgående SMS roaming inom EU/EES
 * `message,roaming,sms,terminated,region_eu` - Inkommande SMS roaming inom EU/EES
 *
 * `voice` - Utgående samtal
 * `voice,proper` - Utgående samtal till fastnät
 * `voice,mobile` - Utgående samtal till mobilnummer
 * `voice,pay_number` - Utgående samtal till betalnummer (trafikavgift)
 * `voice,terminated` - Inkommande samtal
 * `voice,international` - Utgående samtal till utland
 * `voice,international,proper` - Utgående samtal till utland (fastnät)
 * `voice,international,mobile` - Utgående samtal till utland (mobil)
 *
 * `voice,roaming` - Utgående samtal roaming utanför EU
 * `voice,roaming,terminated` - Inkommande samtal roaming utanför EU
 * `voice,roaming,region_eu` - Utgående samtal roaming inom EU/EES
 * `voice,roaming,terminated,region_eu` - Inkommande samtal roaming inom EU/EES
 */
type CallHistoryType
  = 'voice'
  | 'data'
  | 'message'
  | 'content'
  | 'recording'
  | 'roaming'
  | 'region_eu'
  | 'sms'
  | 'mms'
  | 'international'
  | 'terminated'
  | 'proper'
  | 'mobile'
  | 'pay_number';

type CallHistorySort
  = 'timestamp'
  | 'id'
  | 'system'
  | 'customer'
  | 'subscription'
  | 'billing_number'
  | 'calling_number'
  | 'called_number'
  | 'units'
  | 'unit_cost'
  | 'event_cost'
  | 'total_cost';

export interface Invoice {
  id: number;
  custno: number;
  invoiceNumber: string;
  invoiceDate: Date;
  dueDate: Date;
  currency: string;
  amount: number;
  amountFormatted: string;
  vatAmount: number;
  vatAmountFormatted: string;
  rounding: number;
  roundingFormatted: string;
  toPay: number;
  toPayFormatted: string;
  billingAddress: AddressSummaryItem;
  address: InvoiceAddress;
  locked: boolean;
  status: 'sent' | 'draft' | 'ready' | 'dontsend' | 'invalidated';
  invoiceUrl: string;
  sendMethod: string;
  ocr: string;
  giroType: string;
  giroNo: string;
  credit: boolean;
  remainingPayment: number;
  remainingPaymentFormatted: string;
  summary: {
    items: any[],
    total: {
      formatted: string,
      amount: number
    }
    vat: {
      amount: number,
      formatted: string,
      rate: number
    },
    rounding: {
      amount: number,
      formatted: string
    }
    toPay: {
      amount: number,
      formatted: string
    }
  };
}

export interface AddressSummaryItem {
  id: number;
  type: 'billing' | 'postal' | 'visit';
  description?: string;
  default: boolean;
  custno_suffix?: string;
  display: AddressDisplayItem;
}

export interface AddressDisplayItem {
  name: string;
  lines: string[];
  city: string;
  zipcode: string;
  state?: string;
  country: string;
  /** May be empty if country not supported. */
  country_name?: string;
}

export interface InvoiceAddress {
  regnumber: string;
  name: string;
  address1: string;
  address2?: string;
  zipcode: string;
  city: string;
  state?: string;
  country: string;
}

export interface Recording extends Call {
  /** Identification. */
  uniqueid: string;
  /** Recording expiration unix time. */
  expires: number;
  /** User favourited. */
  favourite: boolean;
  /** was the call outbound */
  outbound?: boolean;
}

export interface RecordingData extends Recording {
  /** Recording data url in base 64 */
  dataurl: string;
  /** Recording mimetype */
  mimetype: string;
}

export interface UserPresence {
  /** External number display. */
  external: { number: string, private: boolean };
  /** Number forwarding. */
  forwarding: UserPresenceItem;
  /** Forwarding on busy line. */
  busy: UserPresenceItem;
  /** Forwarding on no answer. */
  no_answer: UserPresenceItem;
}

export interface Numberwang {
  /** Null if forwarding to voicemail or unset/private. */
  number: string | null;
}

export interface UserPresenceItem extends Numberwang {
  /** If fowarding to voicemail. */
  mailbox: boolean;
}

export interface UserPresencePostBody {
  external?: { number?: string };
  forwarding?: UserPresenceItem;
  busy?: UserPresenceItem;
  no_answer?: UserPresenceItem;
}

export type Relative<T> = T & {
  outbound: boolean;
  co_number: string;
};

export interface Call extends Duration, Roaming {
  /** Number making the call. */
  origin: string;
  /** Number receiving the call. */
  destination: string;
  /** Possible recording info */
  recording?: CallRecording;
}

export interface Roaming {
  /** Possibly roaming */
  roaming?: {
    country: string;
    network: string;
    eu: boolean;
  };
}

export interface CallRecording {
  type: 'e';
  server: string;
  uniqueid: string;
}

export interface Message extends Roaming {
  /** Number sending the message. */
  origin: string;
  /** Number receiveing the message. */
  destination: string;
  timestamp: number;
  mms: boolean;
}

export interface EventHistory extends Numbers {
  calls: Relative<Call>[];
  messages: Relative<Message>[];
  data: number;
}

export interface Reference extends Identifiable {
  name: string;
  icon?: string;
}

export interface ReferenceStatus {
  id?: number;
  reference: Reference;
  numbers: string[];
  start: number;
  end: number;
  subject: string,
  description: string,
  graph_id: string,
  states: string[];
  started: boolean;
  deleted: boolean;
}

export interface Product extends Identifiable {
  name: string;
  type: ProductType[];
  price: number;
  tax_amount: number;
  currency: string;
  valid_for: ProductContext[];
  system: number;
}

export type ProductType
  = 'once'
  | 'monthly'
  | 'invoice'
  | 'minideb'
  | 'funded'
  | 'maxcost'
  | 'flatrate'
  | 'callcredit'
  | 'data_nocost'
  | 'provision'
  | 'product_markup'
  | 'per_customer'
  | 'per_sim'
  | 'per_number'
  | 'per_active_number'
  | 'per_cust_recording'
  | 'per_object';

export type ProductContext = 'subscription' | 'customer';

export type NamedNumber = {
  name: string,
  number: string
};

export type ContactEmail = {
  id: number|null,
  description: string,
  address: string
}

export type ContactPhone = {
  id: number|null,
  description: string,
  forwarding_option: boolean,
  number: string
}

export enum ContactType {
  CUSTOM,
  COMPANY,
  TEAMS
}

export type Contact = {
  id: number|null,
  first_name: string,
  last_name: string,
  type: ContactType,
  phones: ContactPhone[],
  emails: ContactEmail[]
}

export type NumberAliasMap = {
  [key: string]: string
}

export type Theme = {
  found: true,
  name: string,            // name of app
  help_link: string,       // url to help page
  mainColor: string,       //6 digit hex color code example "#5FE284"
  mainBgTextColor: string, //6 digit hex color code example "#649E68"
  favicon: string,         // data uri for favicon
  login_header: string,    // data uri for svg image shown on login screen
  menu_header: string,     // data uri for svg image shown at top of menu
  incoming_color: string,
  outgoing_color: string
} | {found: false};

export interface SubscriptionSummary extends Identifiable {
  primary_number: string;
  name: string;
  /** System id */
  system: number;
  /** True if this is your subscription */
  own: boolean;
}

export interface Subscription extends Identifiable {
  primary_number: string;
  name: string;
  /** True if this is your subscription */
  own: boolean;
  /** Unix time */
  start: number;
  /** Unix time */
  end?: number;
  numbers: string[];
  sim: {
    id: number;
    icc: string;
    imsi: string;
    pin: string;
    puk: string;
  };
}

export interface AppUser extends Identifiable {
  display_name: string;
  username: string;
  subscription?: SubscriptionSummary;
  references: ReferenceStatus[];
  in_call: boolean;
  chat_notifications: number;
}

export interface Chat extends Identifiable, SoftDelete {
  /** Message body */
  body: string;
  /** Unix time of message send */
  sent_at: number;
  /** Unix time of message read if applicable */
  read_at?: number;
}

export interface Chats {
  sent: Chat[];
  received: Chat[];
  /** ID of last message marked as read. */
  lastRead: number;
}

export interface Info {
  id: number;
  crm_id: number;
  customer: any;
  first_name: string;
  last_name: string;
  subscription_id?: number;
  recordgroup?: number;
  mailbox?: string;
  primary_number: string;
}

export interface PermissionGroup {
  id: number;
  name: string;
}

export type Discount = {
  type: 'setup',
  amount: number,
  amount_ex_vat: number
} | {
  type: 'monthly',
  months: number,
  amount: number,
  amount_ex_vat: number
}

export type SignupTemplate = {
  id: number,
  name: string,
  system_id: number,
  system_is_mobile: boolean,
  currency: string,
  cost_setup: number,
  cost_setup_ex_vat: number,
  cost_monthly: number,
  cost_monthly_ex_vat: number,
  cost_invoice: number,
  cost_invoice_ex_vat: number,
  cost_monthly_minimum: number,
  cost_monthly_minimum_ex_vat: number,
  call_credit: number,
  call_credit_ex_vat: number,
  binding: number,
  discounts: Array<Discount>,
  included: {
    data: number, // megabyte
    voice: number, //minuter
    sms: number, // antal
    mms: number // antal
  }
}

export interface SignupSim {
  id: number,
  icc: string,
  imsi: string,
  last_msisdn: string
}

export interface SignupNumber {
  id: number,
  number: string
}

export interface ProductBinding {
  id: number,
  name: string,
  start: string // datestring format "YYYY-MM-DD HH:MM:SS"
  end: string | null // datestring format "YYYY-MM-DD HH:MM:SS"
}

export interface SubscriptionBinding {
  end_date: string | null, // datestring format "YYYY-MM-DD HH:MM:SS"
  products: ProductBinding[]
}

export type SubscriptionSim = {
  pin: string,
  puk: string,
  icc: string,
  imsi: string
}

export type EmergencyInfo = {
  country: "SE"
  location_code: number,
  name?: string
}

export type EmergencyCodeLookup = {
  [key: string]: EmergencyInfo[]
}

export type Immutable<T> = {
  readonly [K in keyof T]: Immutable<T[K]>;
}
export const LegalEmergencyInfo: Immutable<EmergencyCodeLookup> = {
  SE: [
    {location_code: 360, name: "Blekinge", country: "SE"},
    {location_code: 188, name: "Dalarna", country: "SE"},
    {location_code: 408, name: "Gotland", country: "SE"},
    {location_code: 169, name: "Gävleborg", country: "SE"},
    {location_code: 311, name: "Halland", country: "SE"},
    {location_code: 144, name: "Jämtland", country: "SE"},
    {location_code: 404, name: "Jönköping", country: "SE"},
    {location_code: 375, name: "Kalmar", country: "SE"},
    {location_code: 386, name: "Kronoberg", country: "SE"},
    {location_code: 115, name: "Norrbotten", country: "SE"},
    {location_code: 335, name: "Skåne", country: "SE"},
    {location_code: 478, name: "Stockholm", country: "SE"},
    {location_code: 436, name: "Södermanland", country: "SE"},
    {location_code: 447, name: "Uppsala", country: "SE"},
    {location_code: 240, name: "Värmland", country: "SE"},
    {location_code: 113, name: "Västerbotten", country: "SE"},
    {location_code: 155, name: "Västernorrland", country: "SE"},
    {location_code: 203, name: "Västmanland", country: "SE"},
    {location_code: 260, name: "Västra Götaland", country: "SE"},
    {location_code: 219, name: "Örebro", country: "SE"},
    {location_code: 423, name: "Östergötland", country: "SE"},
  ]
} as const;

export function emergencyInfoWithName(em: EmergencyInfo): EmergencyInfo {
  const found = LegalEmergencyInfo[em.country].find(x=>x.location_code == em.location_code)
  if(found){ return found; }
  return em;
}




export type PhoneLine = {
  username: string,
  domain: string,
  password: string,
  description: string,
  emergency_info: EmergencyInfo,
  caller_id: {
      hidden: boolean,
      external: string,
      internal: string
  },
  trunk: boolean,
  protocols: Array<"SIP" | string>,
  settings: {
      [key: string]: any
  }

}

export type SubscriptionBase = {
  id: number,
  name: string,
  start: string,
  end: string | null,
  active: boolean,
  primary_number: string,
  numbers: number,
  binding: SubscriptionBinding,
  sim: SubscriptionSim | null,
  line: PhoneLine | null
}

export type SelfAdminSubscription = SubscriptionBase & {
  system: {
      id: number,
      name: string,
      type: "fixed" | "mobile" | "external" | "sms" | "attendant_service" | "custom"
  }
  children: Array<SubscriptionBase>
};

