

























































































































import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import { EasyAppAPI } from "../../../api";
import { Utils } from "@/mixins";
import { getAssetPath } from "../utils";
enum Stage {
  REQUEST_CODE = 1,
  CODE_SENT,
  PASSWORD_RESET
}

@Component
export default class ResetPassword extends Mixins(Utils) {
  public stage: Stage = Stage.REQUEST_CODE;
  public usr: string = "";
  public new_password: string = "";
  public new_password_repeat: string = "";
  public psw_reset_code: string = "";
  public error: string | null | undefined = null;

  get logo() {
    console.log("ResetPassword get logo");
    const asset_path = getAssetPath();
    let theme = localStorage.getItem("theme");
    theme = theme ? theme : "ea";
    return `${asset_path}img/${theme}/logo.svg`;    
  }

  public isStageRequestCode() {
    return this.stage === Stage.REQUEST_CODE;
  }
  public isStageCodeSent() {
    return this.stage === Stage.CODE_SENT;
  }
  public isStagePasswordReset() {
    console.log(
      "isStagePasswordReset",
      this.stage === Stage.PASSWORD_RESET,
      this.stage,
      Stage.PASSWORD_RESET
    );
    return this.stage === Stage.PASSWORD_RESET;
  }

  public toggleResetPassword() {
    this.reset();
    this.$store.commit("resetPassword", !this.$store.getters.resetPassword);
  }

  public async submitGetCode() {
    console.log("sending reset code for user", this.usr);

    try {
      const api = new EasyAppAPI(process.env.VUE_APP_SERVER_BASE_URL!);
      const res = await api.pswResetRequestCode(this.usr);
      if (res.success) {
        this.stage = Stage.CODE_SENT;
        this.psw_reset_code = "";
      } else {
        this.error = res.message;
      }
      console.log("api.pswResetRequestCode reply", res);
    } catch (err) {
      console.error("ResetPassword.submitGetCode Got error:", err);
      this.error = (err as any).message;
    }
  }

  public getCodeDisabled() {
    const ret = this.stage !== Stage.REQUEST_CODE || this.usr === "";
    console.log("getCodeDisabled", ret, this.get_state());

    return ret;
  }

  public async submitConfirmCode() {
    console.log("submitConfirmCode", this.get_state());
    if (this.new_password === this.new_password_repeat) {
      console.log("will reset");
      try {
        const api = new EasyAppAPI(process.env.VUE_APP_SERVER_BASE_URL!);
        const res = await api.pswResetVerifyCode(
          this.usr,
          this.psw_reset_code,
          this.new_password
        );
        console.log("api.pswResetVerifyCode reply ", res);
        if (res.success) {
          this.stage = Stage.PASSWORD_RESET;
          this.psw_reset_code = "";
          this.usr = "";
          this.new_password = "";
          this.new_password_repeat = "";
        } else {
          this.error = res.message;
        }
      } catch (err) {
        console.error("ResetPassword.submitConfirmCode Got error:", err);
        this.error = err as string;
      }
    } else {
      console.log(
        "passowrd missmatch",
        this.new_password,
        this.new_password_repeat
      );
    }
  }

  public confirmCodeDisabled() {
    const ret =
      this.stage !== Stage.CODE_SENT ||
      this.psw_reset_code === "" ||
      this.new_password === "" ||
      this.new_password_repeat === "" ||
      this.new_password !== this.new_password_repeat;
    console.log("confirmCodeDisabled", ret, this.get_state());
    return ret;
  }

  private get_state() {
    return {
      stage: this.stage,
      usr: this.usr,
      new_password: this.new_password,
      new_password_repeat: this.new_password_repeat,
      psw_reset_code: this.psw_reset_code
    };
  }

  private reset() {
    this.stage = Stage.REQUEST_CODE;
    this.usr = "";
    this.new_password = "";
    this.new_password_repeat = "";
    this.psw_reset_code = "";
    this.error = null;
  }
}
