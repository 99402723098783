import { Component, Mixins } from 'vue-property-decorator';
import { parsePhoneNumber, parse, PhoneNumber } from 'libphonenumber-js';
import { Utils, Events } from '@/mixins';
import {
  Contact, ContactEmail, ContactPhone, ContactType
} from '../../../../api';

@Component({

})
export default class ContactDetails extends(Events) {
  public contact: Contact = {
    id: null,
    first_name: "",
    last_name: "",
    type: ContactType.CUSTOM,
    phones: [],
    emails: []
  };
  public processing = true;

  public created() {
    console.log("Contact details created", this.$route.params);
    this.$watch('$route.params.id', (id) => {
      console.log("id changed to ", id);
      this.load(Number(id));
    });
  }

  public mounted() {
    console.log("Contact details mounted", this.$route.params.id);
    this.load(Number(this.$route.params.id));
  }

  public async load(contact_id: number) {
    this.processing = true;
    this.api.getContact(contact_id).then(contact => {
      this.contact = contact;
      this.processing = false;
    });
  }

  public editable(contact: Contact) {
    return contact.type === ContactType.CUSTOM;
  }

  public type_name(contact: Contact) {
    switch(contact.type) {
      case ContactType.COMPANY: return "Company";
      case ContactType.CUSTOM: return "Personal";
      case ContactType.TEAMS: return "Teams";
      default: return "Unknown";
    }
  }

  public edit_contact(contact_id: number) {
    console.log("edit_contact", contact_id);
    this.$router.push({
      name: "contact-edit",
      params: {
        id: String(contact_id)
      }
    });
  }

  public delete_contact(contact_id: number) {
    console.log("delete contact", contact_id);
    this.api.deleteContact(contact_id).then(contact => {
      console.log("delete contact", contact);
      this.$router.push({
        name: "contacts-list"        
      });
    });
  }
}
