import { Component, Mixins } from 'vue-property-decorator';
import { parsePhoneNumber, parse, PhoneNumber } from 'libphonenumber-js';
import * as _ from 'lodash';
import { Utils, Events } from '@/mixins';
import {
  Contact, ContactEmail, ContactPhone, ContactType
} from '../../../../api';

const empty_contact = {
  id: null,
  first_name: "",
  last_name: "",
  type: ContactType.CUSTOM,
  phones: [],
  emails: []
};
@Component({

})
export default class ContactEdit extends(Events) {
  public contact: Contact = _.cloneDeep(empty_contact);
  public processing = true;
  public errors: string[] = [];
  public save_in_progess = false;

  public created() {
    console.log("Contact details created", this.$route.params);
    this.$watch('$route.params.id', (id) => {
      console.log("id changed to ", id);
      this.load(Number(id));
    });
  }

  public mounted() {
    console.log("Contact details mounted", this.$route.params.id);
    this.contact = _.cloneDeep(empty_contact);
    this.save_in_progess = false;
    if(Number(this.$route.params.id) > 0) {
      this.load(Number(this.$route.params.id));
    } else {
      this.processing = false;
    }
  }

  public async load(contact_id: number) {
    this.processing = true;
    this.api.getContact(contact_id).then(contact => {
      if(contact.type !== ContactType.CUSTOM) {
        this.goto_details(contact_id);
      }
      this.contact = contact;
      this.$watch("contact.first_name", this.first_name_updated);
      this.$watch("contact.last_name", this.last_name_updated);
      this.processing = false;
    });
  }

  public async save() {
    const validation = this.validate();    
    if(validation === true && !this.save_in_progess) {
      this.save_in_progess = true;
      this.api.storeContact(this.contact).then( result => {
        this.save_in_progess = false;
        console.log("contact store result", result);
        this.contact = result;
        this.goto_details(this.contact.id as number);        
      }).catch(err => {
        this.save_in_progess = false;
        console.error("got error while saving", err);
      });
    }
  }

  public async delete_phone(phone: ContactPhone, index: number) {
    if(phone.id) {
      await this.api.deleteContactPhone(phone.id);      
    }
    this.contact.phones.splice(index, 1);
  }

  public async delete_email(email: ContactEmail, index: number) {
    if(email.id) {
      await this.api.deleteContactEmail(email.id);
    }
    this.contact.emails.splice(index, 1);
  }

  public validate() {
    this.errors = [];
    if(this.contact.first_name.length > 50) {
      this.errors.push("First name is to long, maximum length is 50 charactes");
    }
    if(this.contact.last_name.length > 50) {
      this.errors.push("Last name is to long, maximum length is 50 characters");
    }
    if((this.contact.first_name + this.contact.last_name) === "") {
      this.errors.push("Both first and last name cannot be left empty");
    }
    for(const phone of this.contact.phones) {
      const num = this.internation_number(phone.number);
      if( num ) {
        phone.number = num;
      } else {
        this.errors.push("Phone number is not valid");
      }
      if(phone.description.length > 50) {
        this.errors.push("Phone description is to long, maximum length is 50 characters");
      }
    }
    for(const email of this.contact.emails) {
      if(!this.valid_email(email.address)) {
        this.errors.push("Email address is not valid");
      }
      if(email.description.length > 50) {
        this.errors.push("Email description is to long, maximum length is 50 characters");
      }
    }
    return this.errors.length === 0;
  }

  public phone_number_style_object(phone_number: string) {
    const err_obj = {
      "width": "95%",
      "border-bottom": "2px solid var(--lambda)"
    };
    const valid_obj = {
      width: "95%"
    };
    return this.internation_number(phone_number) ? valid_obj : err_obj;
  }

  public email_address_style_object(address: string) {
    const err_obj = {
      "width": "95%",
      "border-bottom": "2px solid var(--lambda)"
    };
    const valid_obj = {
      width: "95%"
    };
    return this.valid_email(address) ? valid_obj : err_obj;
  }

  public internation_number(phone_number: string) {
    try {
      const number_data = parsePhoneNumber(phone_number, "SE");
      if(number_data.isValid()) {
        return number_data.number as string;
      } else {
        return null;
      }
    } catch( err ) {
      return null;
    }
  }

  public valid_email(address: string) {
    const reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return reg.test(address);
  }

  public first_name_updated(f: string) {
    console.log("first_name_updated called", f);
    console.log("current contact", this.contact);
  }

  public first_name_lostfocus() {
    console.log("first name lost focus", this.contact.first_name);
  }

  public last_name_updated(f: string) {
    console.log("first_name_updated called", f);
    console.log("current contact", this.contact);
  }

  public add_phone() {
    this.contact.phones.push({
      id: null,
      description: "",
      number: "",
      forwarding_option: false
    });
  }

  public add_email() {
    this.contact.emails.push({
      id: null,
      description: "",
      address: ""
    });
  }

  public goto_details(contact_id: number) {
    this.$router.push({
      name: "contact-details",
      params: {
        id: String(contact_id)
      }
    });
  }

}
