











































import { Mixins, Component } from 'vue-property-decorator';
import { Utils } from '@/mixins';
import GroupPlate from '@/components/plates/GroupPlate.vue';
import { PermissionGroup } from '../../../../api';
import _ from 'lodash';

@Component({
  components: {
    GroupPlate,
  },
})
export default class PermissionsGroups extends Mixins(Utils) {
  public processing = true;
  public saving = false;

  public groups: GroupState[] = [];

  public userId = 0;

  public created() {
    this.userId = Number(this.$route.query.id);
    this.refresh();
  }

  public async refresh(newUserGroups?: PermissionGroup[]) {
    this.processing = true;

    const [groups, userGroups] = await Promise.all([
      this.api.permissionGroups(),
      newUserGroups ? Promise.resolve(newUserGroups) : this.api.permissionUserGroups(this.userId),
    ]);

    const stateGroups = groups.map(group => {
      const currentMember = userGroups.map(g => g.id).indexOf(Number(group.id)) !== -1;
      const member = currentMember;

      const stateGroup: GroupState = {
        ...group,
        member,
        currentMember,
      };
      return stateGroup;
    });

    this.groups = _.orderBy(stateGroups, 'name');

    this.processing = false;
  }

  public toggleMembership(group: GroupState) {
    group.member = !group.member;
  }

  public statusText(group: GroupState): string | null {
    const added = this.$t('ADDED').toString();
    const removed = this.$t('REMOVED').toString();
    return this.fromGroupStatus(group, added, removed);
  }

  public statusColour(group: GroupState): string | null {
    return this.fromGroupStatus(group, 'brand', 'mu');
  }

  public fromGroupStatus<T>(group: GroupState, addedValue: T, removedValue: T) {
    switch (this.groupStatus(group)) {
      case 'added':
        return addedValue;
      case 'removed':
        return removedValue;
      case 'unchanged':
        return null;
      default:
        throw new Error(`Group status invalid. Muse be either 'added', 'removed' or 'unchanged'!`);
    }
  }

  public groupStatus(group: GroupState): Status {
    const { member, currentMember } = group;

    // Selection has added the group
    if (member && !currentMember) {
      return 'added';
    }

    // Selection has removed the group
    if (!member && currentMember) {
      return 'removed';
    }

    return 'unchanged';
  }

  /** Rendered list of group names with the given status. */
  public groupsByStatus(status: Status): GroupState[] {
    const groups = this.groups.filter(
      group => this.groupStatus(group) === status
    );
    return groups;
  }

  public async handleSave() {
    const id = Number(this.$route.query.id);
    if (isNaN(id) || id === 0) {
      throw new Error(`User CRM ID must be a number!`);
    }

    const added = this.groupsByStatus('added');
    const removed = this.groupsByStatus('removed');

    this.saving = true;

    console.log('Adding', added, 'and removing', removed, 'for user', id, name);

    const newGroups = await this.api.updatePermissionUserGroups(added.map(g => g.id), removed.map(g => g.id), id);
    await this.refresh(newGroups);

    this.saving = false;
  }

  get nochange(): boolean {
    return this.groups.every(group => group.currentMember === group.member);
  }

  get addedGroups(): string {
    return this.groupsByStatus('added').map(group => group.name).join(', ');
  }

  get removedGroups(): string {
    return this.groupsByStatus('removed').map(group => group.name).join(', ');
  }

  get finalGroups(): string {
    return this.groups
      .filter(group => group.member)
      .map(group => group.name)
      .join(', ');
  }
}

type Status = 'added' | 'removed' | 'unchanged';

interface GroupState extends PermissionGroup {
  /** Pending group membership. */
  member: boolean;
  /** Current group membership. */
  currentMember: boolean;
}
