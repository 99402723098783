























import { Vue, Component } from 'vue-property-decorator';
import LinkPlate from '@/components/LinkPlate.vue';
import UserPlate from '@/components/UserPlate.vue';
import { Voicemail } from '../../../../api';
import { EasyAppApi } from '@/utils';
import VoicemailList from '@/components/lists/VoicemailList.vue';

// export interface IVoicemail {
//   filename: string;
//   length: number;
//   caller: string;
//   start: number;
//   end: number;
//   type: string;
//   extension: string;
//   pid: number;
//   pages: number;
// }

@Component({
  components: {
    LinkPlate,
    UserPlate,
    VoicemailList,
  },
})
export default class VoicemailIndex extends Vue {
  public processing = true;
  public rawVoicemails: Voicemail[] = [];

  public created() {
    this.processing = true;
    const api = new EasyAppApi(this);
    api.voicemails().then(voicemails => {
      this.rawVoicemails = voicemails;
      this.processing = false;
    });
  }

  public openListen(voicemail: Voicemail) {
    this.$router.push({ name: 'voicemail-listen', params: { name: voicemail.filename }});
  }

  public confirmDelete(voicemail: Voicemail) {
    console.error('Deleting', voicemail);
    // TODO
  }

  get voicemails() {
    const ret = this.rawVoicemails.map(voicemail => {
      const pl = `${voicemail.duration} seconds`;
      const start = new Date(voicemail.start * 1000);
      const pt = start.toLocaleString('sv-SE');
      const id = voicemail.filename;
      return {
        ...voicemail,
        id,
        prettyTime: pt,
        prettyLength: pl,
      };
    });
    ret.sort((a,b)=>b.start-a.start);
    return ret;
  }

}
