import { Component, Vue, Mixins, Watch } from 'vue-property-decorator';
import { Reference, ReferenceStatus, MyNumbers, Event } from '../../../../api';
import GenericPlate from '@/components/GenericPlate.vue';
import QueuePlate from '@/components/QueuePlate.vue';
import CheckPlate from '@/components/plates/CheckPlate.vue';
import RefLine from '@/components/references/RefLine.vue';
import { Utils } from '@/mixins';
import moment from 'moment';
import FlatPickr from 'vue-flatpickr-component';
import { Swedish as swedish } from 'flatpickr/dist/l10n/sv';
import { english } from 'flatpickr/dist/l10n/default';
import { roundNearest, FloorNearest } from '@/utils';

@Component({
  components: {
    GenericPlate,
    QueuePlate,
    CheckPlate,
    FlatPickr,
    RefLine,
  },
})
export default class UpdateReference extends Mixins(Utils) {
  public ref: ReferenceStatus | null = null;
  public errors: string[] = [];

  public created() {
    this.load();
    return;
  }

  public destroyed() {
    return;
  }

  public load() {
    this.errors = [];
    this.api.getUserRef(
      Number(this.$route.params.id)
    ).then(( ref: ReferenceStatus ) => {
      console.log(JSON.parse(JSON.stringify(ref)));
      this.ref = ref;
      this.$watch('ref.start', this.start_end_watch);
      this.$watch('ref.end', this.start_end_watch);
    }).catch(err => {
      console.error("Failed to load reference", err);
      this.errors.push("Failed to load reference");
    });
  }

  public save() {
    if(this.ref) {
      console.log("saving!");
      this.errors = [];
      this.api.updateUserRef(this.ref).then(res => {
        this.$router.push({ name: 'list-reference' });
      }).catch(err => {
        console.error("failed to update reference", err);
        this.errors.push("Failed to update reference");
      });
    }
  }

  public cancel() {
    if(this.ref && this.ref.id) {
      console.log("cancel!");
      this.errors = [];
      this.api.cancelUserRefById(this.ref.id).then(res => {
        this.$router.push({name: 'list-reference' });
      }).catch(err => {
        console.error("failed to cancel reference", err);
        this.errors.push("Failed to delete reference");
      });
    }
  }

  public start_end_watch(start: number) {
    if(this.ref) {
      if(this.ref.start < this.ref.end) {
        this.errors = [];
      } else  {
        this.errors = ['Start time must be before end time'];
      }
    }
  }

  public browserEndChange(selectedDates: Date[], dateStr: string, instance: any) {
    console.log("browserEndChange.selectedDates", selectedDates);
    console.log("browserEndChange.dateStr", dateStr);
    console.log("browserEndChange.instance", instance);
  }


  public browserStartChange(selectedDates: Date[], dateStr: string, instance: any) {
    console.log("browserStartChange.selectedDates", selectedDates);
    console.log("browserStartChange.dateStr", dateStr);
    console.log("browserStartChange.instance", instance);
  }

  public setDatetime(type: 'start' | 'end') {
    const self = this;
    if(self.ref) {
      const isStart = type === 'start';

      const options = {
        date: isStart ? moment.unix(self.ref.start || 0).toDate() : moment.unix(self.ref.end).toDate(),
        mode: 'datetime',
        okText: self.$t('Done'),
        cancelText: self.$t('Cancel'),
        todayText: self.$t('Today'),
        nowText: isStart ? self.$t('Now') : '', // Empty for no current time
        is24Hour: true,
        allowOldDates: false,
        doneButtonLabel: self.$t('Done'),
        cancelButtonLabel: self.$t('Cancel'),
        minuteInterval: 15,
        locale: self.$i18n.locale === 'en' ? 'en_us' : self.$i18n.locale === 'sv' ? 'sv_se' : 'en_us',
      };

      const onSuccess = (date: Date) => {
        if (date && self.ref) {
          if (isStart) {
            self.ref.start  = moment(date).unix();
          } else {
            self.ref.end = moment(date).unix();
          }
        }
      };

      const onError = (err: any) => {
        console.error(err);
      };

      // Launch native datepicker
      (window as any).plugins.datePicker.show(options, onSuccess, onError);
    }
  }

  get valid_date_range() {
    if(this.ref) {
      return this.ref.start < this.ref.end;
    }
    return false;
  }

  get start() {
    if(this.ref && this.ref.start) {
      return moment.unix(this.ref.start).format('YYYY-MM-DD HH:mm');
    }
    return moment.unix(0).format('YYYY-MM-DD HH:mm');
  }

  set start(date_str: string) {
    console.log("date", date_str);
    if(this.ref) {
      this.ref.start = moment(date_str).unix();
    }
  }

  get end() {
    if(this.ref && this.ref.end) {
      return moment.unix(this.ref.end).format('YYYY-MM-DD HH:mm');
    }
    return moment.unix(0).format('YYYY-MM-DD HH:mm');
  }

  set end(date_str: string) {
    console.log("date", date_str);
    if(this.ref) {
      this.ref.end = moment(date_str).unix();
    }
  }

  get flatConfig() {
    const locale = this.$i18n.locale === 'sv' ? swedish : english;
    return {
      enableTime: true,
      dateFormat: 'Y-m-d H:i',
      time_24hr: true,
      minuteIncrement: 15,
      weekNumbers: true,
      locale,
    };
  }
}
