<template>
  <div class="[ o-gutter-bottom-md o-gutter-bottom-lg@lg ]">
    <div v-if="processing" class="o-gutter__item">{{ $t('Loading...') }}</div>
    <div v-else>

      <div class="o-gutter__item" style="padding-bottom:10px;">
        <div class="[ o-grid o-grid--center ] [ o-gutter-left-2xl o-gutter-bottom-md ]">
          <div class="o-grid__item o-gutter__item u-width-1/2">
            <label class="[ c-text-input c-text-input--md ]">
              <input type="text"
                class="[ c-text-input__input c-text-input__input--border ]"
                v-model="search_string"
                :placeholder="$t('Search name')" />
            </label>
          </div>
          <div class="o-grid__item o-gutter__item u-width-1/2">
            <button
              class="c-btn c-btn--sm"
              @click="edit_contact(0)"
            >
              {{$t("Create contact")}}
            </button>
          </div>
        </div>
      </div>


      <div class="[ u-bleed-sides-sm u-bleed-sides-md@xl ]" style="padding-bottom: 10px;">
        <hr class="u-push-bottom-clear">
      </div>

      <div class="o-gutter-bottom-sm u-soft-top">
        <div class="o-gutter__item">
            <table class="table u-text-omicron">
              <thead>
                <th>{{$t('Name')}}</th>
                <th>{{$t('Type')}}</th>
              </thead>
              <tbody>
                <tr v-for="contact in filtered_contacts" :key="contact.id" style="cursor:pointer;" @click="goto_details(contact.id)">
                  <td>{{`${contact.first_name} ${contact.last_name}`}}</td>
                  <td>{{$t(type_name(contact))}}</td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </div>
  </div>


</template>
<script>
import s from "./contacts_list";
export default s;
</script>

<style scoped>

</style>