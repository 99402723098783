import { Component, Mixins } from "vue-property-decorator";
import { Utils, Events } from "@/mixins";
import SelectPlate from "@/components/SelectPlate.vue";
import {
  UserPresence,
  UserPresenceItem,
  UserPresencePostBody,
  Info,
  NamedNumber,
  NumberAliasMap
} from "../../../../api";
import { parsePhoneNumber, PhoneNumber, formatNumber } from "libphonenumber-js";
import { Option, Some, None } from "tsoption";

@Component({
  components: {
    SelectPlate
  }
})
export default class Presence extends Mixins(Events) {
  public info: Info | null = null;
  public external: PresenceItem | null = null;
  public forwarding: PresenceItem | null = null;
  public busy: PresenceItem | null = null;
  public noAnswer: PresenceItem | null = null;
  public presentation_numbers: NamedNumber[] = [];

  public custom_numbers: NamedNumber[] = [];
  public custom_number: string = "";

  public protectedNumber = "";
  public noForwarding = "";
  public voicemail = "";

  public plainNumber = "";
  public number = "";

  public presence_available = false;

  public processing = true;

  public created() {
    this.protectedNumber = this.$t("Protected number").toString();
    this.noForwarding = this.$t("No forwarding").toString();
    this.voicemail = this.$t("Voicemail").toString();
    this.custom_numbers = [];

    this.external = {
      config: {
        title: this.$t("Display number").toString(),
        handler: this.externalSelect
      }
    };
    this.forwarding = {
      config: {
        title: this.$t("Forwarding").toString(),
        handler: this.presenceSelect("forwarding")
      }
    };
    this.busy = {
      config: {
        title: this.$t("Forwarding on busy").toString(),
        handler: this.presenceSelect("busy")
      }
    };
    this.noAnswer = {
      config: {
        title: this.$t("Forwarding on no answer").toString(),
        handler: this.presenceSelect("noAnswer", "no_answer")
      }
    };
    Promise.all([
      this.api.getPresence(),
      this.api.info(),
      this.api.presentationNumbers(),
      this.api.getForwardingNumbers()
    ]).then(([o, i, p, f]: any[]) => {
      this.info = i;
      console.log("this.info =", this.info);
      this.presentation_numbers = p.map((e: NamedNumber) => ({
        name: e.name,
        number: this.formatNumber(e.number)
      }));
      console.log("forwarding numbers from server",f);
      this.custom_numbers = Object.entries(f).map(
        ([key, val], index, arr) => ({name: val as string, number: this.formatNumber(key)})
      );
      console.log("custom_numbers =", this.custom_numbers);
      const opt = Option.of<UserPresence>(o.value);
      if (opt.nonEmpty()) {
        const presence = opt.get();
        console.log("precense = ", presence);
        this.plainNumber = this.info ? this.info.primary_number : "?";
        this.number = this.formatNumber(this.plainNumber);

        // console.log('Done rocessing');
        // // this.$set(this.external!, 'state', this.genExternal(presence));
        const externalState = this.genExternal(presence);
        this.external!.state = externalState;

        this.forwarding!.state = this.genPresence(
          presence.forwarding,
          this.$t("Forwarding").toString()
        );
        this.busy!.state = this.genPresence(
          presence.busy,
          this.$t("Forward at occupied").toString()
        );
        this.noAnswer!.state = this.genPresence(
          presence.no_answer,
          this.$t("Forward at no answer").toString()
        );

        this.presence_available = true;
        this.processing = false;
        // this.$forceUpdate();
      } else {
        this.presence_available = false;
        this.processing = false;
      }
    });
  }

  get dropdowns() {
    return [this.forwarding, this.busy, this.noAnswer];
  }

  public async externalSelect(selection: string) {
    console.log("externalSelect", selection);
    let pnum: PhoneNumber | null = null;
    if (selection !== this.protectedNumber) {
      pnum = parsePhoneNumber(selection, "SE");
    }
    this.external!.state!.processing = true;

    this.$forceUpdate();

    const body: UserPresencePostBody = {
      external: {
        number:
          selection === this.protectedNumber
            ? undefined
            : `0${pnum ? pnum.nationalNumber : ""}`
      }
    };
    try {
      const ret = await this.api.setPresence(body);
      console.log("api.setPresence arg, res", body, ret);
      this.external!.state!.number = selection;
    } catch (e) {
      console.error(e);
    }

    this.external!.state!.processing = false;
    this.$forceUpdate();
  }

  public presenceSelect(itemKey: string, jsonKey?: string) {
    return async (selection: string) => {
      console.log("presenceSelect", itemKey, jsonKey, selection);
      const item = (this as any)[itemKey] as PresenceItem;

      item.state!.processing = true;
      this.$forceUpdate();

      const key = jsonKey || itemKey;
      const body = this.postBodyFromSelection(key, selection);
      try {
        console.log("api.setPresence arg", body);
        const ret = await this.api.setPresence(body);
        console.log("api.setPresence res", ret);
        item.state!.number = selection;
      } catch (e) {
        console.error(e);
      }

      item.state!.processing = false;
      this.$forceUpdate();
    };
  }

  public postBodyFromSelection(itemKey: string, selection: string) {
    let item: any;
    if (selection === this.voicemail) {
      item = {
        number: null,
        mailbox: true
      };
    } else if (selection === this.noForwarding) {
      item = {
        number: null,
        mailbox: false
      };
    } else {
      console.log("postBodyFromSelection", itemKey, selection);
      const pnum = parsePhoneNumber(selection, "SE");
      console.log("postBodyFromSelection.pnum", pnum);
      item = {
        number: `0${pnum.nationalNumber}`,
        mailbox: false
      };
    }

    const body: UserPresencePostBody = {
      [itemKey]: item
    };
    return body;
  }

  public genExternal(p: UserPresence): PresenceState {
    const numbers = [this.number, this.protectedNumber];
    return {
      numbers,
      number: p.external.private
        ? this.protectedNumber
        : this.formatNumber(p.external.number),
      processing: false
    };
  }

  public genPresence(p: UserPresenceItem, title: string): PresenceState {
    console.log("genPresence", p, title);
    const comp_num = (item: UserPresenceItem) => {
      if (item.mailbox) {
        return this.voicemail;
      } else {
        if (item.number) {
          return this.formatNumber(item.number);
        } else {
          return this.noForwarding;
        }
      }
    };
    const numbers = [this.noForwarding, this.voicemail];
    return {
      numbers,
      number: comp_num(p),
      processing: false
    };
  }  
}

export interface PresenceConfig {
  title: string;
  handler: (s: string) => void;
}

export interface PresenceState {
  numbers: string[];
  number: string;
  processing: boolean;
}

export interface PresenceItem {
  config: PresenceConfig;
  state?: PresenceState;
}
