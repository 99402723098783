


























































































import { Component, Mixins } from "vue-property-decorator";
import BarDataPlate from "@/components/BarDataPlate.vue";
import { Consumption as EAConsumption } from "../../../api";
import { EasyAppApi } from "@/utils";
import pb from "pretty-bytes";
import { Utils } from "@/mixins";
import formatd from "format-duration";
import moment from 'moment';
import { LocaleMessages } from 'vue-i18n';

@Component({
  components: {
    BarDataPlate
  }
})
export default class Consumption extends Mixins(Utils) {
  public updated_at: Date|null = null;
  public consumption: EAConsumption | null = null;
  public processing = true;

  public created() {
    const api = new EasyAppApi(this);
    api.consumption().then(cs => {
      console.log("consumption = ", cs);
      this.updated_at = new Date();
      this.consumption = cs;
      this.processing = false;
    });
  }

  public prettyB(bytes: number) {
    return pb(bytes, { locale: this.$i18n.locale });
  }

  get prettyNumber() {
    if (!this.consumption) {
      return "";
    }
    return this.formatNumber(this.consumption.number);
  }

  get subscriptionName() {
    if (!this.consumption) {
      return "";
    }

    const name =
      this.consumption.name[this.$i18n.locale] || this.consumption.name.sv;
    return name;
  }

  get voiceDuration() {
    if (!this.consumption) {
      return "";
    }
    const used = this.consumption.voice.used;

    return this.formatDuration(used * 60 * 1000);
  }

  get updatedAt(): string | LocaleMessages {
    if( this.updated_at ) {
      return moment(this.updated_at).format("YYYY-MM-DD HH:mm:ss");
    }
    return this.$t("Unknown");
  }
}
