import { Mixins, Component, Vue } from 'vue-property-decorator';
import { Utils } from '@/mixins';
import AccountPlate from '@/components/plates/AccountPlate.vue';
import GraphLogin from '@/components/GraphLogin.vue';
import _ from 'lodash';
import { Globals } from '../../globals';
import { Info, SubscriptionSummary, NamedNumber } from '../../../../api';
import { parsePhoneNumber } from 'libphonenumber-js';
import { loadTheme } from '../../utils';

@Component({
  components: {
    AccountPlate,
    GraphLogin,
  },
})
export default class SettingsScreen extends Mixins(Utils) {
  public show_custom_numbers = false;
  public show_presentation_numbers = false;
  public info: Info | null = null;
  public subscriptions: SubscriptionSummary[] = [];
  public presentation_numbers: NamedNumber[] = [];
  public custom_numbers: Array<{ name: string, number: string }> = [];
  public custom_number: string = "";
  public custom_name: string = "";
  public theme: string = "ea";


  public created() {
    console.log("created called");
    Promise.all([
      this.api.info(),
      this.api.subscriptions(),
      this.api.presentationNumbers()
    ]).then(([i, s, p]) => {
      this.info = i;
      this.subscriptions = s;
      console.log("settings.screen.info =", this.info);
      console.log("settings.screen.subscriptions", this.subscriptions);
      this.presentation_numbers = p;
    });
  }

  public destroyed() {
    console.log("destroyed called");
  }


  public getLanguageString() {
    const language = this.$i18n.locale;

    // No translation for easier change of language for the user.

    switch (language) {
      case "sv":
        return "Svenska";
      case "en":
        return "English";
      default:
        console.log("ERROR: Language code not recognized.");
        return "";
    }
  }

  public changeLanguage() {
    const locale = this.$store.getters.locale;
    this.$store.commit("setLocale", locale === 'sv' ? 'en' : 'sv');
    this.$i18n.locale = this.$store.getters.locale;
  }


  public togglePresentationNumbers() {    
    this.show_presentation_numbers = !this.show_presentation_numbers;
  }
  public toggleAdminMode() {
    this.$store.commit('toggleAdminMode');
  }
  public isPresentationNumber(sub: SubscriptionSummary) {
    const pnum = parsePhoneNumber(sub.primary_number, 'SE');
    return this.presentation_numbers.findIndex(e => e.number === (pnum.number as string)) >= 0;
  }
  public async add_presentation_number(sub: SubscriptionSummary) {
    const pnum = parsePhoneNumber(sub.primary_number, 'SE');
    if (this.presentation_numbers.findIndex(e => e.number === (pnum.number as string)) < 0) {
      console.log("adding presentation number", pnum.number);
      this.api.addPresentationNumbers([{ name: sub.name, number: pnum.number as string }]).then(pres_nums => {
        console.log("addPresentationNumbers responded", pres_nums);
        this.presentation_numbers = pres_nums;
      });
    }

  }
  public async remove_presentation_number(sub: SubscriptionSummary) {
    const pnum = parsePhoneNumber(sub.primary_number, 'SE');
    const i = this.presentation_numbers.findIndex(e => e.number === (pnum.number as string));
    if (i >= 0) {
      console.log("removing presentation number", pnum.number);
      this.api.removePresentationNumbers([{ name: sub.name, number: pnum.number as string }]).then(pres_nums => {
        console.log("removePresentationNumbers responded", pres_nums);
        this.presentation_numbers = pres_nums;
      });
    }
  }

  public openHelpPage() {
    const theme = loadTheme();
    if(theme && theme.found) {
      return this.openExternal(theme.help_link);
    } else {
      return this.openExternal(Globals.urls.help);
    }
  }
}
