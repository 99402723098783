








































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {checkPermissions, PERMISSIONS} from '../../../api';

@Component
export default class BarDataPlate extends Vue {

  @Prop()
  private title!: string;

  @Prop()
  private max!: number;

  @Prop()
  private consumed!: number;

  @Prop()
  private displayConsumed!: string;

  @Prop()
  private displayMax!: string;

  @Prop()
  private units!: string;

  @Prop()
  private lastUpdated!: Date;

  @Prop({ required: false })
  private barTitle!: string;

  @Prop({ default: 0 })
  private min!: number;

  @Prop({ default: true })
  private displayEnd!: boolean;

  get barWidth() {
    const totalLength = this.max - this.min;
    let fraction = totalLength === 0 ? 0 : (this.consumed - this.min) / (this.max - this.min);

    if (!this.displayEnd && fraction > 0.8) {
        fraction = 0.8;
    }

    return `width: ${fraction * 100}%;`;
  }
  get prettyLastUpdated() {
    const d = this.lastUpdated;
    return d.toLocaleString();
  }

  public clickHandler() {
      const perms = this.$store.getters.perms || [];
      if(checkPermissions(perms, [PERMISSIONS.USER.EVENT_HISTORY])) {
          this.$router.push({ name: 'event-history-top' });
      } else {
          console.log("BarDataPlate: user lacks event history permission skipping redirect");
      }

  }

}
