import { Component, Vue, Mixins } from "vue-property-decorator";
import LinkPlate from "@/components/LinkPlate.vue";
import RefLine from "@/components/references/RefLine.vue";
import { localize } from "@/translation";
import { interfaces } from "mocha";
import { EasyAppApi } from "@/utils";
import { Reference, ReferenceStatus } from "../../../../api";
import { AxiosResponse } from "axios";
import { Utils } from "@/mixins";
import moment from "moment";

export interface ListReference {
  name: string;
  icon?: string;
  id: number;
  title: string;
}

@Component({
  components: {
    LinkPlate,
    RefLine
  }
})
export default class SelectReference extends Mixins(Utils) {
  public reloading: string = "";
  private references: Reference[] = [];
  private userRefs: ReferenceStatus[] = [];
  private processing: boolean = true;

  private deleting: boolean = false;
  private refresh_interval: NodeJS.Timeout | null = null;

  public created() {
    console.log("SelectReference created");
    this.refresh();
    this.refresh_interval = setInterval(async () => {
      const start = Date.now();
      console.log("refreshing SelectReference");
      await this.refresh();
      console.log(
        `refreshing SelectReference done took ${Date.now() - start} ms`
      );
    }, 1000 * 30);
  }

  public destroyed() {
    console.log("SelectReference destroyed");
    if (this.refresh_interval != null) {
      console.log("clearing select reference interval");
      clearInterval(this.refresh_interval);
      this.refresh_interval = null;
    }
  }

  public async refresh() {
    this.reloading = "refreshing";
    this.processing = true;
    const [references, userRefs] = await Promise.all([
      this.api.references(),
      this.api.checkUserRef()
    ]);
    console.log(references, userRefs);
    this.references = references;
    this.userRefs = userRefs;
    this.processing = false;
    this.reloading = "";
  }

  public async handleRemove() {
    this.deleting = true;
    const userRefs = await this.api.cancelUserRef();
    await this.refresh();
    this.deleting = false;
  }

  get userRef() {
    const refs = this.userRefs.filter(ref => (ref.start || 0)*1000 > new Date().getDate());
    if (refs.length === 0) {
      return null;
    }
    refs.sort((a,b) => (a.start||0) < (b.start||0) ? 1 : -1);

    return this.userRefs[0];
  }

  get prettyEnd() {
    if (!this.userRef) {
      return null;
    }

    return moment.unix(this.userRef.end).format("LLL");
  }
}
