import { Vue, Component, Mixins } from 'vue-property-decorator';
import { EasyAppApi } from './utils';
import { parsePhoneNumber, parse, PhoneNumber } from 'libphonenumber-js';
import { Invoice, PERMISSIONS } from '../../api';

import * as moment from 'moment';
import mdf from 'moment-duration-format';
import pb from 'pretty-bytes';
import _ from 'lodash';
import util from 'util';
import {switchTheme} from './utils';

mdf(moment);

@Component
export class Utils extends Vue {
  /** Server API instance. */
  public api!: EasyAppApi;
  public P = PERMISSIONS;

  public created() {
    this.api = new EasyAppApi(this);
  }

  public mounted() {
    switchTheme(this);
  }

  public has(perm: string) {
    const auth = this.$store.getters.perms.indexOf(perm) !== -1;
    return auth;
  }

  public formatDecimal(n: number) {
    const locale = this.$i18n.locale === 'sv' ? 'sv-SE' : 'en-GB';
    return n.toLocaleString(locale, {
      style: 'decimal',
      useGrouping: true,
    });
  }

  /** Format a duration in ms to human readable string. */
  public formatDuration(duration: number, userConfig?: FormatDurationConfig) {
    const S_IN_MS = 1000;
    const M_IN_MS = 60 * S_IN_MS;
    const H_IN_MS = 60 * M_IN_MS;

    let config: FormatDurationConfig = {
      short: false,
    };

    // Defaults
    if (userConfig) {
      config = Object.assign(config, userConfig);
    }

    const hours = Math.floor(duration / H_IN_MS);
    const minutes = Math.floor((duration - H_IN_MS * hours) / M_IN_MS);
    const seconds = Math.floor((duration - H_IN_MS * hours - M_IN_MS * minutes) / S_IN_MS);

    if (config.short) {
      if (hours > 0) {
        return util.format(
          "%s:%s:%s",
          String(hours).padStart(2, '0'),
          String(minutes).padStart(2, '0'),
          String(seconds).padStart(2, '0')
        );
      } else if (minutes > 0) {
        return util.format(
          "%s:%s",
          String(minutes).padStart(2, '0'),
          String(seconds).padStart(2, '0')
        );
      } else {
        return util.format(
          "00:%s", String(seconds).padStart(2, '0')
        );
      }
    } else {
      const parts: string[] = [];
      const hourUnits = (hs: number) => config.short ? 'h' : this.$t(hs === 1 ? 'Hour' : 'Hours');
      const minuteUnits = (ms: number) => config.short ? 'm' : this.$t(ms === 1 ? 'Minute' : 'Minutes');
      const secondUnits = (ss: number) => config.short ? 's' : this.$t(ss === 1 ? 'Second' : 'Seconds');
      if (hours > 0) {
        parts.push(`${hours} ${hourUnits(hours)}`);
      }
      if (minutes > 0) {
        parts.push(`${minutes} ${minuteUnits(minutes)}`);
      }
      if (seconds > 0) {
        parts.push(`${seconds} ${secondUnits(seconds)}`);
      }
      return parts.join(' ');
    }
  }

  public formatHHMMfromSecs(seconds: number) {
    return moment.utc(seconds * 1000).format('HH:ss');
  }

  public formatDateFromUnix(unix: number) {
    return moment.unix(unix).format("YYYY-MM-DD");
  }

  public formatDateTimeFromUnix(unix: number) {
    return moment.unix(unix).format("YYYY-MM-DD HH:mm:ss");
  }

  public formatTimeFromUnix(unix: number) {
    return moment.unix(unix).format('HH:mm');
  }

  /** Parses and formats Swedish number Swedishly. */
  public formatNumber(phoneNumber: string) {
    let numberData: PhoneNumber;
    try {
      numberData = parsePhoneNumber(phoneNumber, 'SE');
    } catch (e) {
      return this.$t('Protected number').toString();
    }

    const swedish = isSwedishNumber(phoneNumber);

    if (swedish) {
      return numberData.formatNational();
    } else {
      return numberData.formatInternational();
    }
  }

  public formatPrice(price: number, currency: string) {
    // TODO Handle more currenies and countries. formatDecimal() and others did not work as intended / Erik
    const val = (price).toFixed(2).replace('.', ',');
    return val.replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " " + currency;
  }

  /** Opens link in system browser. */
  public openExternal(url: string) {
    if (this.isBrowser) {
      window.open(url, '_system');
    } else {
      ((cordova as any).InAppBrowser as Window).open(url, '_system', 'location=yes');
    }
  }

  /** Open the invoice PDF in the system browser. */
  public openInvoice(invoice: Invoice) {
    let url = invoice.invoiceUrl + '.pdf';

    const matches = /^https:\/\/.+\/(\S+)$/.exec(invoice.invoiceUrl);
    if (matches) {
      const name = matches[1];
      url = `https://faktura.easytelecom.se/${name}.pdf`;
    }

    this.openExternal(url);
  }

  public prettyBytes(bytes: number, mod = 1000) {
    // Number.prototype.siPrefix = function(d) {
    //   if (d === undefined) d = 1000;
    const pfxs = ['', 'k', 'M', 'G', 'T'];
    let n = bytes;
    for (const pfx of pfxs) {
      if (n >= mod * 2) {
        n /= mod;
      } else {
        return `${Math.round(n * 100) / 100} ${pfx}B`;
      }
    }
    // for (var i = 0; i < pfx.length; i++) {
    //     if (n >= d*2) n/=d;
    //     else return [Math.round(n*100)/100, pfx[i]];
    // }
    // };
  }

  get isBrowser() {
    if (!process.env.CORDOVA_PLATFORM || process.env.CORDOVA_PLATFORM === 'browser') {
      return true;
    } else {
      return false;
    }
  }
}

@Component
export class Events extends Mixins(Utils) {
  public itemSummary(item: SummaryItem) {
    const seconds = item.calls;
    const messages = item.messages;
    const data = item.data;

    let callText: string | null = null;
    if (seconds > 0) {
      const diff = moment.duration(seconds * 1000);
      const render = diff.format('hh:mm:ss', { trim: false });
      callText = `${render} ${this.$t('calls')}`;
    }

    let messageText: string | null = null;
    if (messages > 0) {
      messageText = `${messages} SMS/MMS`;
    }

    let dataText: string | null = null;
    if (data > 0) {
      dataText = pb(data * 1000, { locale: this.$i18n.locale });
    }

    const final = [];
    if (callText) { final.push(callText); }
    if (messageText) { final.push(messageText); }
    if (dataText) { final.push(dataText); }

    const result = final.join(' | ');

    return result;
  }
}

function isSwedishNumber(nr: string) {
  const i18n = /^((00)|\+)(\d\d)/;

  const result = i18n.exec(nr);

  if (!result) {
    return true;
  }

  return result[3] === '46';
}

export interface SummaryItem {
  /** Total amount of calls in seconds. */
  calls: number;
  /** Total number of messages. */
  messages: number;
  /** Total data in kB. */
  data: number;
}

export interface FormatDurationConfig {
  /** Use unit abbrevations */
  short: boolean;
}

export interface OriginDestination {
  origin: string;
  destination: string;
}

