






































import { Component, Mixins } from 'vue-property-decorator';
import LinkPlate from '@/components/LinkPlate.vue';
import InvoiceList from '@/components/InvoiceList.vue';
import EmptyPlate from '@/components/EmptyPlate.vue';
import { EasyAppApi } from '@/utils';
import { Invoice } from '../../../../api';
import { Utils } from '@/mixins';
import _ from 'lodash';
import fp from 'lodash/fp';

@Component({
  components: {
    LinkPlate,
    InvoiceList,
    EmptyPlate,
  },
})
export default class InvoicesIndex extends Mixins(Utils) {
  public processing: boolean = true;
  public invoices: Invoice[] = [];

  public created() {
    this.processing = true;
    const api = new EasyAppApi(this);
    api.invoices().then(invoices => {
      this.invoices = _.sortBy(invoices, 'dueDate');
      this.invoices.reverse();
      this.processing = false;
    });
  }

  get empty() {
    return !this.invoiceGroups || Object.keys(this.invoiceGroups).length === 0;
  }

  get latestInvoice(): Invoice | null {
    return _.head(this.invoices) || null;
  }

  get invoiceGroups(): Group[] {
    const invoices = this.invoices;

    const invoiceGroups: InvoiceGroups = {};

    invoices.forEach(invoice => {
      const date = invoice.invoiceDate;
      const year = date.getFullYear();

      const current = invoiceGroups[year] || [];
      current.push(invoice);

      invoiceGroups[year] = current;
    });
    let ret: Group[] = [];
    for(const year in invoiceGroups) {
      const invs = invoiceGroups[year];
      invs.sort((a,b)=>b.invoiceDate.getTime() - a.invoiceDate.getTime());
      ret.push({
        year: Number(year),
        invoices: invs
      });
    }
    ret = _.sortBy(ret, 'year');
    ret.reverse();
    return ret;
  }

  public openDetail(invoice: Invoice) {
      this.$router.push({ name: 'invoice-detail', params: { id: invoice.id.toString() }});
  }
}

type Group = {
  year: number,
  invoices: Invoice[]
};

interface InvoiceGroups {
  [key: number]: Invoice[];
}
