<template>
  <div class="[ o-gutter-bottom-md o-gutter-bottom-lg@lg ]">
    <div class="o-gutter__item [ u-soft-bottom-sm u-soft-bottom-md@lg ]">
      <h1 class="u-push-bottom-clear">{{ prettyTitle }}</h1>
    </div>
    <div class="o-gutter__item">      
      <div v-if="processing">{{ $t('Loading...') }}</div>
      <table v-else class="u-width-1/1 u-text-omicron u-text-md">
        <tbody>
          <tr>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm"><b>{{$t("Name")}}:</b></td>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm">{{subscription.name}}</td>
          </tr>
          <tr>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm"><b>{{$t("Primary Number")}}:</b></td>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm">{{subscription.primary_number}}</td>
          </tr>
          <tr>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm"><b>{{$t("Total Numbers")}}:</b></td>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm">{{subscription.numbers}}</td>
          </tr>
          <tr>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm"><b>{{$t("Type")}}:</b></td>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm">{{$t(subscription.system.type)}}</td>
          </tr>
          <tr>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm"><b>{{$t("Start")}}:</b></td>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm">{{subscription.start}}</td>
          </tr>
          <tr>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm"><b>{{$t("End")}}</b></td>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm">{{subscription.end ? subscription.end : $t("None")}}</td>
          </tr>
          <tr>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm"><b>{{$t("Active")}}:</b></td>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm">{{subscription.active ? $t("Yes") : $t("No")}}</td>
          </tr>
          <tr>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm"><b>{{$t("Longest binding")}}:</b></td>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm">
              {{subscription.binding.end_date ? subscription.binding.end_date : $t("None")}}
            </td>
          </tr>
          <tr v-if="subscription.binding.end_date">
            <td class="u-width-1/2 u-border-top u-soft-ends-sm"><b>{{$t("Binding details")}}:</b></td>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm">
              <button
                class="c-btn c-btn--sm"
                style="font-size:1.2rem;"
                @click="binding_show = !binding_show"
              >{{binding_show ? $t("Collapse") : $t("Expand")}}</button>
            </td>
          </tr>
          <tr>
            <transition name="fade">
              <td v-if="binding_show" colspan="2">
                <table class="u-width-1/1 u-text-sm">
                  <thead>
                    <th>{{$t('Product Name')}}</th>
                    <th>{{$t('Start')}}</th>
                    <th>{{$t('End')}}</th>                    
                  </thead>
                  <tbody>
                    <tr v-for="prod in subscription.binding.products" :key="prod.id">
                      <td>{{prod.name}}</td>
                      <td>{{
                        prod.start ? new Date(Date.parse(prod.start)).toLocaleDateString("sv-SE") : ""
                      }}</td>
                      <td>{{
                        prod.end ? new Date(Date.parse(prod.end)).toLocaleDateString("sv-SE") : ""
                      }}</td>                      
                    </tr>
                  </tbody>  
                </table>
              </td>
            </transition>
          </tr>
          <tr v-if="subscription.sim">
            <td class="u-width-1/2 u-border-top u-soft-ends-sm"><b>{{$t("SIM info")}}:</b></td>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm">
              <button
                class="c-btn c-btn--sm"
                style="font-size:1.2rem;"
                @click="sim_show = !sim_show"
              >{{sim_show ? $t("Collapse") : $t("Expand")}}</button>
            </td>
          </tr>
          <tr >
            <transition name="fade">
              <td v-if="sim_show" colspan="2">
                <table class="u-width-1/1 u-text-sm">
                  <tr>
                    <td class="u-width-1/2 "><b>{{$t("PIN")}}:</b></td>
                    <td class="u-width-1/2 ">{{subscription.sim.pin}}</td>
                  </tr>
                  <tr>
                    <td class="u-width-1/2 "><b>{{$t("PUK")}}:</b></td>
                    <td class="u-width-1/2 ">{{subscription.sim.puk}}</td>
                  </tr>
                  <tr>
                    <td class="u-width-1/2 "><b>{{$t("ICC")}}:</b></td>
                    <td class="u-width-1/2 ">{{subscription.sim.icc}}</td>
                  </tr>
                  <tr>
                    <td class="u-width-1/2 "><b>{{$t("IMSI")}}:</b></td>
                    <td class="u-width-1/2 ">{{subscription.sim.imsi}}</td>
                  </tr>
                </table>
              </td>
            </transition>
          </tr>
          <tr v-if="subscription.line">
            <td class="u-width-1/2 u-border-top u-soft-ends-sm">{{$t("Line info")}}</td>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm">
              <button
                class="c-btn c-btn--sm"
                style="font-size:1.2rem;"
                @click="line_show = !line_show"
              >{{line_show ? $t("Collapse") : $t("Expand")}}</button>
            </td>
          </tr>
          <tr>
            <transition name="fade">
              <td v-if="line_show" colspan="2">
                <table class="u-width-1/1 u-text-sm">
                  <tr>
                    <td class="u-width-1/2 "><b>{{$t("Domain")}}:</b></td>
                    <td class="u-width-1/2 ">{{subscription.line.domain}}</td>
                  </tr>
                  <tr>
                    <td class="u-width-1/2 "><b>{{$t("Username")}}:</b></td>
                    <td class="u-width-1/2 ">{{subscription.line.username}}</td>
                  </tr>
                  <tr>
                    <td class="u-width-1/2 "><b>{{$t("Password")}}:</b></td>
                    <td class="u-width-1/2 ">{{subscription.line.password}}</td>
                  </tr>
                  <tr>
                    <td class="u-width-1/2 "><b>{{$t("Description")}}:</b></td>
                    <td class="u-width-1/2 ">{{subscription.line.description}}</td>
                  </tr>
                  <tr>
                    <td class="u-width-1/2 "><b>{{$t("Protected number")}}:</b></td>
                    <td class="u-width-1/2 ">{{subscription.line.caller_id.hidden ? $t("Yes") : $t("No")}}</td>
                  </tr>
                  <tr>
                    <td class="u-width-1/2 "><b>{{$t("External Caller ID")}}:</b></td>
                    <td class="u-width-1/2 ">{{subscription.line.caller_id.external}}</td>
                  </tr>
                  <tr>
                    <td class="u-width-1/2 "><b>{{$t("Internal Caller ID")}}:</b></td>
                    <td class="u-width-1/2 ">{{subscription.line.caller_id.internal}}</td>
                  </tr>
                  <tr>
                    <td class="u-width-1/2 "><b>{{$t("Emergency country")}}:</b></td>
                    <td class="u-width-1/2 ">{{subscription.line.emergency_info.country}}</td>
                  </tr>
                  <tr>
                    <td class="u-width-1/2 "><b>{{$t("Emergency region")}}:</b></td>
                    <td class="u-width-1/2 ">
                      {{subscription.line.emergency_info.name ? subscription.line.emergency_info.name : $t("Unknown")}}
                    </td>
                  </tr>

                </table>
              </td>
            </transition>
          </tr>
          <tr v-if="subscription.children.length">
            <td class="u-width-1/2 u-border-top u-soft-ends-sm">{{$t("Twinned Subscriptions")}}</td>
            <td class="u-width-1/2 u-border-top u-soft-ends-sm">
              <button
                class="c-btn c-btn--sm"
                style="font-size:1.2rem;"
                @click="children_show = !children_show"
              >{{children_show ? $t("Collapse") : $t("Expand")}}</button>
            </td>
          </tr>
          <tr>
            <transition name="fade">
              <td v-if="children_show" colspan="2">
                <table class="u-width-1/1 u-text-sm">
                  <thead>
                    <th>{{$t('Name')}}</th>
                    <th>{{$t('Number')}}</th>
                    <th>{{$t('Active')}}</th>
                    <th>{{$t('Details')}}</th>
                  </thead>
                  <tbody>
                    <tr v-for="child in subscription.children" :key="child.id">
                      <td>{{child.name}}</td>
                      <td>{{child.primary_number}}</td>
                      <td>{{child.active ? $t("Yes") : $t("No")}}</td>
                      <td><button class="c-btn c-btn--sm btn-xs" @click="goto(child.id)">{{$t("open")}}</button></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </transition>
          </tr>
         <!--  <tr >
            <transition name="fade">
              <div v-if="!show_pre" @click="show_pre = !show_pre">
                Details
              </div>
              <pre v-if="show_pre" @click="show_pre = !show_pre">{{subscription}}</pre>
            </transition>
          </tr> -->
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
.btn-xs {
  font-size: 0.9rem !important;  
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .slide-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
import s from "./self_admin_subscription_details";
export default s;
</script>