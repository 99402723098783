<template>
  <div class="[ o-gutter-bottom-md o-gutter-bottom-lg@lg ]">
    <div class="o-gutter__item [ u-soft-bottom-sm u-soft-bottom-md@lg ]">
      <h1 class="u-push-bottom-clear">{{ contact.id ? $t("Edit contact") : $t("Create contact")}}</h1>
    </div>
    <div class="o-gutter__item">
      <div v-if="processing">{{ $t("Loading...") }}</div>
      <div v-else>
        <div
          v-for="(error, index) in errors"
          :key="index"
          class="u-background-mu u-text-epsilon u-text-bold"
          style="padding: 7px"
        >
          {{ $t(error) }}
        </div>
        <table class="u-width-1/1 u-text-omicron u-text-md">
          <tbody>
            <tr>
              <td class="u-width-1/2 u-soft-ends-sm">
                <b>{{ $t("First Name") }}:</b>
              </td>
              <td class="u-width-1/2 u-soft-ends-sm">
                <input
                  type="text"
                  class="[ c-text-input__input c-text-input__input--border ]"
                  @blur="first_name_lostfocus()"
                  v-model="contact.first_name"
                />
              </td>
            </tr>
            <tr>
              <td class="u-width-1/2 u-soft-ends-sm">
                <b>{{ $t("Last Name") }}:</b>
              </td>
              <td class="u-width-1/2 u-soft-ends-sm">
                <input
                  type="text"
                  class="[ c-text-input__input c-text-input__input--border ]"
                  v-model="contact.last_name"
                />
              </td>
            </tr>
            <tr>
              <td class="u-soft-ends-sm" colspan="2">
                <b>{{ $t("Phones") }}:</b>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <table class="u-width-1/1" style="font-size: 0.85rem;">
                  <thead >
                    <th width="25%">{{ $t("Description") }}</th>
                    <th width="28%">{{ $t("Number") }}</th>
                    <th width="27%" >{{ $t("Forwarding") }}</th>
                    <th width="20%">{{ $t("Delete")}}</th>
                  </thead>
                  <tbody>
                    <tr v-for="(phone, index) in contact.phones" :key="index">
                      <td>
                        <input
                          type="text"
                          style="width: 95%"
                          class="[ c-text-input__input c-text-input__input--border ]"
                          v-model="phone.description"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          class="[ c-text-input__input c-text-input__input--border ]"
                          v-bind:style="phone_number_style_object(phone.number)"
                          v-model="phone.number"
                        />
                      </td>
                      <td>
                        <figure v-if="phone.forwarding_option"
                          @click="phone.forwarding_option = !phone.forwarding_option"
                          class="u-position-relative u-push-bottom-clear u-inline-block u-soft-lg u-fill-delta"
                        >
                          <svg
                            class="[ u-position-absolute u-position-center-center ] u-width-1/2 u-height-1/2 u-fill-outgoing"
                          >
                            <use xlink:href="#icon-check-yes-filled" />
                          </svg>
                        </figure>
                        <figure v-else
                          @click="phone.forwarding_option = !phone.forwarding_option"
                          class="u-position-relative u-push-bottom-clear u-inline-block u-soft-lg u-fill-delta"
                        >
                          <svg
                            class="[ u-position-absolute u-position-center-center ] u-width-1/2 u-height-1/2 u-fill-incoming"
                          >
                            <use xlink:href="#icon-check-no-filled" />
                          </svg>
                        </figure>
                      </td>
                      <td>
                        <figure
                          @click="delete_phone(phone, index)"
                          class="u-position-relative u-push-bottom-clear u-inline-block u-soft-lg u-fill-delta"
                        >
                          <svg
                            class="[ u-position-absolute u-position-center-center ] u-width-1/2 u-height-1/2"
                          >
                            <use xlink:href="#icon-phone-cross" />
                          </svg>
                        </figure>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3">
                        <pre style="line-height: 0.35rem"></pre>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <button
                  style="float: right; max-width: 150px;"
                  class="c-btn c-btn--sm"
                  @click="add_phone()"
                >
                  {{ $t("Add phone") }}
                </button>
              </td>
            </tr>
            <tr>
              <td class="u-soft-ends-sm" colspan="2">
                <b>{{ $t("Emails") }}:</b>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <table class="u-width-1/1" style="font-size: 0.85rem;">
                  <thead>
                    <th width="25%">{{ $t("Description") }}</th>
                    <th width="55%">{{ $t("Address") }}</th>
                    <th width="20%">{{ $t("Delete")}} </th>
                  </thead>
                  <tbody>
                    <tr v-for="(email, index) in contact.emails" :key="index">
                      <td>
                        <input
                          type="text"
                          style="width: 95%"
                          class="[ c-text-input__input c-text-input__input--border ]"
                          v-model="email.description"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          class="[ c-text-input__input c-text-input__input--border ]"
                          v-bind:style="
                            email_address_style_object(email.address)
                          "
                          v-model="email.address"
                        />
                      </td>
                       <td>
                        <figure
                          @click="delete_email(email, index)"
                          class="u-position-relative u-push-bottom-clear u-inline-block u-soft-lg u-fill-delta"
                        >
                          <svg
                            :class="{'u-fill-mu': !playing && fraction === 0}"
                            class="[ u-position-absolute u-position-center-center ] u-width-1/2 u-height-1/2"
                          >
                            <use xlink:href="#icon-email-delete" />
                          </svg>
                        </figure>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <pre style="line-height: 0.35rem"></pre>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <button
                  class="c-btn c-btn--sm"
                  style="float: right; max-width: 150px;"
                  @click="add_email()"
                >
                  {{ $t("Add email") }}
                </button>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <button
                  class="c-btn c-btn--lg"
                  style="float: right"
                  @click="save()"
                >
                  {{ $t("Save") }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import s from "./contact_edit";
export default s;
</script>