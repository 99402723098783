













import { Vue, Component, Prop } from 'vue-property-decorator';
import LinkPlate from '@/components/LinkPlate.vue';
import { Invoice } from '../../../api';
import moment from 'moment';
import _ from 'lodash';

@Component({
  components: {
    LinkPlate,
  },
})
export default class InvoiceList extends Vue {
  @Prop({ default: [] })
  private invoices!: Invoice[];

  public prettyInvoiceDate(invoice: Invoice) {
    moment.locale(this.$i18n.locale);
    return _.capitalize(moment(invoice.invoiceDate).format('MMMM Do'));
  }
}
