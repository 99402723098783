import Component from 'vue-class-component';
import { Mixins } from 'vue-property-decorator';
import { Events } from '@/mixins';
import FlatPickr from 'vue-flatpickr-component';
import { Swedish as swedish } from 'flatpickr/dist/l10n/sv';
import { english } from 'flatpickr/dist/l10n/default';
import moment from 'moment';
import {
  SelfAdminSubscription,
  SignupTemplate,
  SignupNumber,
  SignupSim,
  Paginated,
  SubscriptionSim
} from '../../../../api';
import i18n from '../../i18n';
type system_map = {
  [key: string]: {
    numbers: SignupNumber[],
    sims: {
      offset: number,
      limit: number,
      total_count: number,
      items: SignupSim[]
    }
  }
};

@Component({
  components: {
    FlatPickr
  }
})
export default class SelfAdminSubscriptionCreate extends Mixins(Events) {
  public processing = true;

  public inc_vat = false;

  public templates: SignupTemplate[] = [];
  public selected_template: SignupTemplate|null = null;
  public map: system_map = {};
  public selected_sim: SignupSim|null = null;
  public selected_number: SignupNumber|null = null;

  public sims_processing = false;
  public sim_offset: number = 0;
  public sim_limit: number = 5;
  public sim_items: SignupSim[] = [];
  public sim_total: number = 0;
  
  public subscription_name: string = "";
  public start_date: Date = new Date();

  public number_items: SignupNumber[] = [];

  public will_create_with: {
    name: string, template_id: number, sim_id: number, number_id: number|null, start: string
  }|null = null;


  public created() {
    console.log("SelfAdminSubscriptionCreate created()");
    this.$watch("selected_template", (n, o) => this.switch_template(n,o) );
    this.$watch("selected_sim", (n, o) => this.switch_sim(n,o) );
    this.$watch("selected_number", (n, o) => this.switch_number(n,o) );
    this.api.selfadmin_list_templates().then(
      temps => {
        console.log("templates",temps);
        this.templates = temps;
        const systems = [... new Set(this.templates.map(t=>t.system_id))];
        console.log("unique system ids", systems);
        if(systems.length) {
          systems.forEach( sid => {
            Promise.all([
              this.api.selfadmin_list_sims(sid),
              this.api.selfadmin_random_numbers(sid, 10)
            ]).then(([sims, nums]) => {
              this.map[sid] = {
                numbers: nums,
                sims: {
                  offset: 0,
                  limit: 5,
                  total_count: sims.count,
                  items: new Array(sims.count)
                }
              };
              this.map[sid].sims.items.splice(0, sims.items.length, ...sims.items);
              console.log(" map is ", this.map);
              this.processing = false;
            }).catch(err => {
              console.log("failed to get data for system "+sid+" got error:", err);
            });
          });
        } else {
          this.processing = false;
        }

      }
    ).catch(
      err => console.error("list templates error", err)
    );
  }

  public mounted() {
    console.log("SelfAdminSubscriptionCreate mounted()");
  }

  public create_subscription() {
    if(this.validate) {
      this.will_create_with = {
        name: this.subscription_name,
        template_id: this.selected_template!.id,
        sim_id: this.selected_sim!.id,
        number_id: this.selected_number ? this.selected_number.id : null,
        start: moment(this.start_date).format('YYYY-MM-DD')
      };
    }
  }

  get validate() {
    return this.selected_template && this.selected_sim && (this.selected_number || this.selected_sim.last_msisdn);
  }

  get sims() {
    if(this.selected_template && this.selected_template.system_is_mobile) {
      const sid = this.selected_template.system_id;
      const ret = this.sim_items.slice(
        this.sim_offset,
        this.sim_offset + this.sim_limit
      );
      console.log("sims = ", ret);
      return ret;
    }
    return [];
  }

  get numbers() {
    if(this.selected_template ) {
      return this.number_items;
    }
    return [];
  }

  get flatConfig() {
    const locale = this.$i18n.locale === 'sv' ? swedish : english;
    return {
      enableTime: false,
      dateFormat: 'Y-m-d',
      time_24hr: true,
      weekNumbers: true,
      locale,
      disable: [(date: Date)=> {
        const now = new Date();
        now.setHours(0,0,0,0);
        date.setHours(0,0,0,0);
        const day_start = now.getTime();
        const date_start = date.getTime();
        return date_start < day_start;
      }]
    };
  }

  get prettyStartDate() {
    return moment(this.start_date).format('YYYY-MM-DD');
  }

  public set_start_date() {
    const options = {
      date: this.start_date,
      mode: 'date',
      okText: this.$t('Done'),
      cancelText: this.$t('Cancel'),
      todayText: this.$t('Today'),
      nowText: this.$t('Now'),
      is24Hour: true,
      allowOldDates: false,
      doneButtonLabel: this.$t('Done'),
      cancelButtonLabel: this.$t('Cancel'),
      minuteInterval: 15,
      locale: this.$i18n.locale === 'en' ? 'en_us' : this.$i18n.locale === 'sv' ? 'sv_se' : 'en_us',
    };

    const onSuccess = (date: Date) => {
      this.start_date = date;
    };

    const onError = (err: any) => {
      console.error(err);
    };

    // Launch native datepicker
    (window as any).plugins.datePicker.show(options, onSuccess, onError);
  }


  public switch_template(new_template: SignupTemplate|null, old_template: SignupTemplate|null) {
    console.log("selected_template changed");
    if(old_template) {
      this.map[old_template.system_id].sims = {
        offset: this.sim_offset,
        limit: this.sim_limit,
        total_count: this.sim_total,
        items: this.sim_items
      };
      this.map[old_template.system_id].numbers = this.number_items;
    }
    if(new_template) {
      this.sim_offset = this.map[new_template.system_id].sims.offset;
      this.sim_limit  = this.map[new_template.system_id].sims.limit;
      this.sim_total  = this.map[new_template.system_id].sims.total_count;
      this.sim_items  = this.map[new_template.system_id].sims.items;
      this.number_items = this.map[new_template.system_id].numbers;
    } else {
      this.sim_offset = 0;
      this.sim_limit = 5;
      this.sim_total = 0;
      this.sim_items = [];
      this.number_items = [];
    }
    this.selected_sim = null;
    this.selected_number = null;
  }

  public switch_sim(new_sim: SignupSim|null, old_sim: SignupSim|null) {
    console.log("selected_sim changed");
    if(new_sim && new_sim.last_msisdn) {
      this.selected_number = null;
    }
  }

  public switch_number(new_number: SignupNumber|null, old_number: SignupNumber|null) {
    console.log("selected_number changed");
  }

  public select_sim(sim: SignupSim) {
    this.selected_sim = sim;
  }

  public async load_sims() {
    if(this.selected_template) {
      const sid = this.selected_template.system_id;
      console.log("sid = ", sid);
      const end = Math.min(
        this.sim_offset + this.sim_limit - 1,
        this.sim_items.length - 1
      );
      if(
        this.sim_items[this.sim_offset] == null
        || this.sim_items[end] == null
      ) {
        this.sims_processing = true;
        const res: Paginated<SignupSim> = await this.api.selfadmin_list_sims( sid, this.sim_offset );
        this.sim_total = res.count;
        if(this.sim_items.length !== res.count) {
          this.sim_items = new Array(res.count);
        }
        this.sim_items.splice(this.sim_offset, res.items.length, ...res.items);
        this.sims_processing = false;
      }
    }
  }

  public async next_sims() {
    if(!this.processing && !this.sims_processing && this.selected_template) {
      this.sim_offset = Math.min(this.sim_offset + this.sim_limit, this.sim_total);
      return this.load_sims();
    }
    return false;
  }

  public async prev_sims() {
    if(!this.processing && !this.sims_processing && this.selected_template) {
      this.sim_offset = Math.max(this.sim_offset - this.sim_limit, 0);
      return this.load_sims();
    }
    return false;
  }

  get prettyTitle() {
    return `${i18n.t("Create Subscription")}`;
  }


}
