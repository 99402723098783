import Vue from 'vue';
import App from './App.vue';
import { router } from './router';
import store from './store';
import i18n from './i18n';
import VTooltip from 'v-tooltip';

Vue.use(VTooltip);

import './assets/scss/main.scss';
import 'flatpickr/dist/flatpickr.css';
import 'v-tooltip/dist/v-tooltip.css';

Vue.config.productionTip = false;
i18n.locale = store.state.locale;

function init() {
  const x = new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');
}

if (window.cordova) {
  document.addEventListener('deviceready', init);
} else {
  window.onload = () => {
    console.log('Not cordova');
    init();
  };
}
