






























import { Mixins, Component, Vue } from 'vue-property-decorator';
import { Utils } from '@/mixins';
import AccountPlate from '@/components/plates/AccountPlate.vue';
import placeholder from '@/assets/img/user-placeholder.png';
import { AppUser } from '../../../../api';
import _ from 'lodash';

@Component({
  components: {
    AccountPlate,
  },
})
export default class PermissionsUsers extends Mixins(Utils) {
  public processing: boolean = true;
  public users: AppUser[] = [];
  public placeholder = placeholder;

  public thisUserID: number = 0;

  public created() {
    this.fetch();
  }

  public async fetch() {
    this.processing = true;

    const [users, info] = await Promise.all([
      this.api.userList(),
      this.api.info(),
    ]);

    this.users = users.map(u => ({ ...u, ref: _.head(u.references), notifications: 0 }));

    this.thisUserID = info.crm_id;

    this.processing = false;
  }

  public openGroups(user: AppUser) {
    const name = user.display_name;
    const id = user.id.toString();

    this.$router.push({
      name: 'permissions-groups',
      query: { name, id },
    });
  }
}
