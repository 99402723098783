import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';
import { PERMISSIONS as P, Permissions as Perms } from '../../api';


// import Login from '@/views/Login.vue';
import Consumption from '@/views/Consumption.vue';

import SelectReference from '@/views/references/SelectReference.vue';
import ListReference from '@/views/references/ListReference.vue';
import CommitReference from '@/views/references/CommitReference.vue';
import UpdateReference from '@/views/references/UpdateReference.vue';
import Reference from '@/views/references/Reference.vue';

import MyProfile from '@/views/profile/MyProfile.vue';
import MyProfileRecents from '@/views/profile/MyProfileRecents.vue';
import Presence from '@/views/profile/Presence.vue';

import Recordings from '@/views/recordings/Recordings.vue';
import RecordingIndex from '@/views/recordings/Index.vue';
import RecordingListen from '@/views/recordings/Listen.vue';

import Voicemails from './views/voicemails/Voicemails.vue';
import VoicemailIndex from './views/voicemails/Index.vue';
import VoicemailListen from './views/voicemails/Listen.vue';

import SimData from '@/views/sim-data/SimData.vue';
import SimDataIndex from '@/views/sim-data/Index.vue';
import SimDataView from '@/views/sim-data/View.vue';

import Invoices from '@/views/invoices/Invoices.vue';
import InvoicesIndex from '@/views/invoices/Index.vue';
import InvoiceDetail from '@/views/invoices/Detail.vue';

import EventHistory from '@/views/event-history/EventHistory.vue';
import EventHistoryTop from '@/views/event-history/EventHistoryTop.vue';
import EventHistoryDay from '@/views/event-history/EventHistoryDay.vue';
import EventHistoryView from '@/views/event-history/EventHistoryView.vue';

import Contacts from '@/views/contacts/Contacts.vue';
import ContactsList from '@/views/contacts/ContactsList.vue';
import ContactDetails from '@/views/contacts/ContactDetails.vue';
import ContactEdit from '@/views/contacts/ContactEdit.vue';

import CallLog from '@/views/call-log/CallLog.vue';

import ActiveQueues from '@/views/ActiveQueues.vue';

import Topups from '@/views/topups/Topups.vue';
import TopupProduct from '@/views/topups/Product.vue';
import TopupSubscriptions from '@/views/topups/Subscriptions.vue';

import MyCompany from '@/views/my-company/MyCompany.vue';

import ChatList from '@/views/chat/ChatList.vue';

import Permissions from '@/views/permissions/Permissions.vue';
import PermissionsUsers from '@/views/permissions/Users.vue';
import PermissionsGroups from '@/views/permissions/Groups.vue';

import SettingsScreen from '@/views/settings/SettingsScreen.vue';
import Logout from '@/views/logout/Logout.vue';

import i18n from './i18n';
import SelfAdmin from '@/views/selfadmin/SelfAdmin.vue';
import SelfAdminSubscriptionList from '@/views/selfadmin/SelfAdminSubscriptionList.vue';
import SelfAdminSubscriptionDetails from '@/views/selfadmin/SelfAdminSubscriptionDetails.vue';
import SelfAdminSubscriptionCreate from '@/views/selfadmin/SelfAdminSubscriptionCreate.vue';

Vue.use(Router);
// Vue.use(routerHistory);

function $t(message: string) {
  return i18n.t(message).toString();
}

export type Route = RouteConfig & {
  title: string;
  icon?: string;
  permissions?: Perms;
};

export const routes: Route[] = [
  {
    path: '/my-profile',
    title: 'my-profile',
    component: MyProfile,
    icon: 'user',
    permissions: [P.USER.INFO, P.USER.SUBSCRIPTION],
    children: [
      {
        path: '',
        name: 'my-profile-recents',
        component: MyProfileRecents,
      },
      {
        path: 'presence',
        name: 'presence',
        component: Presence,
      },
    ],
  },
  {
    path: '/sim-data',
    title: 'sim-data',
    permissions: P.SUBSCRIPTIONS.READ,
    component: SimData,
    icon: 'sim',
    children: [
      {
        path: '',
        name: 'sim-data-index',
        component: SimDataIndex,
      },
      {
        path: ':id',
        name: 'sim-data-view',
        component: SimDataView,
      },
    ],
  },
  {
    path: '/consumption',
    title: 'consumption',
    name: 'consumption',
    permissions: P.USER.SUBSCRIPTION,
    component: Consumption,
    icon: 'dashboard',
  },
  {
    path: '/event-history',
    title: 'event-history',
    component: EventHistory,
    icon: 'phone-calling',
    permissions: P.USER.EVENT_HISTORY,
    children: [
      {
        path: '',
        name: 'event-history-top',
        component: EventHistoryTop,
      },
      {
        path: 'day/:year/:month',
        name: 'event-history-day',
        component: EventHistoryDay,
      },
      {
        path: 'view/:year/:month/:day',
        name: 'event-history-view',
        component: EventHistoryView,
      },
    ],
  },
  {
    path: '/contacts',
    title: 'contacts',
    component: Contacts,
    icon: 'people',
    permissions: P.USER.SUBSCRIPTION,
    children: [
      {
        path: '',
        name: 'contacts-list',
        component: ContactsList
      },
      {
        path: 'details/:id',
        name: 'contact-details',
        component: ContactDetails
      },
      {
        path: 'edit/:id',
        name: 'contact-edit',
        component: ContactEdit
      }
    ]
  },
  {
    path: '/call-log',
    title: 'call-log',
    name: 'call-log',
    component: CallLog,
    icon: 'phone-calling',
    permissions: P.USER.CALL_LOG

  },
  {
    path: '/references',
    title: 'references',
    component: Reference,
    icon: 'calendar',
    permissions: P.USER.REFERENCES.UPDATE,
    children: [
      {
        path: '',
        name: 'list-reference',
        component: ListReference,
      },
      {
        path: 'select',
        name: 'select-reference',
        component: SelectReference,
      },
      {
        path: 'commit/:id',
        name: 'reference-commit',
        component: CommitReference,
      },
      {
        path: 'update/:id',
        name: 'reference-update',
        component: UpdateReference,
      }
    ],
  },
  {
    path: '/voicemails',
    title: 'voicemails',
    component: Voicemails,
    icon: 'microphone',
    permissions: P.USER.VOICEMAILS.READ,
    children: [
      {
        path: '',
        name: 'voicemail-index',
        component: VoicemailIndex,
      },
      {
        path: 'listen/:name',
        name: 'voicemail-listen',
        component: VoicemailListen,
      },
      // {
      //   path: 'delete/:name',
      //   name: 'voicemail-delete',
      //   component: VoicemailDelete,
      // },
    ],
  },
  {
    path: '/recordings',
    title: 'recordings',
    component: Recordings,
    icon: 'pause',
    permissions: P.USER.RECORDINGS.READ,
    children: [
      {
        path: '',
        name: 'recording-index',
        component: RecordingIndex,
      },
      {
        path: 'listen/:uniqueid',
        name: 'recording-listen',
        component: RecordingListen,
      },
    ],
  },
  // {
  //   path: '/groups',
  //   name: 'all-groups',
  //   component: AllGroups,
  //   icon: 'microphone',
  // },
  {
    path: '/invoices',
    title: 'invoices',
    component: Invoices,
    icon: 'invoice',
    permissions: P.USER.INVOICES.READ,
    children: [
      {
        path: '',
        name: 'invoice-index',
        component: InvoicesIndex,
      },
      {
        path: ':id',
        name: 'invoice-detail',
        component: InvoiceDetail,
      },
    ],
  },
  // {
  //   path: '/upload',
  //   name: 'upload',
  //   component: Upload,
  //   icon: 'layers',
  // },
  {
    path: '/queues',
    title: 'queues',
    name: 'queues',
    component: ActiveQueues,
    icon: 'support',
    permissions: P.QUEUES.READ,
  },
  {
    path: '/topups',
    title: 'topups',
    component: Topups,
    icon: 'plus',
    permissions: topupPermissions,
    children: [
      {
        path: '',
        name: 'topup-product',
        component: TopupProduct,
      },
      {
        path: ':product-:system-:price-:currency/:name',
        name: 'topup-subscriptions',
        component: TopupSubscriptions,
      },
    ],
  },
  {
    title: 'my-company',
    name: 'my-company',
    path: '/my-company',
    component: MyCompany,
    icon: 'user',
    permissions: P.USERS.READ
  },
  {
    path: '/permissions',
    title: 'permissions',
    component: Permissions,
    icon: 'padlock',
    permissions: [P.PERMISSIONS.READ, P.PERMISSIONS.UPDATE],
    children: [
      {
        path: '',
        name: 'permissons-users',
        component: PermissionsUsers,
      },
      {
        path: 'groups',
        name: 'permissions-groups',
        component: PermissionsGroups,
      },
    ],
  },

  {
    path: '/settings',
    title: 'settings',
    name: 'settings',
    component: SettingsScreen,
    icon: 'settings',
  },
  {
    path: '/self-admin',
    title: 'self-admin',
    component: SelfAdmin,
    icon: 'tools',
    permissions: P.SELF_ADMIN,
    children: [
      {
        path: '',
        name: 'self-admin-list',
        component: SelfAdminSubscriptionList
      },
      {
        path: 'details/:id',
        name: 'self-admin-subscription-details',
        component: SelfAdminSubscriptionDetails
      },
      {
        path: 'create',
        name: 'self-admin-subscription-create',
        component: SelfAdminSubscriptionCreate
      }
    ]

  },
  {
    path: '/logout',
    title: 'logout',
    name: 'logout',
    component: Logout,
    icon: 'exit',
  },
];


// Normalise permissions
export const allRoutes = [
  ...routes,
  {
    path: '*',
    redirect: { name: 'my-profile-recents' },
  },
  {
    name: 'chat',
    path: '/chat/:name/:id',
    component: ChatList,
  },
];

// console.log('All routes', allRoutes);

export const router = new Router({
  mode: 'hash',
  routes: allRoutes,
});

function normalisePermissions(perm?: string | string[]): string[] {
  if (!perm) {
    return [];
  }

  const normal = Array.isArray(perm) ? perm : [perm];

  // console.log('Transforming permissions "', perm, '" to', normal);
  return normal;
}

// Requires READ and at least one of customer BUY and user BUY
function topupPermissions(userPerms: string[]): string[] {
  if (userPerms.indexOf(P.ADDONS.READ) === -1) {
    return [P.ADDONS.READ];
  }

  const anyOf = [P.ADDONS.BUY, P.USER.ADDONS.BUY].some(p => userPerms.indexOf(p) !== -1);
  return anyOf ? [] : [P.USER.ADDONS.BUY];
}
